import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, InputNumber, Modal, notification, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";
import CustomRegularityModal from "./CustomRegularityModal";

const OfferItem = ({ regularityList, offerItem, calculateHoursPerMonth, addCleaningGroup, isOrder }) => {
  const { t } = useTranslation();

  const { offer, refreshOffer } = useContext(OfferContext);

  const hourPrice = offer?.building?.city_branch?.hourly_wage;

  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [regularityModalOpen, setRegularityModalOpen] = useState(false);

  const getCleaningGroupById = (value) => {
    return offer?.cleaning_service_groups?.find((group) => group.id === value);
  };

  form.setFieldsValue(offerItem);

  let count = form.getFieldValue("service_count") || 0;
  let minutes = form.getFieldValue("expected_minutes_per_cleaning") || 0;
  let cleaningGroup = getCleaningGroupById(form.getFieldValue("cleaning_service_group_id"));

  let hpm = 0;

  const handleCountChange = (value) => {
    count = value;
    handleCalculation();
  };

  const handleMinutesChange = (value) => {
    minutes = value;
    handleCalculation();
  };

  const handleCleaningGroupChange = (value) => {
    cleaningGroup = getCleaningGroupById(value);
    handleCalculation();
  };

  const handleCalculation = () => {
    hpm = calculateHoursPerMonth(cleaningGroup, minutes, count);

    if (hpm > 0) {
      form.setFieldsValue({ expected_hours_per_month: hpm.toFixed(3) });
      calculatePrice();
    }
  };

  const calculatePrice = () => {
    let price = hpm * hourPrice;
    form.setFieldsValue({ expected_price_per_month: price.toFixed(2) });
  };

  const deleteItem = (item) => {
    coreApi
      .delete("cleaning-service-group-contents/" + item.id)
      .then((res) => {
        refreshOffer({ ...res.data.offer });
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const editItem = (editedItem) => {
    coreApi
      .put("cleaning-service-group-contents/" + editedItem.cleaning_service_group_content_id, editedItem)
      .then((res) => {
        setDisabled(true);
        refreshOffer({ ...res.data.offer });
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err?.response?.data?.message });
      });
  };

  return (
    <Form
      name={offer?.id}
      form={form}
      onFinish={(values) => {
        editItem({ ...values });
      }}
    >
      <Row gutter={[8, 0]} style={{ width: "100%" }}>
        <Col span={3}>
          <Form.Item hidden name={"cleaning_service_group_content_id"} initialValue={offerItem.id}></Form.Item>
          {!disabled && (
            <Form.Item name="cleaning_service_group_id" initialValue={offerItem.id}>
              <Select
                placeholder={t("offers.itemPlaceholders.regularity")}
                onChange={(value) => {
                  handleCleaningGroupChange(value);
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        padding: 8,
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          flex: "none",
                          display: "block",
                          cursor: "pointer",
                        }}
                        onClick={() => setRegularityModalOpen(true)}
                      >
                        <PlusOutlined /> {t("common.add")}
                      </button>
                    </div>
                  </div>
                )}
              >
                {regularityList.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col span={7}>
          <Form.Item name="service" initialValue={offerItem.service}>
            <TextArea autoSize={true} disabled={disabled}></TextArea>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name="service_count" initialValue={offerItem.count}>
            <InputNumber
              style={{ textAlign: "center", width: "100%" }}
              disabled={disabled}
              onChange={(value) => handleCountChange(value)}
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="expected_minutes_per_cleaning" initialValue={offerItem.expected_minutes_per_cleaning}>
            <InputNumber
              style={{ textAlign: "center", width: "100%" }}
              disabled={disabled}
              onChange={(value) => handleMinutesChange(value)}
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="expected_hours_per_month" initialValue={offerItem.expected_hours_per_month}>
            <InputNumber
              style={{ textAlign: "center", width: "100%" }}
              disabled={disabled}
              // readOnly={true}
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name="expected_price_per_month"
            initialValue={new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: "CZK",
            }).format(offerItem.expected_price_per_month)}
          >
            <InputNumber
              style={{ textAlign: "center", width: "100%" }}
              disabled={disabled}
              // readOnly={true}
            ></InputNumber>
          </Form.Item>
        </Col>
        {!isOrder && (
          <Col span={3}>
            {disabled ? (
              <Row gutter={[8, 8]}>
                <Col>
                  <Button icon={<EditOutlined />} onClick={(e) => setDisabled(!disabled)}></Button>
                </Col>
                <Col>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      return Modal.confirm({
                        title: t("offers.confirmations.delete"),
                        onOk() {
                          deleteItem(offerItem);
                        },
                      });
                    }}
                  ></Button>
                </Col>
              </Row>
            ) : (
              <Row gutter={[8, 8]}>
                <Col>
                  <Button icon={<CheckOutlined />} onClick={(e) => form.submit()}></Button>
                </Col>
                <Col>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      setDisabled(!disabled);
                    }}
                  ></Button>
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
      <CustomRegularityModal
        isModalOpen={regularityModalOpen}
        setModalOpen={setRegularityModalOpen}
        addCleaningGroup={addCleaningGroup}
      ></CustomRegularityModal>
    </Form>
  );
};

export default OfferItem;
