import { Button, Card, Col, Divider, notification, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../../api/calls";

const CountryReportTemplate = ({
  year,
  APIurl,
  cityDataPropName,
  countryDataPropName,
  currencyValues = false,
  selectedType,
}) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [showValuesInCZK, setShowValuesInCZK] = useState(false);

  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [convertedCityData, setConvertedCityData] = useState([]);
  const [convertedCountryData, setConvertedCountryData] = useState([]);

  useEffect(() => {
    if (year === undefined) return;

    setLoading(true);
    coreApi
      .get(APIurl, {
        params: { year: year?.format("YYYY-MM-DD") },
      })
      .then((res) => {
        console.log(res.data);

        if (res.data["aggregate_recurring"]) res.data[countryDataPropName].push(res.data["aggregate_recurring"]);
        if (res.data["aggregate_one_time"]) res.data[countryDataPropName].push(res.data["aggregate_one_time"]);
        if (res.data["aggregate_total"]) res.data[countryDataPropName].push(res.data["aggregate_total"]);

        setCityData([...res.data[cityDataPropName]]);
        setCountryData([...res.data[countryDataPropName]]);

        if (currencyValues) {
          setConvertedCityData([...res.data["converted_" + cityDataPropName]]);
          setConvertedCountryData([...res.data["converted_" + countryDataPropName]]);
        }
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => {
        setLoading(false);
      });
  }, [year, APIurl, cityDataPropName, countryDataPropName, currencyValues]);

  const cityTableColumns = [
    {
      title: t("common.cityBranch"),
      key: "city_branch_id",
      width: 150,
      fixed: "left",
      render: (row) => {
        return <Link to={"/countries/" + row.country_branch_id + "/cities/" + row.city_branch_id}>{row.name}</Link>;
      },
      filters: [
        {
          text: "Czech Republic",
          value: 2,
        },
        {
          text: "Slovakia",
          value: 3,
        },
      ],
      onFilter: (value, record) => record?.country_branch_id === value,
    },
    {
      title: t("expansion.countries.cleaningType"),
      key: "recurrence",
      width: 150,
      fixed: "left",
      render: (row) => {
        switch (row.recurring) {
          case 1:
            return t("expansion.countries.periodic");
          case 0:
            return t("expansion.countries.oneTime");
          default:
            return t("expansion.countries.unknown");
        }
      },
      filters: [
        {
          text: t("expansion.countries.periodic"),
          value: 1,
        },
        {
          text: t("expansion.countries.oneTime"),
          value: 0,
        },
      ],
      onFilter: (value, record) => record?.recurring === value,
    },
    {
      title: t("expansion.cityManager"),
      key: "employee_id",
      width: 150,
      fixed: "left",
      render: (row) => {
        if (!row?.employee?.first_name || !row?.employee?.last_name) return t("common.empty");
        return (
          <Link to={"/employees/" + row?.employee?.id}>
            {row?.employee?.first_name + " " + row?.employee?.last_name}
          </Link>
        );
      },
    },
  ];

  const countryTableColumns = [
    {
      title: t("common.country"),
      key: "country_branch_id",
      width: 150,
      fixed: "left",
      render: (row) => {
        if (!row.country_branch_id) return row.name;
        return <Link to={"/countries/" + row.country_branch_id}>{row.name}</Link>;
      },
      filters: [
        {
          text: "Czech Republic",
          value: "Czech Republic",
        },
        {
          text: "Slovakia",
          value: "Slovakia",
        },
        {
          text: "Aggregate",
          value: "Aggregate",
        },
      ],
      onFilter: (value, record) => record?.name?.includes(value),
    },
    {
      title: t("expansion.countries.cleaningType"),
      key: "recurrence",
      width: 150,
      fixed: "left",
      render: (row) => {
        switch (row.recurring) {
          case 1:
            return t("expansion.countries.periodic");
          case 0:
            return t("expansion.countries.oneTime");
          default:
            return t("expansion.countries.unknown");
        }
      },
      filters: [
        {
          text: t("expansion.countries.periodic"),
          value: 1,
        },
        {
          text: t("expansion.countries.oneTime"),
          value: 0,
        },
      ],
      onFilter: (value, record) => record?.recurring === value,
    },
  ];

  const monthColumnsValues = [
    {
      title: moment().month(0).format("MMMM"),
      key: "1",
      width: 150,
      render: (row) => {
        return row["1"];
      },
    },
    {
      title: moment().month(1).format("MMMM"),
      key: "2",
      width: 150,
      render: (row) => {
        return row["2"];
      },
    },
    {
      title: moment().month(2).format("MMMM"),
      key: "3",
      width: 150,
      render: (row) => {
        return row["3"];
      },
    },
    {
      title: moment().month(3).format("MMMM"),
      key: "4",
      width: 150,
      render: (row) => {
        return row["4"];
      },
    },
    {
      title: moment().month(4).format("MMMM"),
      key: "5",
      width: 150,
      render: (row) => {
        return row["5"];
      },
    },
    {
      title: moment().month(5).format("MMMM"),
      key: "6",
      width: 150,
      render: (row) => {
        return row["6"];
      },
    },
    {
      title: moment().month(6).format("MMMM"),
      key: "7",
      width: 150,
      render: (row) => {
        return row["7"];
      },
    },
    {
      title: moment().month(7).format("MMMM"),
      key: "8",
      width: 150,
      render: (row) => {
        return row["8"];
      },
    },
    {
      title: moment().month(8).format("MMMM"),
      key: "9",
      width: 150,
      render: (row) => {
        return row["9"];
      },
    },
    {
      title: moment().month(9).format("MMMM"),
      key: "10",
      width: 150,
      render: (row) => {
        return row["10"];
      },
    },
    {
      title: moment().month(10).format("MMMM"),
      key: "11",
      width: 150,
      render: (row) => {
        return row["11"];
      },
    },
    {
      title: moment().month(11).format("MMMM"),
      key: "12",
      width: 150,
      render: (row) => {
        return row["12"];
      },
    },
  ];

  const monthColumnsCurrency = [
    {
      title: moment().month(0).format("MMMM"),
      key: "1",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["1"]);
      },
    },
    {
      title: moment().month(1).format("MMMM"),
      key: "2",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["2"]);
      },
    },
    {
      title: moment().month(2).format("MMMM"),
      key: "3",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["3"]);
      },
    },
    {
      title: moment().month(3).format("MMMM"),
      key: "4",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["4"]);
      },
    },
    {
      title: moment().month(4).format("MMMM"),
      key: "5",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["5"]);
      },
    },
    {
      title: moment().month(5).format("MMMM"),
      key: "6",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["6"]);
      },
    },
    {
      title: moment().month(6).format("MMMM"),
      key: "7",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["7"]);
      },
    },
    {
      title: moment().month(7).format("MMMM"),
      key: "8",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["8"]);
      },
    },
    {
      title: moment().month(8).format("MMMM"),
      key: "9",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["9"]);
      },
    },
    {
      title: moment().month(9).format("MMMM"),
      key: "10",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["10"]);
      },
    },
    {
      title: moment().month(10).format("MMMM"),
      key: "11",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["11"]);
      },
    },
    {
      title: moment().month(11).format("MMMM"),
      key: "12",
      width: 150,
      render: (row) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: row?.currency_iso_4217 || "CZK",
        }).format(row["12"]);
      },
    },
  ];

  const cityTotalColumnValues = {
    title: t("common.total"),
    key: "city_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return row.city_total;
    },
  };

  const cityTotalColumnCurrency = {
    title: t("common.total"),
    key: "city_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: row?.currency_iso_4217 || "CZK",
      }).format(row.city_total);
    },
  };

  const countryTotalColumnValues = {
    title: t("common.total"),
    key: "country_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return row.country_total;
    },
  };

  const countryTotalColumnCurrency = {
    title: t("common.total"),
    key: "country_total",
    fixed: "right",
    width: 200,
    render: (row) => {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: row.currency_iso_4217 || "CZK",
      }).format(row.country_total);
    },
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        {currencyValues && (
          <React.Fragment key={"currency_changer"}>
            <Col span={24}>
              <Button
                onClick={() => {
                  setShowValuesInCZK(!showValuesInCZK);
                }}
              >
                {showValuesInCZK ? t("reports.country.showCZKVals") : t("reports.country.showOrigVals")}
              </Button>
            </Col>
            <Divider></Divider>
          </React.Fragment>
        )}
        <Col span={24}>
          <Card loading={isLoading} size="small" bodyStyle={{ padding: "10px" }}>
            <Table
              loading={isLoading}
              size={"small"}
              dataSource={showValuesInCZK ? convertedCountryData : countryData}
              columns={
                currencyValues
                  ? [...countryTableColumns, ...monthColumnsCurrency, countryTotalColumnCurrency]
                  : [...countryTableColumns, ...monthColumnsValues, countryTotalColumnValues]
              }
              pagination={false}
              scroll={{ x: "80%" }}
            ></Table>
          </Card>
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <Card loading={isLoading} size="small" bodyStyle={{ padding: "10px" }}>
            <Table
              loading={isLoading}
              size={"small"}
              dataSource={showValuesInCZK ? convertedCityData : cityData}
              columns={
                currencyValues
                  ? [...cityTableColumns, ...monthColumnsCurrency, cityTotalColumnCurrency]
                  : [...cityTableColumns, ...monthColumnsValues, cityTotalColumnValues]
              }
              pagination={false}
              scroll={{ x: "80%" }}
            ></Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CountryReportTemplate;
