import { Col, Divider, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PartnerBankingSection from "./PartnerBankingSection";
import PartnerBasicInfoSection from "./PartnerBasicInfoSection";
import PartnerBillingSection from "./PartnerBillingSection";
import PartnerContactSection from "./PartnerContactSection";
import PartnerInsuranceSection from "./PartnerInsuranceSection";
import PartnerWorkersTable from "./PartnerWorkersTable";

const ContractorGeneralPane = ({ partner, readOnly }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <PartnerBasicInfoSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col xs={24} md={12}>
        <PartnerBillingSection partner={partner} readOnly={readOnly} />
        <PartnerContactSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col xs={24} md={12}>
        <PartnerBankingSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col xs={24} md={12}>
        <PartnerInsuranceSection partner={partner} readOnly={readOnly} />
      </Col>
      <Col span={24}>
        <Divider orientation="left">{t("partners.workers")}</Divider>
        <PartnerWorkersTable workers={partner?.workers} idPartner={partner?.id}></PartnerWorkersTable>
      </Col>
    </Row>
  );
};

export default ContractorGeneralPane;
