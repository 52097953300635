import { Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const InvoicesTableSimple = ({
  invoices,
  loading,
  fetchData,
  pagination,
  filters,
  setFilters,
  setPagination,
  setSorter,
}) => {
  const { t } = useTranslation();

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({ ...filters, page_size: pagination?.pageSize, page: pagination?.current });

    setSorter(sorter);
    setPagination(pagination);
  };

  /**
   * Columns of the table
   */
  const invoiceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      render: (rowData) => {
        return (
          rowData?.transactions && (
            <Link style={{ borderBottom: "1px dotted" }} to={"/transactions/" + rowData?.transactions[0]?.id}>
              {rowData?.transactions[0]?.reference || rowData?.transactions[0]?.id}
            </Link>
          )
        );
      },
    },
    {
      title: t("expansion.city"),
      key: "city_branch_id",
      render: (rowData) => rowData?.city_branch?.name,
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      sorter: true,
      render: (rowData) => {
        return (
          <Link style={{ fontWeight: 500 }} to={"/invoices/" + rowData?.id}>
            {rowData?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.recipientName"),
      key: "recipient_name",
      dataIndex: "recipient_name",
      sorter: true,
    },
    {
      title: t("finance.invoices.recipientAddress"),
      key: "recipient_address",
      dataIndex: "recipient_address",
      sorter: true,
    },
    {
      title: t("finance.invoices.publisher"),
      key: "invoice_publisher_id",
      sorter: true,
      render: (rowData) => {
        return (
          rowData?.publisher && (
            <Link to={"/partners/" + rowData?.publisher?.id} style={{ borderBottom: "1px dotted" }}>
              {`${rowData?.publisher?.first_name} ${rowData?.publisher?.last_name}`}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.dateDue"),
      key: "date_due",
      dataIndex: "date_due",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.totalAmount"),
      key: "total_amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.total_amount);
      },
    },
    {
      title: t("finance.invoices.datePaid"),
      key: "date_paid",
      dataIndex: "date_paid",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag color="red">{t("finance.invoices.unpaid")}</Tag>
        );
      },
    },
  ];

  return (
    <Table
      pagination={{
        ...pagination,
      }}
      loading={loading}
      dataSource={invoices}
      size={"small"}
      columns={invoiceTableColumns}
      onChange={handleTableChange}
    />
  );
};

export default InvoicesTableSimple;
