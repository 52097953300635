import { DownloadOutlined, FormOutlined, EyeOutlined, HomeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import BuildingUnitDetails from "../components/Buildings/BuildingUnitDetails";
import BuildingUnitSchedulerPane from "../components/Buildings/BuildingUnitSchedulerPane";
import ClaimsPane from "../components/Claims/ClaimsPane";
import CleaningListComplex from "../components/Cleanings/CleaningListComplex";
import ContactsPane from "../components/ContactsPane";
import CustomTabs from "../components/custom/CustomTabs";

const { Title } = Typography;

/**
 * Page with building unit detail
 * @component
 * @alias BuildingUnitProfile
 * @returns <Card /> with content
 */
const BuildingUnitProfile = (props) => {
  // Translation constant
  const { t } = useTranslation();

  // States
  const [buildingUnit, setBuildingUnit] = useState({});
  const [rules, setRules] = useState([]);
  const [isDownloading, setDownloading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rulesForm] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);

    coreApi
      .get("buildings/units/rules/" + props.match.params.id)
      .then((response) => {
        // Parse date to moment
        response.data[0]?.rules.forEach((rule) => {
          rule.date_from = moment().startOf("year");
        });

        if (response.data[0]?.rules?.some((e, i) => e.rrule.includes("FREQ=MONTHLY;INTERVAL=6;"))) {
          var index;

          // Get index of frequancy 2 times per year
          response.data[0]?.rules?.some((e, i) => {
            if (e.rrule.includes("FREQ=MONTHLY;INTERVAL=6;")) {
              index = i;
              return true;
            }
            return false;
          });

          // Second cleaning of 2 times per year cleanings is created by code
          response.data[0]?.rules.splice(index, 0, {
            date_from: moment().startOf("year"),
            rrule: "FREQ=MONTHLY;INTERVAL=6;BYDAY=MO",
            ceated_at: "2022-01-17T09:10:59.000000Z",
            date_to: null,
            name: "2x ROČNĚ:",
            rrule_blueprint: "FREQ=MONTHLY;INTERVAL=6;BYDAY=MO",
            service_ratio: "0.40",
            sort_weight: null,
          });
        }

        setRules(response.data[0]?.rules);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    rulesForm.resetFields();
  }, [rules]);

  // Submit a form with schedule
  const onFinish = (values) => {
    values.rulesList?.forEach((rrule) => {
      rrule.date_from = rrule.date_from ? rrule.date_from.format("YYYY-MM-DD") : null;
    });

    console.log(values);

    coreApi
      .post(
        "cleaning-schedule/create",
        {
          ...values,
          building_unit_id: buildingUnit?.id,
          dateFrom: values.dateFrom ? values.dateFrom.format("YYYY-MM-DD") : null,
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then((res) => {
        fileDownload(
          new Blob([res.data], { type: "application/pdf" }),
          "rozpis-" + buildingUnit.street.toLowerCase() + "-" + buildingUnit.house_number + ".pdf"
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // setIsModalVisible(false);
      });
  };

  useEffect(() => {
    coreApi
      .get("buildings/units/" + props.match.params.id)
      .then((res) => setBuildingUnit(res.data))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  const downloadPDFScheudle = () => {
    setDownloading(true);

    coreApi
      .get("/cleaning-schedule", {
        responseType: "blob",
        params: {
          building_unit_id: buildingUnit?.id,
          year: "2022-01-01",
        },
      })
      .then((res) =>
        fileDownload(res.data, "rozpis-" + buildingUnit.street.toLowerCase() + "-" + buildingUnit.house_number + ".pdf")
      )
      .catch((err) => notification.error({ message: err?.response?.data?.message }))
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Card loading={isLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <Title>
                  {buildingUnit?.building?.registration_number +
                    " - " +
                    buildingUnit.street +
                    " " +
                    buildingUnit.house_number}
                </Title>

                <Space>
                  <Typography.Title level={5} type="secondary" style={{ color: "#1890ff", fontWeight: 400 }}>
                    <Space size={[6, 12]}>
                      <Link to={`/buildings/${buildingUnit?.building_id}`}>
                        <React.Fragment>
                          <HomeOutlined style={{ marginRight: "6px" }} />
                          {buildingUnit?.building?.reference}
                        </React.Fragment>
                      </Link>
                    </Space>
                  </Typography.Title>
                </Space>
              </div>

              <div style={{ display: "flex", gap: "6px" }}>
                <Button type={"primary"} icon={<FormOutlined />} onClick={showModal} loading={isDownloading}>
                  {t("schedules.createPDFSchedule")}
                </Button>

                <Modal
                  title={t("schedules.createPDFSchedule")}
                  visible={isModalVisible}
                  width={600}
                  onOk={() => {
                    rulesForm.submit();
                  }}
                  onCancel={() => {
                    setIsModalVisible(false);
                  }}
                >
                  <Form
                    form={rulesForm}
                    onFinish={onFinish}
                    initialValues={{
                      rulesList: rules,
                    }}
                  >
                    <Row gutter={[6, 6]}>
                      <Col span={24}>
                        <Row gutter={[12, 12]}>
                          <Col span={11}>
                            <strong>{t("schedules.frequancy")}:</strong>
                          </Col>

                          <Col span={11}>
                            <strong>{t("schedules.firstCleaning")}:</strong>
                          </Col>

                          <Col span={2}>
                            <Tooltip placement="top" title={t("schedules.include")}>
                              <EyeOutlined style={{ fontSize: "18px" }} />
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Form.List name="rulesList">
                          {(fields) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Row gutter={[12, 12]} key={key}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "cleaning_service_group_id"]}
                                    fieldKey={(restField.key, "cleaning_service_group_id")}
                                    hidden
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Col span={11}>
                                    <Form.Item {...restField} name={[name, "name"]} fieldKey={(restField.key, "name")}>
                                      <Input placeholder={t("schedules.frequancy")} disabled />
                                    </Form.Item>
                                  </Col>

                                  <Col span={11}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "date_from"]}
                                      fieldKey={[restField.key, "date_from"]}
                                    >
                                      <DatePicker
                                        format={(date) =>
                                          rulesForm
                                            .getFieldsValue()
                                            .rulesList[restField.fieldKey].rrule.includes("MONTHLY") ||
                                          rulesForm
                                            .getFieldsValue()
                                            .rulesList[restField.fieldKey].rrule.includes("YEARLY")
                                            ? date.startOf("month").format("YYYY/MM/DD")
                                            : date.format("YYYY/MM/DD")
                                        }
                                        placeholder={t("schedules.firstCleaning")}
                                        style={{ width: "100%" }}
                                        allowClear={false}
                                        picker={
                                          rulesForm
                                            .getFieldsValue()
                                            .rulesList[restField.fieldKey].rrule.includes("MONTHLY") ||
                                          rulesForm
                                            .getFieldsValue()
                                            .rulesList[restField.fieldKey].rrule.includes("YEARLY")
                                            ? "month"
                                            : "date"
                                        }
                                        disabledDate={(current) =>
                                          rulesForm
                                            .getFieldsValue()
                                            .rulesList[restField.fieldKey].rrule.includes("MONTHLY") ||
                                          rulesForm
                                            .getFieldsValue()
                                            .rulesList[restField.fieldKey].rrule.includes("YEARLY")
                                            ? null
                                            : current.format("YYYY-MM-DD") !==
                                              current.clone().startOf("week").format("YYYY-MM-DD")
                                        }
                                        onChange={(key, value) => {
                                          const fields = rulesForm.getFieldsValue();
                                          const { rulesList } = fields;

                                          Object.assign(rulesList[restField.fieldKey], {
                                            date_from: moment(value),
                                          });

                                          rulesForm.setFieldsValue({ rulesList });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "include"]}
                                      fieldKey={(restField.key, "include")}
                                      initialValue={true}
                                      valuePropName="checked"
                                    >
                                      <Checkbox />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  </Form>
                </Modal>

                <Button
                  type={"primary"}
                  icon={<DownloadOutlined />}
                  onClick={downloadPDFScheudle}
                  loading={isDownloading}
                >
                  {t("schedules.downloadPDFSchedule")}
                </Button>
              </div>
            </Row>

            <CustomTabs props={props}>
              <Tabs.TabPane tab={t("buildingUnits.overview")} key="#overview">
                <BuildingUnitDetails
                  buildingUnit={buildingUnit}
                  setBuildingUnit={setBuildingUnit}
                ></BuildingUnitDetails>
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("buildingUnits.contacts")} key="#contacts">
                <ContactsPane
                  props={props}
                  contacts={buildingUnit?.contacts}
                  contactable={{ id: buildingUnit?.id, type: "buildingUnit" }}
                ></ContactsPane>
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("schedules.schedule")} key="#schedule">
                <BuildingUnitSchedulerPane buildingUnit={buildingUnit} />
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("cleanings.cleanings")} key="#cleanings">
                <CleaningListComplex buildingUnit={buildingUnit} />
              </Tabs.TabPane>

              <Tabs.TabPane tab={t("buildingUnits.claims")} key="#claims">
                <ClaimsPane buildingUnit={buildingUnit} />
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingUnitProfile;
