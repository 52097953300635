import { MenuOutlined } from "@ant-design/icons";
import { Affix, Col, Collapse, notification, Row, Table } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SortableHandle } from "react-sortable-hoc";
import { OfferContext } from "../../pages/OfferProfile";
import DraggableTable from "../SortableTable/DraggableTable";
import { coreApi } from "./../../api/calls";
import OfferItem from "./OfferItem";
import OfferItemAdd from "./OfferItemAdd";

const OfferItemListContainer = ({ separatedData }) => {
  const { t } = useTranslation();

  const { offer, isOrder, refreshOffer } = useContext(OfferContext);

  const parseRegularityFromRrule = (cleaningGroup) => {
    let parsed = {};
    let rrule = cleaningGroup?.rrule_blueprint;
    rrule = rrule.replace("RRULE:", "");
    rrule = rrule.split(";");
    rrule.forEach((item) => {
      item = item.split("=");
      parsed[item[0]] = item[1];
    });

    if (!parsed.INTERVAL) {
      parsed.INTERVAL = 1;
    }

    if (parsed.BYDAY) {
      parsed.BYDAY = parsed.BYDAY.split(",");
      parsed.REPETITION_COUNT = parsed.BYDAY.length;
    }
    return parsed;
  };

  const calculateHoursPerMonth = (cleaningGroup, cleaningMinutes, cleaningCount) => {
    let parsedCleaningGroup = parseRegularityFromRrule(cleaningGroup);

    switch (parsedCleaningGroup.FREQ) {
      case "WEEKLY":
        switch (parsedCleaningGroup.REPETITION_COUNT) {
          case 1:
            return (((cleaningMinutes * cleaningCount) / 60) * 4.33) / parsedCleaningGroup.INTERVAL;
          case 2:
            return ((((cleaningMinutes * cleaningCount) / 60) * 4.33) / parsedCleaningGroup.INTERVAL) * 2;
          case 3:
            return ((((cleaningMinutes * cleaningCount) / 60) * 4.33) / parsedCleaningGroup.INTERVAL) * 3;
          case 4:
            return ((((cleaningMinutes * cleaningCount) / 60) * 4.33) / parsedCleaningGroup.INTERVAL) * 4;
          case 5:
            return ((((cleaningMinutes * cleaningCount) / 60) * 4.33) / parsedCleaningGroup.INTERVAL) * 5;
          default:
            return 0;
        }
      case "MONTHLY":
        return (cleaningMinutes * cleaningCount) / 60 / parsedCleaningGroup.INTERVAL;
      case "YEARLY":
        return ((cleaningMinutes * cleaningCount) / 60) * (parsedCleaningGroup.INTERVAL / 12);
      default:
        return 0;
    }
  };

  const addCleaningGroup = (cleaningGroupName, rrule) => {
    let newGroup = {
      offer_id: offer?.id,
      building_unit_id: separatedData.building_unit?.id,
      name: cleaningGroupName,
      rrule: rrule,
    };

    coreApi
      .post("/cleaning-service-groups", newGroup)
      .then((res) => {
        refreshOffer(res.data.offer);
        notification.success({ message: res.data.message });
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }));
  };

  const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);

  const sortColumn = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
  ];

  const itemColumnRender = [
    {
      title: "offerItem",
      key: "cleaning_service_group_content_id",
      render: (content) => {
        return (
          <OfferItem
            key={content.cleaning_service_group_content_id}
            isOrder={isOrder}
            regularityList={[...separatedData?.cleaning_service_groups]}
            offerItem={content}
            idOffer={offer?.id}
            calculateHoursPerMonth={calculateHoursPerMonth}
            addCleaningGroup={addCleaningGroup}
          />
        );
      },
    },
  ];

  const itemColumn = [
    {
      title: "item",
      className: "drag-visible",
      render: (rowData) => (
        <Collapse bordered={false} ghost={true}>
          <React.Fragment key={rowData.cleaning_service_group_id}>
            <Collapse.Panel header={rowData?.name} key={rowData.cleaning_service_group_id}>
              {isOrder ? (
                <Table
                  size={"small"}
                  showHeader={false}
                  dataSource={rowData.cleaning_service_group_contents}
                  rowKey={"cleaning_service_group_content_id"}
                  columns={[...itemColumnRender]}
                ></Table>
              ) : (
                <DraggableTable
                  endpointUrl={"cleaning-service-group-contents/"}
                  propIdName={"cleaning_service_group_content_id"}
                  columns={[...sortColumn, ...itemColumnRender]}
                  data={rowData.cleaning_service_group_contents.map((content) => {
                    return {
                      ...content,
                      index: content.cleaning_service_group_content_id,
                    };
                  })}
                ></DraggableTable>
              )}
            </Collapse.Panel>
          </React.Fragment>
        </Collapse>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Affix offsetTop={64}>
          <Row
            gutter={[8, 0]}
            style={{
              backgroundColor: "rgba(240,242,245,1)",
              fontWeight: 600,
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Col span={3}></Col>
            <Col span={7}>{t("offers.columnHeaders.description")}</Col>
            <Col span={2}>{t("offers.columnHeaders.count")}</Col>
            <Col span={3}>{t("offers.columnHeaders.minutesPerCleaning")}</Col>
            <Col span={3}>{t("offers.columnHeaders.hoursPerMonth")}</Col>
            <Col span={3}>{t("offers.columnHeaders.pricePerMonth")}</Col>
            <Col span={3}></Col>
          </Row>
        </Affix>
        <Col span={24}>
          {isOrder ? (
            <Table
              dataSource={separatedData.cleaning_service_groups
                .filter((item) => item.cleaning_service_group_contents.length !== 0)
                .map((group) => {
                  return { ...group, index: group.id };
                })}
              columns={[...itemColumn]}
              showHeader={false}
            ></Table>
          ) : (
            <DraggableTable
              endpointUrl={"cleaning-service-groups/"}
              propIdName={"cleaning_service_group_id"}
              data={separatedData.cleaning_service_groups
                .filter((item) => item.cleaning_service_group_contents.length !== 0)
                .map((group) => {
                  return { ...group, index: group.id };
                })}
              columns={[...sortColumn, ...itemColumn]}
            />
          )}
        </Col>
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <OfferItemAdd
              isOrder={isOrder}
              regularityList={[...separatedData?.cleaning_service_groups]}
              calculateHoursPerMonth={calculateHoursPerMonth}
              addCleaningGroup={addCleaningGroup}
              parseRegularityFromRrule={parseRegularityFromRrule}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OfferItemListContainer;
