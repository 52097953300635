import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";

const ManagerSelect = ({
  clearable = false,
  label,
  validationMessage,
  initialValue,
  required,
  readOnly,
  showSearch = false,
  sendOnchange = null,
}) => {
  const { t } = useTranslation();

  const [managers, setManagers] = useState([]);

  useEffect(() => {
    coreApi
      .get("/employees/all")
      .then((response) => {
        setManagers(response.data);
      })
      .catch((error) => console.log(error));
  }, [t]);

  return (
    <Form.Item
      name="manager_id"
      label={label}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
      initialValue={initialValue}
    >
      <Select
        onChange={(id) => {
          if (sendOnchange) sendOnchange(id);
        }}
        allowClear={clearable}
        disabled={readOnly}
        bordered={!readOnly}
        showSearch={showSearch}
        placeholder={t("managers.selectManager")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {managers.map((manager) => {
          return (
            <Select.Option key={manager.id} value={manager.id}>
              {manager.first_name + " " + manager.last_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default ManagerSelect;
