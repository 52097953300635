import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "antd";
import axios from "axios";
import throwError from "../helpers/helpers";

/**
 * Transaction Invoices Pane
 * @component
 * @alias TransactionInvoicesPane
 * @property {object} transaction
 * @returns <Table />
 */
const TransactionInvoicesPane = ({ invoices }) => {
  // Translations
  const { t } = useTranslation();

  /**
   * Columns of the table
   */
  const columns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      render: (rowData) => {
        return (
          <Link style={{ fontWeight: 500 }} to={"/invoices/" + rowData?.id}>
            {rowData?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.invoiceTotalAmount"),
      key: "total_amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.total_amount);
      },
    },
    {
      title: t("finance.invoices.usedTotalAmount"),
      key: "amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData?.pivot?.amount);
      },
    },
    {
      title: t("finance.invoices.difference"),
      key: "total_amount",
      sorter: true,

      render: (rowData) => {
        let color = "#52c41a";
        let total = rowData?.total_amount - rowData?.pivot?.amount;

        if (total < 0) color = "#faad14";
        if (total > 0) color = "#f5222d";

        return (
          <strong style={{ color: color }}>
            {new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(total || 0)}
          </strong>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Table pagination={false} size="small" columns={columns} dataSource={invoices} rowKey={"invoice_number"} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionInvoicesPane;
