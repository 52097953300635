import { Badge, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { Collapse } from "antd";
import { Link } from "react-router-dom";

/**
 * @component
 * @alias AuditPane
 * @returns <Table />
 */
const ReminderPane = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const [reminders, setReminders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    coreApi
      .get("/reminders")
      .then((response) => {
        setReminders(Object.entries(response.data?.reminders));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton active={loading}></Skeleton>
      ) : (
        <Collapse>
          {reminders?.map((reminder) => {
            return (
              <Panel
                header={t("dashboard.reminders." + reminder[0])}
                key={reminder[0]}
                extra={<Badge count={reminder[1].error_count} />}
              >
                {Object.entries(reminder[1])
                  ?.slice(1) // skip error count
                  ?.map((resource) => {
                    return (
                      <div key={resource[0]}>
                        <h3 style={{ marginTop: "12px" }}>{t("dashboard.reminders." + resource[0])}</h3>

                        {resource[1]?.map((record) => {
                          return (
                            <div>
                              <Link to={"/" + reminder[0] + "/" + record?.id} key={record?.id}>
                                {record?.name
                                  ? record.name
                                  : record?.registration_number
                                  ? record.registration_number
                                  : record?.first_name && record?.last_name
                                  ? record.first_name + " " + record.last_name
                                  : record?.street && record?.house_number
                                  ? record.street + " " + record.house_number
                                  : record?.company_name
                                  ? record.company_name
                                  : record?.id}
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </Panel>
            );
          })}
        </Collapse>
      )}
    </>
  );
};

export default ReminderPane;
