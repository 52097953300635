import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * @component
 * @alias EmailFromSelect
 * @property {number} initialFromAddress - city_branch_id - it`s email get selected
 * @returns <Form.Item /> with <Select /> in it.
 */
const EmailFromSelect = ({ initialFromAddress }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/cities")
      .then((response) => {
        setCities(response.data);
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Form.Item name={"from"} label={t("emails.from")} rules={[{ required: true }]} initialValue={initialFromAddress}>
      <Select loading={loading} disabled={loading}>
        {cities.map((city, index) => {
          return (
            <Select.Option key={index} value={city.email}>
              {city.name + " <" + city.email + ">"}
            </Select.Option>
          );
        })}
        <Select.Option value={"no-reply@naseandulka.cz"}>No-reply mailer</Select.Option>
      </Select>
    </Form.Item>
  );
};

export default EmailFromSelect;
