import { CalendarOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Modal, notification, Row, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../../api/calls";
import ScheduleFirstStepMassEdit from "./ScheduleFirstStepMassEdit";
import ScheduleSecondStepMassEdit from "./ScheduleSecondStepMassEdit";
import ScheduleThirdStepMassEdit from "./ScheduleThirdStepMassEdit";

const ScheduleMultiStepMassEdit = ({ selectedBuildingUnits }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const [form] = Form.useForm();

  const steps = [
    {
      title: t("schedules.termination"),
      content: (
        <ScheduleFirstStepMassEdit
          form={form}
          moveToSecondStep={() => {
            setCurrent(1);
          }}
        />
      ),
    },
    {
      title: t("schedules.newRules"),
      content: (
        <ScheduleSecondStepMassEdit
          form={form}
          moveToFirstStep={() => {
            setCurrent(0);
          }}
          moveToThirdStep={() => {
            setCurrent(2);
          }}
        />
      ),
    },
    {
      title: t("common.summary"),
      content: (
        <ScheduleThirdStepMassEdit
          selectedBuildingUnits={selectedBuildingUnits}
          form={form}
          submitting={submitting}
          moveToSecondStep={() => {
            setCurrent(1);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    form.resetFields();
    setCurrent(0);
  }, [isOpen, form]);

  return (
    <React.Fragment>
      <Button
        size={"small"}
        icon={<CalendarOutlined />}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("schedules.rescheduleRules")}
      </Button>
      <Modal
        width={600}
        footer={null}
        title={t("schedules.rescheduleRules")}
        visible={isOpen}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Form
          form={form}
          // (values) as an argument not working here, the fields are not displayed in the summary
          onFinish={() => {
            setSubmitting(true);

            let values = form.getFieldsValue(true);
            values.date_terminate = values?.date_terminate?.format("YYYY-MM-DD") || null;
            values.date_from = values?.date_from?.format("YYYY-MM-DD") || null;
            values.date_to = values?.date_to?.format("YYYY-MM-DD") || null;
            values.building_units = selectedBuildingUnits.map((buildingUnit) => buildingUnit?.id);
            values.service_rrules = selectedBuildingUnits
              .map((buildingUnit) => {
                return buildingUnit.service_rrules.map((serviceRrule) => {
                  return serviceRrule.id;
                });
              })
              .flat();

            coreApi
              .post("/schedules/mass-create", values)
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });
                setOpen(false);
              })
              .catch((error) => {
                notification.error({ message: error?.response?.data?.message });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Steps current={current}>
                {steps.map((item, index) => (
                  <Steps.Step key={index} title={item.title} />
                ))}
              </Steps>
            </Col>
            <Divider />
            <Col span={24}>
              <div className="steps-content">{steps[current].content}</div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleMultiStepMassEdit;
