import { Card, Col, notification, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../../api/calls";

const CityBuildingsReport = ({ city, currencyIso4217, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [reports, setReports] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/reports/city/" + city?.id + "/orders", {
        params: {
          date_from: dateFrom ? dateFrom.format("YYYY-MM-DD") : null,
          date_to: dateTo ? dateTo.format("YYYY-MM-DD") : null,
        },
      })
      .then(({ data }) => {
        setReports(data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [city, dateFrom, dateTo]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title={t("reports.city.buildings.contractsConfirmedByUser")} size={"small"}>
          <Table
            rowKey={"id"}
            loading={loading}
            size={"small"}
            columns={[
              {
                title: t("employees.employee"),
                key: "employee",
                render: (data) => <Link to={"/employees/" + data.employee_id}>{data.employee}</Link>,
              },
              {
                title: t("reports.city.buildings.count"),
                key: "confirmations_count",
                dataIndex: "confirmations_count",
              },
              {
                title: t("reports.city.buildings.sum"),
                key: "confirmations_sum",
                dataIndex: "confirmations_sum",
                render: (sum) =>
                  new Intl.NumberFormat("cs-CZ", {
                    style: "currency",
                    currency: currencyIso4217 || "CZK",
                  }).format(sum),
              },
            ]}
            dataSource={reports?.orders_confirmed}
          ></Table>
        </Card>
      </Col>
      <Col span={24}>
        <Card title={t("reports.city.buildings.contractTerminations")} size={"small"}>
          <Table
            size={"small"}
            columns={[
              {
                title: t("offers.endOfCooperationTag"),
                key: "tag_name",
                dataIndex: "tag_name",
              },
              {
                title: t("reports.city.buildings.count"),
                key: "terminations_count",
                dataIndex: "terminations_count",
              },
              {
                title: t("reports.city.buildings.sum"),
                key: "terminations_sum",
                dataIndex: "terminations_sum",
                render: (sum) =>
                  new Intl.NumberFormat("cs-CZ", {
                    style: "currency",
                    currency: currencyIso4217 || "CZK",
                  }).format(sum),
              },
            ]}
            dataSource={reports?.orders_terminated}
          ></Table>
        </Card>
      </Col>
      <Col span={24}>
        <Card title={t("reports.city.buildings.contractTerminationsByEmployees")} size={"small"}>
          <Table
            size={"small"}
            rowKey={"id"}
            columns={[
              {
                title: t("employees.employee"),
                key: "employee",
                render: (data) => <Link to={"/employees/" + data.employee_id}>{data.employee}</Link>,
              },
              {
                title: t("reports.city.buildings.count"),
                key: "terminations_count",
                dataIndex: "terminations_count",
              },
              {
                title: t("reports.city.buildings.sum"),
                key: "terminations_sum",
                dataIndex: "terminations_sum",
                render: (sum) =>
                  new Intl.NumberFormat("cs-CZ", {
                    style: "currency",
                    currency: currencyIso4217 || "CZK",
                  }).format(sum),
              },
            ]}
            dataSource={reports?.orders_terminated_by_employee}
          ></Table>
        </Card>
      </Col>
    </Row>
  );
};

export default CityBuildingsReport;
