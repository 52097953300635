import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Cleaning Claim Tag Select
 * @component
 * @alias CleaningClaimTagSelect
 * @property {number} initialValue - tag_id
 * @property {bool} readOnly - sets <Select /> ready-only
 * @returns
 */
const CleaningClaimTagSelect = ({ initialValue, readOnly }) => {
  const { t } = useTranslation();

  const [claimTags, setClaimTags] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/cleanings/claim-tags", { params: {} })
      .then((response) => {
        if (isMounted) {
          setClaimTags(response.data);
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item label={t("cleanings.claimTag")} name={"claim_tag_id"} initialValue={initialValue}>
      <Select loading={loading} disabled={loading}>
        {claimTags.map((claimTag) => {
          return (
            <Select.Option key={claimTag?.id} value={claimTag?.id}>
              {claimTag.tag_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CleaningClaimTagSelect;
