import { UploadOutlined, ImportOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, notification, Row, Upload } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * City Import Data Modal - used for importing data (old way)
 * @deprecated
 * @component
 * @alias CityImportDataModal
 * @property {object} props - parent props
 * @returns <Modal /> that allows user to upload a file, whih then will be proccesed and it`s content imported to the database
 */
const CityImportDataModal = ({ props }) => {
  const { t } = useTranslation();

  const [uploadForm] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const [isOpen, setOpen] = useState(false);

  const [clientsPath, setClientsPath] = useState("");
  const [buildingsPath, setBuildingsPath] = useState("");
  const [partnersPath, setPartnersPath] = useState("");
  const [contactsPath, setContactsPath] = useState("");
  const [contactsBuildingsPath, setContactsBuildingsPath] = useState("");

  return (
    <React.Fragment>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        icon={<ImportOutlined />}
      >
        {t("imports.button")}
      </Button>
      <Modal
        title={t("imports.button")}
        visible={isOpen}
        onOk={() => {
          uploadForm.submit();
        }}
        okText={t("imports.button")}
        okButtonProps={{
          disabled:
            clientsPath === "" || buildingsPath === "" || partnersPath === "",
          loading: submitting,
        }}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Form
          layout={"vertical"}
          form={uploadForm}
          onFinish={(values) => {
            setSubmitting(true);

            coreApi
              .post("/cities/" + props.match.params.id + "/import", {
                clients_path: clientsPath,
                buildings_path: buildingsPath,
                partners_path: partnersPath,
                contacts_path: contactsPath,
                contacts_buildings_path: contactsBuildingsPath,
              })
              .then((response) => {
                notification.success({ message: response.data.message });
                setOpen(false);
              })
              .catch((error) => {
                notification.error({
                  message: error?.response?.data?.message,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          <Row gutter={[16, 24]}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={t("imports.files.clients")}
                name={"clients_csv"}
                valuePropName={"type"}
                rules={[
                  {
                    required: true,
                    message: t("imports.validation.clients"),
                  },
                ]}
              >
                <div style={{ textAlign: "center" }}>
                  <Upload
                    maxCount={1}
                    listType="picture"
                    className="avatar-uploader"
                    customRequest={({ onSuccess, onError, file }) => {
                      let payload = new FormData();
                      payload.append("clients", file);

                      coreApi
                        .post(
                          "/cities/" +
                            props.match.params.id +
                            "/import/clients",
                          payload
                        )
                        .then((res) => {
                          setClientsPath(res.data.path);
                          onSuccess({ status: "done" });
                        })
                        .catch((err) => {
                          notification.error({
                            message: err.response.data.message,
                          });
                        })
                        .finally();
                    }}
                    onChange={({ fileList }) => {
                      if (fileList.length === 0) setClientsPath("");
                    }}
                  >
                    {!clientsPath.length > 0 && (
                      <Button icon={<UploadOutlined />} block></Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={t("imports.files.buildings")}
                name={"buildings_csv"}
                valuePropName={"type"}
                rules={[
                  {
                    required: true,
                    message: t("imports.validation.buildings"),
                  },
                ]}
              >
                <div style={{ textAlign: "center" }}>
                  <Upload
                    maxCount={1}
                    listType="picture"
                    customRequest={({ onSuccess, onError, file }) => {
                      let payload = new FormData();
                      payload.append("buildings", file);

                      coreApi
                        .post(
                          "/cities/" +
                            props.match.params.id +
                            "/import/buildings",
                          payload
                        )
                        .then((res) => {
                          setBuildingsPath(res.data.path);
                          onSuccess({
                            status: "done",
                          });
                        })
                        .catch((err) => {
                          notification.error({
                            message: err.response.data.message,
                          });
                        })
                        .finally();
                    }}
                    onChange={({ fileList }) => {
                      if (fileList.length === 0) setBuildingsPath("");
                    }}
                  >
                    {!buildingsPath.length > 0 && (
                      <Button icon={<UploadOutlined />} block></Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label={t("imports.files.partners")}
                name={"partners_csv"}
                valuePropName={"type"}
                rules={[
                  {
                    required: true,
                    message: t("imports.validation.partners"),
                  },
                ]}
              >
                <div style={{ textAlign: "center" }}>
                  <Upload
                    maxCount={1}
                    listType="picture"
                    className="avatar-uploader"
                    customRequest={({ onSuccess, onError, file }) => {
                      let payload = new FormData();
                      payload.append("partners", file);

                      coreApi
                        .post(
                          "/cities/" +
                            props.match.params.id +
                            "/import/partners",
                          payload
                        )
                        .then((res) => {
                          setPartnersPath(res.data.path);
                          onSuccess({ status: "done" });
                        })
                        .catch((err) => {
                          notification.error({
                            message: err.response.data.message,
                          });
                        })
                        .finally();
                    }}
                    onChange={({ fileList }) => {
                      if (fileList.length === 0) setPartnersPath("");
                    }}
                  >
                    {!partnersPath.length > 0 && (
                      <Button icon={<UploadOutlined />} block></Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label={t("imports.files.contacts")}
                name={"contacts_csv"}
                valuePropName={"type"}
                hasFeedback
                rules={[
                  {
                    required: false,
                    message: t("imports.validation.partners"),
                  },
                ]}
              >
                <div style={{ textAlign: "center" }}>
                  <Upload
                    maxCount={1}
                    listType="picture"
                    className="avatar-uploader"
                    customRequest={({ onSuccess, onError, file }) => {
                      let payload = new FormData();
                      payload.append("contacts", file);

                      coreApi
                        .post(
                          "/cities/" +
                            props.match.params.id +
                            "/import/contacts",
                          payload
                        )
                        .then((res) => {
                          setContactsPath(res.data.path);
                          onSuccess({ status: "done" });
                        })
                        .catch((err) => {
                          notification.error({
                            message: err.response.data.message,
                          });
                        })
                        .finally();
                    }}
                    onChange={({ fileList }) => {
                      if (fileList.length === 0) setContactsPath("");
                    }}
                  >
                    {!contactsPath.length > 0 && (
                      <Button icon={<UploadOutlined />} block></Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label={t("imports.files.contactsBuildings")}
                name={"contacts_relations_csv"}
                valuePropName={"type"}
                hasFeedback
                rules={[
                  {
                    required: false,
                    message: t("imports.validation.contactRelation"),
                  },
                ]}
              >
                <div style={{ textAlign: "center" }}>
                  <Upload
                    maxCount={1}
                    listType="picture"
                    className="avatar-uploader"
                    customRequest={({ onSuccess, onError, file }) => {
                      let payload = new FormData();
                      payload.append("contacts", file);

                      coreApi
                        .post(
                          "/cities/" +
                            props.match.params.id +
                            "/import/contacts",
                          payload
                        )
                        .then((res) => {
                          setContactsBuildingsPath(res.data.path);
                          onSuccess({ status: "done" });
                        })
                        .catch((err) => {
                          notification.error({
                            message: err.response.data.message,
                          });
                        })
                        .finally();
                    }}
                    onChange={({ fileList }) => {
                      if (fileList.length === 0) setContactsBuildingsPath("");
                    }}
                  >
                    {!contactsBuildingsPath.length > 0 && (
                      <Button icon={<UploadOutlined />} block></Button>
                    )}
                  </Upload>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CityImportDataModal;
