import { Checkbox, Col, Form, Input, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PartnerLeadDetailPane = ({
  partnerLeadId,
  partnerLeadIdPropName,
  firstName,
  lastName,
  email,
  phoneNumber,
  doNotContact,
  endpoint,
  partnerLeadDetailsForm,
  setFormReadOnly,
  isFormReadOnly,
  setFormSending,
}) => {
  const [partnerLead, setPartnerLead] = useState({
    partnerLeadId: "",
    partnerLeadIdPropName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    doNotContact: "",
  });

  useEffect(() => {
    setPartnerLead({
      partnerLeadId: partnerLeadId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      doNotContact: doNotContact,
    });
  }, [partnerLeadId, doNotContact, phoneNumber, email, firstName, lastName]);

  const { t } = useTranslation();

  return partnerLead.partnerLeadId ? (
    <React.Fragment>
      <Form
        form={partnerLeadDetailsForm}
        onFinish={(values) => {
          console.log(values);
          setFormSending(true);
          coreApi
            .put(endpoint + values[partnerLeadIdPropName], values)
            .then((response) => {
              setFormReadOnly(true);
            })
            .catch((error) => {
              notification.error({});
            })
            .finally(() => {
              setFormSending(false);
            });
        }}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}></Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              hidden
              name={partnerLeadIdPropName}
              initialValue={partnerLead.partnerLeadId}
            >
              <Input
                readOnly={isFormReadOnly}
                bordered={!isFormReadOnly}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("common.firstName")}
              name="first_name"
              initialValue={partnerLead.firstName}
            >
              <Input
                readOnly={isFormReadOnly}
                bordered={!isFormReadOnly}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("common.lastName")}
              name="last_name"
              initialValue={partnerLead.lastName}
            >
              <Input
                readOnly={isFormReadOnly}
                bordered={!isFormReadOnly}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t("common.email")}
              name="email"
              initialValue={partnerLead.email}
            >
              <Input
                readOnly={isFormReadOnly}
                bordered={!isFormReadOnly}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("common.phone")}
              name="phone"
              initialValue={partnerLead.phoneNumber}
            >
              <Input
                readOnly={isFormReadOnly}
                bordered={!isFormReadOnly}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col span={8}>
            <Form.Item
              name="do_not_contact"
              label={t("partnerLeads.doNotContact")}
              valuePropName="checked"
              initialValue={partnerLead.doNotContact}
            >
              <Checkbox disabled={isFormReadOnly}></Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  ) : (
    ""
  );
};

export default PartnerLeadDetailPane;
