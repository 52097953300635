import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";
import CitySelector from "../Cities/CitySelector";
import ContactAddForm from "../ContactAddForm";

// gutter for <Rows />
const gutter = [16, 24];

/**
 * Building Add Form - form used for adding a new building record
 * @component
 * @alias BuildingAddForm
 * @property {object} client - object containing client details - default null
 * @property {function} onFinishAction - function for adding new record to react state
 * @returns <Button /> for opening and <Modal /> with <Form />
 */
const BuildingAddForm = ({ client = null, onFinishAction }) => {
  const { t } = useTranslation();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope?.areaScope;

  const [isModalOpen, setModalOpen] = useState(false);
  const [addBuildingForm] = Form.useForm();

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    coreApi
      .get("contacts/all")
      .then((res) => setContacts(res.data))
      .catch((err) => console.log(err));
  }, []);

  // ! Commented Google API request (for future use) - DO NOT DELETE
  // ! temporarly disabled 'cause of expired trial version of Google Maps
  // const googleMapsRequest = (field) => {
  //   setDataFetching(true);

  //   let { building_units } = addBuildingForm.getFieldValue();

  //   let address =
  //     building_units[field.key].street +
  //     " " +
  //     building_units[field.key].house_number +
  //     " " +
  //     addBuildingForm.getFieldValue("district") +
  //     " " +
  //     addBuildingForm.getFieldValue("postal_code") +
  //     " " +
  //     addBuildingForm.getFieldValue("city");
  //   Axios.get(
  //     "https://maps.googleapis.com/maps/api/geocode/json?address=" +
  //       address +
  //       "&key=AIzaSyB89-xgM4dGhRTaboQXybeaN7FycvuZ3mQ"
  //   )
  //     .then((res) => {
  //       console.log(res.data.results[0].geometry.location); // localized

  //       Object.assign(building_units[field.key], {
  //         location_geo:
  //           res.data.results[0].geometry.location.lat +
  //           ", " +
  //           res.data.results[0].geometry.location.lng, //String(geoLocation.lat) + ", " + String(geoLocation.lng),
  //       });
  //       addBuildingForm.setFieldsValue({
  //         building_units: [...building_units],
  //       });
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setDataFetching(false));
  // };

  const addContact = (values) => {
    console.log(values);
    coreApi
      .post("contacts", values)
      .then((res) => setContacts([...contacts, res.data.contact]))
      .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("buildings.add")}
      </Button>
      <Modal
        okText={t("common.create")}
        title={t("buildings.addBuilding")}
        visible={isModalOpen}
        onOk={addBuildingForm.submit}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={addBuildingForm}
          onFinish={async (values) => {
            await onFinishAction(values);
            setModalOpen(false);
          }}
          initialValues={{ building_units: [{ contacts: [{}] }] }}
        >
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item hidden hasFeedback name={"registration_number"}>
                <Input></Input>
              </Form.Item>
              <Form.Item hidden hasFeedback name={"client_id"} initialValue={Number(client?.id)}>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CitySelector initialValue={selectedCity} required={true} />
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                name={"city"}
                label={t("buildings.city")}
                rules={[
                  {
                    required: true,
                    message: t("buildings.validation.city"),
                  },
                ]}
              >
                <Input required></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                hasFeedback
                name={"district"}
                label={t("buildings.district")}
                rules={[
                  {
                    required: true,
                    message: t("buildings.validation.district"),
                  },
                ]}
              >
                <Input required></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                hasFeedback
                name={"postal_code"}
                label={t("buildings.postalCode")}
                rules={[
                  {
                    required: true,
                    message: t("buildings.validation.postalCode"),
                    pattern: /^[0-9]{5}$/,
                  },
                ]}
              >
                <Input required></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hasFeedback name={"cleaning_room"} label={t("buildings.cleaningRoomDescription")}>
            <TextArea cols={3} rows={2}></TextArea>
          </Form.Item>
          <Form.Item name={"notes"} label={t("buildings.buildingNotes")}>
            <TextArea cols={3} rows={2}></TextArea>
          </Form.Item>
          <Divider orientation="left" size={"small"}>
            <h4>{t("buildings.units")}</h4>
          </Divider>
          <Form.List name="building_units">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Row gutter={[8, 16]}>
                      <Col span={16}>
                        <Form.Item
                          hasFeedback
                          name={[field.name, "street"]}
                          key={[field.key, "street"]}
                          rules={[
                            {
                              required: true,
                              message: t("buildingUnits.validation.street"),
                            },
                          ]}
                        >
                          <Input placeholder={t("buildingUnits.street")}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          hasFeedback
                          name={[field.name, "house_number"]}
                          key={[field.key, "house_number"]}
                          rules={[
                            {
                              required: true,
                              message: t("buildingUnits.validation.houseNumbering"),
                            },
                          ]}
                        >
                          <Input placeholder={t("buildingUnits.houseNumbering")}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          hasFeedback
                          name={[field.name, "floor_count"]}
                          key={[field.key, "floor_count"]}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: t("buildingUnits.validation.floorCount"),
                          //   },
                          // ]}
                        >
                          <Input placeholder={t("buildingUnits.floorCount")}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          hasFeedback
                          name={[field.name, "ground_floor_count"]}
                          key={[field.key, "ground_floor_count"]}
                        >
                          <Input placeholder={t("buildingUnits.groundFloorCount")}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          hasFeedback
                          name={[field.name, "basement_floor_count"]}
                          key={[field.key, "basement_floor_count"]}
                        >
                          <Input placeholder={t("buildingUnits.basementFloorCount")}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.List name={[field.name, "contacts"]}>
                          {(contact_fields, { add, remove }) => {
                            return (
                              <div>
                                {contact_fields.map((contact_field) => (
                                  <Row gutter={[8, 16]}>
                                    <Col span={8}>
                                      <Form.Item
                                        hasFeedback
                                        name={[contact_field.name, "contact_id"]}
                                        key={[contact_field.key, "contact_id"]}
                                      >
                                        <Select
                                          clearIcon={true}
                                          showSearch={true}
                                          style={{ width: "100%" }}
                                          placeholder={t("buildingUnits.contactsResponsible")}
                                          dropdownRender={(menu) => (
                                            <div>
                                              {menu}
                                              <Divider></Divider>
                                              <ContactAddForm
                                                onFinishAction={addContact}
                                                addingInSelect={true}
                                              ></ContactAddForm>
                                            </div>
                                          )}
                                          filterOption={true}
                                          optionFilterProp="label"
                                        >
                                          {contacts?.map((contact) => (
                                            <Select.Option
                                              value={contact?.id}
                                              key={contact?.id}
                                              label={contact.first_name + " " + contact.last_name}
                                            >
                                              {contact.first_name + " " + contact.last_name}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Form.Item
                                        name={[contact_field.name, "is_invoice"]}
                                        key={[contact_field.key, "is_invoice"]}
                                        label={t("contacts.isInvoice")}
                                        valuePropName="checked"
                                      >
                                        <Checkbox></Checkbox>
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Form.Item
                                        name={[contact_field.name, "is_operation"]}
                                        key={[contact_field.key, "is_operation"]}
                                        label={t("contacts.isOperation")}
                                        valuePropName="checked"
                                      >
                                        <Checkbox></Checkbox>
                                      </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                      {contact_fields.length > 1 && (
                                        <Button
                                          danger
                                          type="dashed"
                                          onClick={() => {
                                            remove(contact_field.key);
                                          }}
                                          block
                                        >
                                          <MinusCircleOutlined />
                                          {t("common.remove")}
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => {
                                      add({});
                                    }}
                                    block
                                  >
                                    <PlusOutlined />
                                    {t("buildingUnits.addContact")}
                                  </Button>
                                </Form.Item>
                              </div>
                            );
                          }}
                        </Form.List>
                      </Col>
                      <Col span={24}>
                        {fields.length > 1 && (
                          <Button
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.key);
                            }}
                            block
                          >
                            <MinusCircleOutlined />
                            {t("common.remove")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add({});
                      }}
                      block
                    >
                      <PlusOutlined />
                      {t("buildings.addBuildingUnit")}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default BuildingAddForm;
