import { EditOutlined } from "@ant-design/icons";
import { Button, notification, Space, Table, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { coreApi } from "../../api/calls";
import CleaningClaimStatus from "./CleaningClaimStatus";
import CleaningEditModal from "./CleaningEditModal";
import MassClaimModal from "./MassClaimModal";
import MassPromoModal from "./MassPromoModal";
import MassSubstituteModal from "./MassSubstituteModal";

/**
 * Cleaning List Complex
 * @component
 * @alias CleaningListComplex
 * @property {object} partner
 * @property {object} buildingUnit
 * @returns <React.Fragment /> containing <Table /> that has all the cleanings with ability to filter data
 */
const CleaningListComplex = ({ partner = null, buildingUnit = null }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({});

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCleaning, setSelectedCleaning] = useState();

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [cleanings, setCleanings] = useState([]);
  const [units, setUnits] = useState([]);

  const refreshCleanings = useCallback(() => {
    setLoading(true);

    let params = {
      partner_id: partner ? partner.id : null,
      building_unit_id: buildingUnit ? buildingUnit?.id : null,
      date_from: dateFrom ? dateFrom.format("YYYY-MM-DD") : null,
      date_to: dateTo ? dateTo.format("YYYY-MM-DD") : null,
    };

    axios
      .all([
        coreApi.get("/cleanings/recurring", {
          params: params,
        }),
        coreApi.get("/cleanings/one-time", {
          params: params,
        }),
      ])
      .then(
        axios.spread((recurringCleaningsResponse, oneTimeCleaningsResponse) => {
          let { ...pagination } = recurringCleaningsResponse.data;

          setPagination(pagination);
          setCleanings([...recurringCleaningsResponse.data, ...oneTimeCleaningsResponse.data]);
        })
      )
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [partner, buildingUnit, dateFrom, dateTo]);

  useEffect(() => {
    refreshCleanings();
  }, [refreshCleanings]);

  useEffect(() => {
    let builidingUnits = [];

    cleanings.forEach((cleaning) => {
      if (!builidingUnits.find((buildingUnit) => buildingUnit.value === cleaning?.building_unit?.id)) {
        builidingUnits.push({
          text: cleaning?.building_unit?.street + " " + cleaning?.building_unit?.house_number,
          value: cleaning?.building_unit?.id,
        });
      }
    });

    setUnits(builidingUnits);
  }, [cleanings]);

  // Columns of table
  const tableColumns = [
    {
      title: t("cleanings.dateExpected"),
      key: "date_expected",
      dataIndex: "date_expected",
      sorter: (a, b) => moment(a.date_expected).unix() - moment(b.date_expected).unix(),
      render: (date) => moment(date).format("L"),
    },
    {
      title: t("schedules.buildingUnit"),
      key: "building_unit",
      sorter: (a, b) => a.building_unit.street.localeCompare(b.building_unit.street),
      render: (cleaning) => (
        <Link
          style={{ borderBottom: "1px dotted" }}
          to={"/buildings/" + cleaning?.building_unit?.building_id + "/unit/" + cleaning?.building_unit?.id}
        >
          {cleaning?.building_unit?.street + " " + cleaning?.building_unit?.house_number}
        </Link>
      ),
      filters: units,
      filterMultiple: true,
      onFilter: (value, record) => record?.building_unit?.id === value,
    },
    {
      title: t("cleanings.fullName"),
      key: "company_name",
      sorter: (a, b) =>
        a.partner?.last_name
          ? a.partner?.last_name.localeCompare(b.partner?.last_name)
          : a.partner?.company_name.localeCompare(b.partner?.company_name),
      render: (cleaning) => (
        <Link to={"/partners/" + cleaning?.partner?.id} style={{ borderBottom: "1px dotted" }}>
          {cleaning?.partner?.first_name + " " + cleaning?.partner?.last_name || cleaning?.partner?.company_name}
        </Link>
      ),
    },
    {
      title: t("cleanings.substitute"),
      key: "substitute",
      sorter: (a, b) =>
        a.substitute?.last_name
          ? a.substitute?.last_name.localeCompare(b.substitute?.last_name)
          : a.substitute?.company_name.localeCompare(b.substitute?.company_name),
      render: (cleaning) => {
        return (
          cleaning?.substitute_id && (
            <Tooltip
              title={
                cleaning?.substitute?.first_name + " " + cleaning?.substitute?.last_name ||
                cleaning?.substitute?.company_name
              }
            >
              <Link to={"/partners/" + cleaning?.substitute_id} style={{ borderBottom: "1px dotted" }}>
                {cleaning?.substitute?.first_name + " " + cleaning?.substitute?.last_name ||
                  cleaning?.substitute?.company_name}
              </Link>
            </Tooltip>
          )
        );
      },
    },
    {
      title: t("cleanings.price"),
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (cleaning) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: cleaning.currency_iso_4217 || "CZK",
        }).format(cleaning.price);
      },
    },
    {
      title: t("cleanings.promo"),
      key: "promo",
      sorter: (a, b) => a.promo - b.promo,
      render: (cleaning) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: cleaning.currency_iso_4217 || "CZK",
        }).format(cleaning.promo);
      },
    },
    {
      title: t("cleanings.partnerProfit"),
      key: "partner_profit",
      sorter: (a, b) => a.partner_profit - b.partner_profit,
      render: (cleaning) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: cleaning.currency_iso_4217 || "CZK",
        }).format(cleaning.partner_profit);
      },
    },
    {
      title: t("cleanings.annaProfit"),
      key: "anna_profit",
      sorter: (a, b) => a.anna_profit - b.anna_profit,
      render: (cleaning) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: cleaning.currency_iso_4217 || "CZK",
        }).format(cleaning.anna_profit);
      },
    },
    {
      title: t("cleanings.substituteProfit"),
      key: "substitute_profit",
      sorter: (a, b) => a.substitute_profit - b.substitute_profit,
      render: (cleaning) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: cleaning.currency_iso_4217 || "CZK",
        }).format(cleaning.substitute_profit);
      },
    },
    {
      title: t("cleanings.claimStatus"),
      key: "claim_status",
      render: (cleaning) => <CleaningClaimStatus cleaning={cleaning} />,
      filters: [
        {
          text: t("cleanings.claimInSolution"),
          value: "is_claim_in_solution",
        },
        {
          text: t("cleanings.claimSolved"),
          value: "is_claim_solved",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        return Boolean(record[value]) === true;
      },
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (cleaning) => (
        <Button
          size={"small"}
          icon={<EditOutlined />}
          onClick={() => {
            setSelectedCleaning(cleaning);
            setEditModalOpen(true);
          }}
        ></Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <SlideDown className={"my-dropdown-slidedown"} closed={selectedRows.length < 1}>
        {/* Multiple-buttons */}
        <Space style={{ paddingBottom: "16px" }}>
          <MassSubstituteModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            partnerId={partner?.id}
            refresh={refreshCleanings}
          />

          <MassPromoModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            partnerId={partner?.id}
            refresh={refreshCleanings}
          />

          <MassClaimModal
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            partnerId={partner?.id}
            refresh={refreshCleanings}
          />

          {/* <Button
            icon={<CalendarOutlined />}
            size={"small"}
            onClick={() => {
              console.log("dateRealChange");
              setSelectedMultipleActionType(4);
              setMultipleEditModalOpen(true);
            }}
          >
            {t("cleanings.dateRealChange")}
          </Button> */}
        </Space>
      </SlideDown>
      <Table
        size={"small"}
        rowKey={"id"}
        loading={loading}
        pagination={{
          ...pagination,
        }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys);
          },
        }}
        columns={tableColumns}
        dataSource={cleanings}
      />

      <CleaningEditModal
        refreshParent={() => {
          refreshCleanings();
        }}
        changeCleaning={(e, cleaning_id) => {
          var index = cleanings.findIndex((i) => i.cleaning_id === cleaning_id);

          if (cleanings[index + e]) setSelectedCleaning(cleanings[index + e]);
        }}
        cleaning={selectedCleaning}
        isOpen={isEditModalOpen}
        close={() => {
          setEditModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};

export default CleaningListComplex;
