import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import throwError from "../helpers/helpers";
import moment from "moment";

/**
 * @component
 * @alias AuditPane
 * @returns <Table />
 */
const AuditPane = () => {
  const { t } = useTranslation();

  const [audit, setAudit] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    coreApi
      .get("/audit")
      .then((response) => {
        setAudit(response.data);
      })
      .catch((e) => {
        throwError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  /**
   * Columns of the table
   */
  const columns = [
    {
      title: "User",
      key: "user",
      render: (audit) => audit?.user?.username,
    },
    {
      title: "Event",
      key: "event",
      dataIndex: "event",
    },
    {
      title: "Record",
      key: "record",
      render: (audit) => audit?.auditable_type + " " + audit?.auditable_id,
    },
    {
      title: "URL",
      key: "url",
      dataIndex: "url",
    },
    {
      title: "Date",
      key: "created_at",
      dataIndex: "created_at",
      render: (createdAt) => moment(createdAt)?.fromNow(),
    },
  ];

  return <Table dataSource={audit} columns={columns} loading={loading} pagination={false} size={"small"} />;
};

export default AuditPane;
