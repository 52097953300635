import { Card, Col, notification, Row, Statistic, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../../api/calls";

const CityGeneralInformationReport = ({ city, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [reports, setReports] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    coreApi
      .get("/reports/city/" + city?.id + "/general-information", {
        params: {
          date_from: dateFrom ? dateFrom.format("YYYY-MM-DD") : null,
          date_to: dateTo ? dateTo.format("YYYY-MM-DD") : null,
        },
      })
      .then(({ data }) => {
        setReports(data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [city, dateFrom, dateTo]);

  return (
    <Row gutter={[16, 24]}>
      <Col xs={24} md={6}>
        <Card>
          <Statistic loading={loading} title={t("expansion.cityManager")} value={reports?.manager} />
        </Card>
      </Col>
      <Col xs={24} md={6}>
        <Card>
          <Statistic loading={loading} title={t("reports.city.buildingsCount")} value={reports?.buildings_count} />
        </Card>
      </Col>
      <Col xs={24} md={6}>
        <Card>
          <Statistic
            loading={loading}
            title={t("reports.city.buildingUnitsCount")}
            value={reports?.building_units_count}
          />
        </Card>
      </Col>
      <Col xs={24} md={6}>
        <Card>
          <Statistic loading={loading} title={t("reports.city.partnersCount")} value={reports?.partners_count} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small" loading={loading}>
          <Table
            rowKey={"id"}
            loading={loading}
            size={"small"}
            columns={[
              {
                title: t("employees.employee"),
                key: "employee",
                render: (data) => <Link to={"/employees/" + data.employee_id}>{data.employee}</Link>,
              },
              {
                title: t("reports.city.buildingsCount"),
                key: "buildings_count",
                dataIndex: "buildings_count",
              },
              {
                title: t("reports.city.buildingUnitsCount"),
                key: "building_units_count",
                dataIndex: "building_units_count",
              },
              {
                title: t("reports.city.partnersCount"),
                key: "partners_count",
                dataIndex: "partners_count",
              },
            ]}
            dataSource={reports?.user_stats}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CityGeneralInformationReport;
