import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const gutter = [16, 24];

const ClientLeadAddForm = ({ duplicity, onFinishAction, checkDuplicity }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [addClientLeadForm] = Form.useForm();

  const [checked, setChecked] = useState(false);

  const [timesClicked, setTimesClicked] = useState(0);

  return (
    <React.Fragment>
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("clientLeads.add")}
      </Button>
      <Modal
        title={t("clientLeads.add")}
        visible={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={t("common.add")}
        onOk={() => {
          setTimesClicked(timesClicked + 1);
          addClientLeadForm.submit();
        }}
      >
        <Form
          form={addClientLeadForm}
          onFinish={async (values) => {
            if (!checked) {
              await checkDuplicity(values);
              setChecked(true);
            }

            if (!duplicity && checked) {
              await onFinishAction(values);
              setModalOpen(false);
            } else if (timesClicked % 2 === 0 && duplicity) {
              await onFinishAction(values);
              setModalOpen(false);
            }
          }}
          layout="vertical"
        >
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t("clientLeads.name")}
                onChange={() => {
                  setChecked(false);
                }}
                rules={[
                  {
                    required: true,
                    message: t("clientLeads.validation.name"),
                  },
                ]}
              >
                <Input
                  onChange={() => {
                    setChecked(false);
                  }}
                ></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={16}>
              <Form.Item
                name="billing_address_street"
                label={t("clientLeads.street")}
                rules={[
                  {
                    required: false,
                    message: t("clientLeads.validation.street"),
                  },
                ]}
              >
                <Input
                  onChange={() => {
                    setChecked(false);
                  }}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="units_count"
                label={t("clientLeads.unitsCount")}
                rules={[
                  {
                    required: false,
                    pattern: /^[1-9]{1,2}$/,
                    message: t("clientLeads.validation.unitsCount"),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  onChange={() => {
                    setChecked(false);
                  }}
                ></InputNumber>
              </Form.Item>
            </Col>
          </Row>
          {duplicity && checked && (
            <Row>
              <Col span={24}>
                <p style={{ color: "red", textAlign: "center" }}>
                  {t("clientLeads.validation.duplicity")}
                </p>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ClientLeadAddForm;
