import { CloseOutlined, EditOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, notification, Row, Space, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAreaScope } from "../../actions";
import { coreApi } from "../../api/calls";
import BuildingUnitLink from "../Buildings/BuildingUnitLink";
import CleaningClaimStatus from "../Cleanings/CleaningClaimStatus";
import CleaningEditModal from "../Cleanings/CleaningEditModal";
import EmployeeLink from "../Employees/EmployeeLink";
import PartnerLink from "../Partners/PartnerLink";
import ClaimsStatistics from "./ClaimsStatistics";

/**
 * Claims pane
 * @component
 * @alias ClaimsPane
 * @param {object} partner
 * @param {object} buildingUnit
 * @param {object} cityBranch
 * @param {object} user
 * @returns
 */
const ClaimsPane = ({ partner, buildingUnit, cityBranch, user }) => {
  const { t } = useTranslation();

  const defaultPageSize = 10;

  // Get city id from global filter
  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  // LOADING FLAGS
  const [claimsLoading, setClaimsLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);

  // EDIT
  const [selectedCleaning, setSelectedCleaning] = useState();
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  // DATASOURCES
  const [stats, setStats] = useState();
  const [claims, setClaims] = useState([]);
  const [claimTags, setClaimTags] = useState([]);

  // PARAMS
  const [params, setParams] = useState({
    date_from: null,
    date_to: null,
    users: user ? [user.id] : [],
    partners: partner ? [partner.id] : [],
    in_solution_only: null,
    solved_only: null,
    building_unit_id: buildingUnit ? buildingUnit?.id : null,
    city_branch_id: selectedCity ?? null,
    page: 1,
    page_size: 10,
  });

  const [filters, setFilters] = useState({});

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setFilters({ ...filters, district: selectedKeys[0] });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    let clearedFilters = filters;
    delete clearedFilters.district;
    setFilters({ ...clearedFilters });
  };

  // FILTER CONTENTS
  const [userList, setUserList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  // SELECTIONS
  const [selectedRows, setSelectedRows] = useState([]);

  // PAGINATIONS
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let isMounted = true;

    coreApi
      .get("/cleanings/claim-tags")
      .then((response) => {
        if (isMounted) {
          setClaimTags(response.data);
        }
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      });
    return () => {
      isMounted = false;
    };
  }, []);

  // Column definition for <Table /> component
  const columns = [
    {
      title: t("cleanings.dateExpected"),
      key: "date_expected",
      dataIndex: "date_expected",
      sorter: true,
      render: (date) => moment(date).format("L"),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Space>
            <DatePicker.RangePicker
              value={[selectedKeys[0], selectedKeys[1]]}
              onChange={(e) => setSelectedKeys(e !== null && e.length === 2 ? [e[0], e[1]] : [])}
            />
            <Button
              onClick={() => {
                clearFilters();
                let newParams = { ...params };
                newParams.date_from = null;
                newParams.date_to = null;
                setParams(newParams);
              }}
              icon={<CloseOutlined />}
            />
            <Button
              type={"primary"}
              onClick={() => {
                confirm({ closeDropdown: false });
                let newParams = { ...params };
                newParams.date_from = selectedKeys[0].format("YYYY-MM-DD");
                newParams.date_to = selectedKeys[1].format("YYYY-MM-DD");
                setParams(newParams);
              }}
              icon={<FilterOutlined />}
            />
          </Space>
        </div>
      ),
    },
    {
      title: t("cleanings.claimStatus"),
      key: "is_claim_solved",
      render: (cleaning) => <CleaningClaimStatus cleaning={cleaning} />,
      sorter: true,
      filterMultiple: false,
      filters: [
        {
          text: t("cleanings.claimInSolution"),
          value: "in_solution_only",
        },
        {
          text: t("cleanings.claimSolved"),
          value: "solved_only",
        },
      ],
    },
    {
      title: t("cleanings.promo"),
      key: "promo",
      render: (cleaning) => cleaning?.promo,
      sorter: true,
      filterMultiple: false,
      filters: [
        {
          text: t("cleanings.zeroPromoOnly"),
          value: 1,
        },
        {
          text: t("cleanings.notZeroPromoOnly"),
          value: 2,
        },
      ],
    },
    {
      title: t("cleanings.claimTag"),
      key: "claim_tag_id",
      render: (cleaning) => cleaning?.claim_tag?.claim_tag_translations[0]?.tag_name,
      sorter: true,
      filters: [
        ...claimTags.map((claimTag) => ({
          text: claimTag?.tag_name,
          value: claimTag?.id,
        })),
      ],
    },
    {
      title: t("cleanings.satisfactionCheckDate"),
      key: "date_satisfaction_check",
      sorter: true,
      render: (cleaning) =>
        cleaning.date_satisfaction_check ? moment(cleaning.date_satisfaction_check).format("L") : null,
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (cleaning) => (
        <Space>
          <Button
            size={"small"}
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedCleaning(cleaning);
              setEditModalOpen(true);
            }}
          ></Button>
        </Space>
      ),
    },
  ];

  if (!partner) {
    columns.splice(4, 0, {
      title: t("cleanings.partner"),
      key: "partner.last_name",
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "partners")}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />

          <Space>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, "partners")}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common.search")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      render: (rowData) => <PartnerLink partner={rowData?.partner} />,
      sorter: (a, b) => a?.partner?.company_name?.localeCompare(b?.partner?.company_name),

      // let uniquePartners = [
      //   ...new Set(
      //     rowData?.partners?.map((partner) => (
      //     ))
      //   ),
      // ];
      // return uniquePartners.join(", ");
    });
  }

  if (!buildingUnit) {
    columns.splice(5, 0, {
      title: t("schedules.buildingUnit"),
      key: "buildingUnit.street",
      dataIndex: "building_unit",
      render: (buildingUnit) => <BuildingUnitLink buildingUnit={buildingUnit} />,
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "buildingUnits")}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />

          <Space>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, "buildingUnits")}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common.search")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    });
  }

  if (!user) {
    columns.splice(columns.length - 1, 0, {
      // title: t("common.assignedUser"),
      // key: "users",
      // dataIndex: "partner",
      // render: (partner) => (
      //   <EmployeeLink toUser employee={partner.user.employee} />
      // ),
      // filters: userList.map((user) => {
      //   return {
      //     text: user?.employee?.first_name + " " + user?.employee?.last_name,
      //     value: user.id,
      //   };
      // }),
      // filterMultiple: true,

      title: t("common.assignedUser"),
      key: "partner.user.employee.first_name",
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "users")}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />

          <Space>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, "users")}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("common.search")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      render: (rowData) =>
        rowData?.partner?.user?.employee ? (
          <EmployeeLink toUser employee={rowData?.partner?.user?.employee} />
        ) : (
          t("schedules.notFilled")
        ),
      sorter: true,
    });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    let newParams = { ...params };
    newParams.partner = filters["partner.last_name"] ? filters["partner.last_name"][0] : null;
    newParams.building_unit = filters["buildingUnit.street"] ? filters["buildingUnit.street"][0] : null;
    newParams.user = filters["partner.user.employee.first_name"]
      ? filters["partner.user.employee.first_name"][0]
      : null;
    newParams.zeroPromoOnly = filters.promo?.includes(1) ? 1 : 0;
    newParams.notZeroPromoOnly = filters.promo?.includes(2) ? 1 : 0;
    newParams.in_solution_only = filters.is_claim_solved?.includes("in_solution_only") ? 1 : 0;
    newParams.solved_only = filters.is_claim_solved?.includes("solved_only") ? 1 : 0;
    newParams.page = pagination.current;
    newParams.page_size = pagination.pageSize;
    newParams.sorterOrder = sorter?.order;
    newParams.sorterKey = sorter?.columnKey;
    newParams.claimTagIds = filters?.claim_tag_id;
    setParams(newParams);
  };

  const refreshClaims = useCallback(() => {
    setStatsLoading(true);
    setClaimsLoading(true);

    coreApi
      .get("/claims/stats", { params: params })
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setStatsLoading(false);
      });

    coreApi
      .get("/claims", { params: { ...params, city_branch_id: selectedCity } })
      .then((response) => {
        setClaims(response.data.data);
        setCurrentPage(response.data.current_page);
        setPageSize(response.data.per_page);
        setTotal(response.data.total);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setClaimsLoading(false);
      });
  }, [params, selectedCity]);

  useEffect(() => {
    refreshClaims();
  }, [refreshClaims, selectedCity]);

  // Handling the situation where no user is passed via props and a filter has to be implemented and therefore, fetched
  useEffect(() => {
    if (!user) {
      coreApi
        .get("/users")
        .then((response) => {
          setUserList(response.data);
        })
        .catch((error) => {
          notification.error({ message: error?.response?.data?.message });
        });
    }
  }, [user]);

  // Handling the situation where no partner is passed via props and a filter has to be implemented and therefore, fetched
  useEffect(() => {
    if (!partner) {
      coreApi
        .get("/partners")
        .then((response) => {
          setPartnerList(response.data);
        })
        .catch((error) => {
          notification.error({ message: error?.response?.data?.message });
        });
    }
  }, [partner]);

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <ClaimsStatistics stats={stats} loading={statsLoading} />
        <Col span={24}>
          <Table
            size={"small"}
            rowKey={"id"}
            rowSelection={{
              selectedRowKeys: selectedRows,
              selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
              onChange: (selectedRowKeys) => {
                setSelectedRows(selectedRowKeys);
              },
            }}
            columns={columns}
            loading={claimsLoading}
            dataSource={claims}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              showTotal: (total, range) => (
                <p>
                  {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                  {" " + t("common.paginationPartTwo") + " "}
                  <strong>{total}</strong> {t("common.paginationPartThree")}
                </p>
              ),
            }}
          ></Table>

          <CleaningEditModal
            refreshParent={() => {
              refreshClaims(params);
            }}
            cleaning={selectedCleaning}
            isOpen={isEditModalOpen}
            close={() => {
              setEditModalOpen(false);
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClaimsPane;
