import { Col, Form, Input, List, Modal, notification, Row, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const OfferCopyModal = ({ isOpen, refreshOffer, setOpen, buildingUnits, idOffer, currentBuildingUnitId }) => {
  const { t } = useTranslation();

  const [copyForm] = Form.useForm();

  const [isLoading, setLoading] = useState(false);
  const [currentBuildingUnit, setCurrentBuildingUnit] = useState({});

  useEffect(() => {
    coreApi
      .get("/buildings/units/" + currentBuildingUnitId)
      .then((res) => {
        setCurrentBuildingUnit(res.data);
      })
      .catch((err) => notification.error({ message: err?.response?.data?.message }));
  }, [currentBuildingUnitId]);

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        copyForm.resetFields();
        setOpen(false);
      }}
      onOk={() => copyForm.submit()}
      okButtonProps={{ loading: isLoading }}
      title={t("offers.copy.title") + " " + currentBuildingUnit?.street + " " + currentBuildingUnit?.house_number}
    >
      <Typography.Text>{t("offers.copy.info")}</Typography.Text>

      {currentBuildingUnitId && (
        <List size={"small"}>
          <Form
            form={copyForm}
            onFinish={(values) => {
              setLoading(true);

              coreApi
                .post("offers/" + idOffer + "/copy-items", values)
                .then((res) => {
                  refreshOffer(res.data.offer);
                  copyForm.resetFields();
                  setOpen(false);
                })
                .catch((err) => notification.error({ message: err.response.data.message }))
                .finally(() => setLoading(false));
            }}
            initialValues={{
              building_units_to: [
                ...buildingUnits
                  .filter((i) => i?.id !== currentBuildingUnitId)
                  .map((bu) => {
                    return {
                      building_unit_id: bu?.building_unit_id,
                      street: bu?.street + " " + bu?.house_number,
                      copy: true,
                    };
                  }),
              ],
            }}
          >
            <Form.Item
              hidden
              name={"building_unit_id_from"}
              initialValue={currentBuildingUnitId}
              key={"building_unit_id_from"}
            ></Form.Item>

            <Form.List name="building_units_to">
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Form.Item
                        {...restField}
                        name={[name, "building_unit_id"]}
                        fieldKey={(restField.key, "building_unit_id")}
                        hidden
                      >
                        <Input />
                      </Form.Item>

                      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
                        <Col span={12}>
                          <Form.Item {...restField} name={[name, "street"]} fieldKey={(restField.key, "street")}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            valuePropName="checked"
                            name={[name, "copy"]}
                            fieldKey={(restField.key, "copy")}
                          >
                            <Switch></Switch>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        </List>
      )}
    </Modal>
  );
};

export default OfferCopyModal;
