import { Col, DatePicker, Divider, Form, Input, InputNumber, Modal, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { coreApi } from "../../api/calls";
import PartnerSelect from "../Partners/PartnerSelect";
import Checkbox from "antd/lib/checkbox/Checkbox";
import CleaningPromoTagSelect from "./CleaningPromoTagSelect";

/**
 * Recurring Cleaning Edit Form
 * @component
 * @alias RecurringCleaningAddForm
 * @property {object} form - form object
 * @property {function} onFinish - parent function that fires on form submit
 * @property {string} layout - form layout
 * @property {string} size - form size
 * @returns <Form /> components that has all fields for cleaning edit
 */
const RecurringCleaningAddForm = ({ isOpen, close, buildingUnit, selectedDate, refreshCalendarView }) => {
  const { t } = useTranslation();

  const [isSubmitting, setSubmitting] = useState(false);

  const [recurringCleaningForm] = Form.useForm();

  return (
    <Modal
      width={650}
      visible={isOpen}
      title={t("cleanings.recurring.createCleaning")}
      okText={t("common.create")}
      onOk={() => {
        recurringCleaningForm.submit();
      }}
      okButtonProps={{ loading: isSubmitting }}
      onCancel={() => {
        recurringCleaningForm.resetFields();
        close();
      }}
    >
      <Form
        layout={"horizontal"}
        form={recurringCleaningForm}
        onFinish={(values) => {
          setSubmitting(true);
          // again stupid moment.js null check 'cause it can NOT handle NULL
          values?.date_expected
            ? (values.date_expected = moment(values.date_expected).format("YYYY-MM-DD"))
            : (values.date_expected = null);

          values?.date_real
            ? (values.date_real = moment(values.date_real).format("YYYY-MM-DD"))
            : (values.date_real = null);
          coreApi
            .post("/cleanings/recurring", values)
            .then((response) => {
              notification.success({
                message: response.data.message,
              });
              refreshCalendarView();
              recurringCleaningForm.resetFields();
              close();
            })
            .catch((error) => {
              notification.error({
                message: error.response.data.message,
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        <Form.Item hidden name={"building_unit_id"} initialValue={buildingUnit?.id}>
          <Input></Input>
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <PartnerSelect
              name={"partner_id"}
              label={t("cleanings.partner")}
              validationMessage={t("cleanings.validation.partners")}
              required={true}
            />
          </Col>
          <Col xs={24} md={12}>
            <PartnerSelect name={"substitute_id"} label={t("cleanings.substitute")} />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={"date_expected"} label={t("cleanings.dateExpected")} initialValue={selectedDate}>
              <DatePicker disabled bordered={false} format="L" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name={"date_real"} label={t("cleanings.dateReal")} initialValue={selectedDate}>
              <DatePicker format="L" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Divider orientation={"left"}>{t("cleanings.values")}</Divider>
            <Form.Item name={"promo"} label={t("cleanings.promo")}>
              <InputNumber />
            </Form.Item>
            <Form.Item name={"service_ratio"} label={t("cleanings.serviceRatio")} initialValue={"0.4"}>
              <InputNumber></InputNumber>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Divider orientation={"left"}>{t("cleanings.promoCoveredBy")}</Divider>
            <Form.Item
              name={"is_promo_partner_covered"}
              valuePropName={"checked"}
              label={t("cleanings.promoCoveredByPartner")}
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              name={"is_promo_anna_covered"}
              valuePropName={"checked"}
              label={t("cleanings.promoCoveredByAnna")}
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item name={"is_full_partner_share"} valuePropName={"checked"} label={t("cleanings.fullPartnerShare")}>
              <Checkbox></Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <CleaningPromoTagSelect />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RecurringCleaningAddForm;
