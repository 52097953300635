import { StopOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Form, Input, Modal, notification, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

import { PartnerContext } from "../../pages/PartnerProfile";

const PartnerCancelModal = ({ partner }) => {
  const { t } = useTranslation();

  const partnerContext = useContext(PartnerContext);

  const { Option } = Select;

  const { refreshPartnerState } = partnerContext;

  const [form] = Form.useForm();

  const [tags, setTags] = useState([]);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [isCancelFormSubmitting, setCancelFormSubmitting] = useState(false);

  useEffect(() => {
    coreApi
      .get("partners/end-tags")
      .then((response) => {
        setTags(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <React.Fragment>
      <Button
        icon={<StopOutlined />}
        onClick={() => {
          setCancelModalOpen(true);
        }}
        danger
      >
        {t("partners.cancelPartner")}
      </Button>
      <Modal
        title={t("partners.cancelPartner")}
        visible={isCancelModalOpen}
        okText={t("partners.cancelPartner")}
        okButtonProps={{ loading: isCancelFormSubmitting }}
        onOk={() => {
          Modal.confirm({
            title: t("common.lastWarning"),
            content: t("partners.cancelPartnerConfirmation"),
            okText: t("partners.cancelPartner"),
            okButtonProps: { type: "danger" },
            onOk: () => {
              form.submit();
            },
          });
        }}
        onCancel={() => {
          setCancelModalOpen(false);
        }}
      >
        <Form
          form={form}
          layout={"vertical"}
          onFinish={(values) => {
            setCancelFormSubmitting(true);

            values.contract_ended = values.contract_ended.format("YYYY-MM-DD");

            coreApi
              .put("/partners/end-contract/" + partner.id, values)
              .then((response) => {
                refreshPartnerState(response.data.partner);
                notification.success({ message: response.data.message });
                setCancelModalOpen(false);
              })
              .catch((error) => {
                notification.error({
                  message: error?.response?.data?.message,
                });
              })
              .finally(() => {
                setCancelFormSubmitting(false);
              });
          }}
        >
          <Form.Item name={"contract_ended"} label={t("partners.contractEnded")} required>
            <DatePicker format={"L"}></DatePicker>
          </Form.Item>

          <Form.Item name="ending_reason_tag_id" label={t("partners.endingReason")} rules={[{ required: true }]}>
            <Select placeholder={t("partners.endingReason")}>
              {tags.map((tag) => {
                return <Option value={tag?.id}>{tag?.tag_name}</Option>;
              })}
            </Select>
          </Form.Item>

          {/* <Form.Item name={"ending_reason"} label={t("partners.endingReason")} required>
            <Input.TextArea></Input.TextArea>
          </Form.Item> */}

          <Form.Item name={"ending_note"} label={t("partners.endingNote")}>
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        </Form>
        <Divider></Divider>
        <Typography.Text type={"secondary"}>{t("partners.cancelPartnerWarning")}</Typography.Text>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerCancelModal;
