import { AutoComplete, Card, Col, Form, Row, Spin, Table, Tag, notification, DatePicker, Checkbox, Input } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import debounce from "lodash.debounce";
import PartnerLink from "../components/Partners/PartnerLink";
import PartnerSelect from "../components/Partners/PartnerSelect";
import axios from "axios";
import ManagerSelect from "../components/ManagerSelect";

/**
 * Page with all buildings units.
 *
 * @component
 * @alias BuildingUnitsIndex
 * @returns <Fragment /> with content
 */
const BuildingUnitsIndex = (props) => {
  // Translation constant
  const { t } = useTranslation();

  // States
  const [loading, setLoading] = useState(true);
  const [buildingUnits, setBuildingUnits] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const areaScope = useSelector(getAreaScope);
  const selectedCity = Number(areaScope.areaScope);
  const [autocompletePostalCodes, setAutocompletePostalCodes] = useState([]);
  const [autocompleteDistricts, setAutocompleteDistricts] = useState([]);
  const [autocompleteRegNums, setAutocompleteRegNums] = useState([]);

  // Columns of the table
  const columns = [
    {
      title: t("schedules.status"),
      key: "building.ending_date",
      sorter: true,
      render: (buildingUnit) => {
        if (buildingUnit?.building?.ending_date) {
          return <Tag color="red">{t("schedules.invalid")}</Tag>;
        } else {
          if (buildingUnit?.is_waiting === true) {
            return <Tag color="orange">{t("buildings.isWaiting")}</Tag>;
          } else {
            return <Tag color="green">{t("schedules.valid")}</Tag>;
          }
        }
      },
      filterMultiple: false,
      filters: [
        {
          text: t("schedules.valid"),
          value: "active",
        },
        {
          text: t("buildings.isWaiting"),
          value: "waiting",
        },
        {
          text: t("schedules.invalid"),
          value: "inactive",
        },
      ],
    },
    {
      title: t("expansion.managerName"),
      key: "employee",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => {
        let employee = rrules?.[rrules?.length - 1]?.partner?.user?.employee;

        return `${employee?.first_name || ""} ${employee?.last_name || ""}`;
      },
    },
    {
      title: t("buildings.registrationNumber"),
      key: "building.registration_number",
      dataIndex: "building",
      sorter: true,
      render: (building) => {
        return (
          <Link to={"/buildings/" + building?.id} style={{ borderBottom: "1px dotted" }}>
            {building?.registration_number}
          </Link>
        );
      },
    },
    {
      title: t("common.address"),
      key: "street",
      sorter: true,
      render: (_, row, index) => {
        return (
          <React.Fragment key={row?.id}>
            <Link to={"/buildings/" + row.building_id + "/unit/" + row?.id} style={{ fontWeight: 500 }}>
              {row.street + " " + row.house_number}
            </Link>
          </React.Fragment>
        );
      },
    },
    {
      title: t("common.city"),
      key: "city",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: t("common.district"),
      key: "district",
      dataIndex: "district",
      sorter: true,
    },
    {
      title: t("cleanings.partner"),
      key: "partner",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => {
        return <PartnerLink partner={rrules?.[rrules?.length - 1]?.partner} />;
      },
    },
    {
      title: t("schedules.pricePerUnit"),
      key: "building",
      dataIndex: "building",
      sorter: true,
      render: (building) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: building?.citryBranch?.countryBranch?.iso_4217_currency_code || "CZK",
        }).format(building?.price_per_unit);
      },
    },
    {
      title: t("schedules.cleaningDays"),
      key: "cleaning_days",
      dataIndex: "cleaning_days",
      sorter: true,
      render: (days) => {
        return days
          .map((day) => {
            if (day?.includes(",")) {
              let moreDays = [];

              day?.split(",").forEach((day) => {
                moreDays.push(t("rrules.byweekday." + day));
              });

              return moreDays;
            } else {
              return t("rrules.byweekday." + day);
            }
          })
          .join(", ");
      },
      filters: [
        {
          text: t("rrules.byweekday.MO"),
          value: "MO",
        },
        {
          text: t("rrules.byweekday.TU"),
          value: "TU",
        },
        {
          text: t("rrules.byweekday.WE"),
          value: "WE",
        },
        {
          text: t("rrules.byweekday.TH"),
          value: "TH",
        },
        {
          text: t("rrules.byweekday.FR"),
          value: "FR",
        },
        {
          text: t("rrules.byweekday.SA"),
          value: "SA",
        },
        {
          text: t("rrules.byweekday.SU"),
          value: "SU",
        },
      ],
    },
    {
      title: t("schedules.frequancy"),
      key: "serviceRrules.sort_weight",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => {
        return rrules
          .sort((a, b) => {
            return a.cleaning_service_group?.sort_weight - b.cleaning_service_group?.sort_weight;
          })
          ?.filter((rrule) => {
            return rrule?.rrule?.includes("WEEKLY");
          })[0]?.cleaning_service_group?.name;
      },
      ellipsis: true,
      width: "10%",
      filterMultiple: false,
      filters: [
        {
          text: "1x týdně",
          value: "FREQ=WEEKLY;BYDAY=",
        },
        {
          text: "1x 14 dní",
          value: "FREQ=WEEKLY;INTERVAL=2;",
        },
      ],
    },
    {
      title: t("schedules.cleaningsFrom"),
      key: "cleaningsStartFrom",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => {
        return moment(rrules?.[rrules?.length - 1]?.date_from)?.format("DD. MM. YYYY");
      },
    },
    {
      title: t("buildings.endingDate"),
      key: "building.ending_date",
      dataIndex: "building",
      sorter: true,
      render: (building) => {
        return building?.ending_date ? moment(building?.ending_date).format("DD. MM. YYYY") : "";
      },
    },
  ];

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (paginating, filter, sorting) => {
    setFilters({ ...filter, ...paginating });
    setSorter(sorting);
  };

  /**
   * Fetch data from API
   */
  const fetchData = useCallback(() => {
    setLoading(true);

    coreApi
      .get("/building-units", {
        params: {
          serviceRrules: filters?.service_rrules
            ? filters?.service_rrules?.includes("FREQ=WEEKLY;INTERVAL=2;")
              ? "FREQ=WEEKLY;INTERVAL=2;"
              : "FREQ=WEEKLY;BYDAY="
            : null,
          cleaningDays: filters?.cleaning_days || null,
          city_branch_id: selectedCity !== 0 ? Number(selectedCity) : null,
          sorterOrder: sorter?.order,
          sorterKey: sorter?.columnKey,
          cleaningsStartFrom: filters?.cleaningsStartFrom
            ? moment(filters?.cleaningsStartFrom).format("YYYY-MM-DD")
            : null,
          endingDate: filters?.endingDate ? moment(filters?.endingDate).format("YYYY-MM-DD") : null,
          registrationNumber: filters?.registrationNumber,
          page_size: filters?.pageSize || 10,
          page: filters?.current,
          district: filters?.district,
          dateConfirmed: filters?.dateConfirmed ? moment(filters?.dateConfirmed).format("YYYY-MM-DD") : null,
          contractNotSigned: filters?.contractNotSigned
            ? moment(filters?.contractNotSigned).format("YYYY-MM-DD")
            : null,
          status: filters?.["building.ending_date"]?.[0] || null,
          partner_id: filters?.partner_id,
          manager_id: filters?.manager_id,
          street: filters?.street,
        },
      })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setBuildingUnits(data);
        setPagination(pagination);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCity, filters, sorter]);

  useEffect(() => {
    fetchData();
  }, [filters, sorter, selectedCity, fetchData]);

  useEffect(() => {
    if (selectedCity === 0) return;

    setFilters({ city_branch_id: selectedCity });

    axios
      .all([
        coreApi.get("/buildings/postal-codes", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
        coreApi.get("/buildings/districts", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
        coreApi.get("/buildings/registration-numbers", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
      ])
      .then(
        axios.spread((postalCodes, districts, regNums) => {
          setAutocompletePostalCodes(
            postalCodes.data.map((item) => ({
              value: item.postal_code,
            }))
          );

          setAutocompleteDistricts(
            districts.data.map((item) => ({
              value: item.district,
            }))
          );

          setAutocompleteRegNums(
            regNums.data.map((item) => ({
              value: item.registration_number,
            }))
          );
        })
      )
      .catch((err) =>
        notification.error({
          message: err.response.data.message,
        })
      );
  }, [selectedCity]);

  /**
   * Handle street onchange filter with lodash
   */
  const handleStreetFilter = debounce((street) => {
    setFilters({ ...filters, street: street });
  }, 600);

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Card>
            <Row gutter={[8, 0]}>
              <Col span={4}>
                <Form.Item name="street" initialValue={filters?.street}>
                  <Input
                    debounce={true}
                    allowClear={true}
                    placeholder={t("common.address")}
                    onChange={(e) => handleStreetFilter(e?.target?.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="district" initialValue={filters?.district}>
                  <AutoComplete
                    allowClear={true}
                    autoClearSearchValue={true}
                    dropdownMatchSelectWidth={false}
                    notFoundContent={
                      !autocompleteDistricts.length > 0 && (
                        <Spin
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            justifyItems: "center",
                          }}
                        />
                      )
                    }
                    onSearch={(value) => autocompleteDistricts?.filter((item) => item.value.includes(value))}
                    placeholder={t("common.district")}
                    options={autocompleteDistricts}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(e) => setFilters({ ...filters, district: e })}
                  ></AutoComplete>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="registration_number" initialValue={filters?.registration_number}>
                  <AutoComplete
                    allowClear={true}
                    backfill={true}
                    notFoundContent={
                      !autocompleteRegNums.length > 0 && (
                        <Spin
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            justifyItems: "center",
                          }}
                        />
                      )
                    }
                    placeholder={t("buildings.registrationNumber")}
                    options={autocompleteRegNums}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(e) => setFilters({ ...filters, registrationNumber: e })}
                  ></AutoComplete>
                </Form.Item>
              </Col>

              <Col span={4}>
                <PartnerSelect
                  name={"partner_id"}
                  validationMessage={t("cleanings.validation.partners")}
                  required={false}
                  showSearch={true}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      partner_id: e?.id,
                    });
                  }}
                />
              </Col>

              <Col span={4}>
                <ManagerSelect
                  name={"manager_id"}
                  required={false}
                  showSearch={true}
                  sendOnchange={(id) => {
                    setFilters({
                      ...filters,
                      manager_id: id,
                    });
                  }}
                />
              </Col>

              <Col span={4}>
                <Form.Item name="cleanings_start_from">
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(e) => setFilters({ ...filters, cleaningsStartFrom: e })}
                    placeholder={t("schedules.cleaningsFrom")}
                  ></DatePicker>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="dateConfirmed">
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(e) => setFilters({ ...filters, dateConfirmed: e })}
                    placeholder={t("buildings.dateSigned")}
                  ></DatePicker>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="ending_date">
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(e) => setFilters({ ...filters, endingDate: e })}
                    placeholder={t("buildings.endingDate")}
                  ></DatePicker>
                </Form.Item>
              </Col>
            </Row>

            <Table
              size="small"
              loading={loading}
              rowKey={"id"}
              dataSource={buildingUnits}
              columns={columns}
              pagination={{
                ...pagination,
                showTotal: (total, range) => (
                  <p>
                    {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                    {" " + t("common.paginationPartTwo") + " "}
                    <strong>{total}</strong> {t("common.paginationPartThree")}
                  </p>
                ),
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingUnitsIndex;
