import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Modal, notification, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import moment from "moment";

const CancelModal = ({ offer, refreshOffer, isOrder }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState();
  const [isLoading, setLoading] = useState();

  const [rejectStates, setRejectStates] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    coreApi
      .get("offers/reject-states")
      .then((res) => setRejectStates([...res.data]))
      .catch((err) => notification.error({ message: err.response.data.message }));
  }, []);

  return (
    <React.Fragment>
      <Button
        danger
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("offers.cancel")}
      </Button>
      <Modal
        visible={isModalOpen}
        title={t("offers.confirmCancel")}
        okButtonProps={{ type: "danger" }}
        okText={t("offers.cancel")}
        onCancel={() => setModalOpen(false)}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout={"horizontal"}
          onFinish={(values) => {
            if (isOrder) {
              coreApi
                .post("/offers/cancel", {
                  ...values,
                })
                .then((res) => refreshOffer({ ...res.data.offer }))
                .catch((err) => console.log(err));
            } else {
              coreApi
                .post("/offers/cancel", {
                  ...values,
                })
                .then((res) => refreshOffer({ ...res.data.offer }))
                .catch((err) => console.log(err));
            }

            coreApi
              .post("/offers/update-reject-state/" + offer?.id, {
                ...values,
              })
              .then((res) => refreshOffer({ ...res.data.offer }))
              .catch((err) => notification.error({ message: err.response.data.message }));
          }}
        >
          <Row gutter={[16, 16]}>
            <Form.Item hidden name={"offer_id"} initialValue={offer?.id}></Form.Item>
            <Form.Item hidden name={"date_from"} initialValue={offer?.date_active_from}></Form.Item>
            <Col span={12}>
              <Form.Item name="date_to" initialValue={moment()} label={t("offers.dateActiveTo")}>
                <DatePicker></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rejected_state_id" initialValue={offer.rejected_state_id}>
                <Select loading={isLoading}>
                  {rejectStates?.map((state) => {
                    return (
                      <Select.Option key={state.rejected_state_id} value={state.rejected_state_id}>
                        {t("offers.rejectStates." + state.translation_key)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CancelModal;
