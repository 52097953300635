import { Divider, Card, Col, notification, Row, Space, Table, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import { coreApi } from "../api/calls";
import BuildingDetails from "../components/Buildings/BuildingDetails";
import BuildingUnitAddForm from "../components/Buildings/BuildingUnitAddForm";
import BuildingUnitContactPane from "../components/Buildings/BuildingUnitContactPane";
import BuildingInvoicePane from "../components/Buildings/BuildingInvoicePane";
import RecordActionsBar from "../components/RecordActionsBar";
import InitialInvoiceAddForm from "../components/Invoices/InitialInvoiceAddForm";
import CustomTabs from "../components/custom/CustomTabs";
import { BankOutlined } from "@ant-design/icons";
import ContactsPane from "../components/ContactsPane";
import PartnerLink from "../components/Partners/PartnerLink";

// Gutter for <Row>
const gutter = [16, 16];

/**
 * Page with detail of building
 * @component
 * @alias BuildingProfile
 * @returns <Card /> with content
 */
const BuildingProfile = (props) => {
  const { t } = useTranslation();

  // States
  const [isLoading, setLoading] = useState(false);
  const [building, setBuilding] = useState({});
  const [selectedBuildingUnits, setSelectedBuildingUnits] = useState([]);
  const [redirectState, setRedirectState] = useState(false);

  const { TabPane } = Tabs;

  useEffect(() => {
    setLoading(true);

    coreApi
      .get("buildings/" + props.match.params.id)
      .then((res) => {
        setBuilding(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.id]);

  // Create BildingUnit
  const addBuildingUnit = (values) => {
    // eslint-disable-next-line no-extend-native
    Array.prototype.unique = function () {
      var a = this.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) a.splice(j--, 1);
        }
      }

      return a;
    };

    values?.building_units?.forEach((unit) => {
      let payload = {
        ...unit,
        building_id: building?.id,
        city: building.city,
        district: building.district,
      };

      let addedBuildingUnitID = 0;

      coreApi
        .post("/buildings/units", payload)
        .then((res) => {
          addedBuildingUnitID = res.data.building_unit?.id;
          setBuilding({
            ...building,
            building_units: [...building.building_units, res.data.building_unit],
          });

          let mergeContacts = [
            ...unit.contacts_responsible,
            ...unit.contacts_operation,
            ...unit.contacts_invoice,
          ].unique();

          mergeContacts.forEach((contact) => {
            let payloadContact = {
              building_unit_id: addedBuildingUnitID,
              contact_id: contact,
              is_responsible: false,
              is_operation: false,
              is_invoice: false,
            };

            if (unit.contacts_responsible.includes(contact))
              payloadContact = { ...payloadContact, is_responsible: true };
            if (unit.contacts_operation.includes(contact)) payloadContact = { ...payloadContact, is_operation: true };
            if (unit.contacts_invoice.includes(contact)) payloadContact = { ...payloadContact, is_invoice: true };

            coreApi
              .post("contacts/assign-to-building-unit", payloadContact)
              .then((res) => notification.success({ message: res.data.message }))
              .catch((err) => console.log(err));
          });
        })

        .catch((err) => console.log(err));
    });
  };

  return (
    <React.Fragment key={building?.id}>
      <Row gutter={gutter}>
        <Col span={24}>
          <Card loading={isLoading}>
            <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Name of building */}
              <div>
                <Typography.Title>
                  {building?.registration_number ? building.registration_number + " - " : ""}
                  {String(building?.reference)}
                </Typography.Title>

                <Space>
                  <Typography.Title level={5} type="secondary" style={{ color: "#1890ff", fontWeight: 400 }}>
                    <Link to={`/clients/${building?.client_id}`}>
                      <Space size={[6, 12]}>
                        <BankOutlined />
                        {building?.client?.name}
                      </Space>
                    </Link>
                  </Typography.Title>
                </Space>
              </div>

              {/* Actions buttons */}
              <RecordActionsBar
                redirectState={redirectState}
                setRedirect={() => {
                  setRedirectState(true);
                }}
                firstModalTitle={t("buildings.delete.alertTitle")}
                firstModalContent={t("buildings.delete.alertContent")}
                entityRoute="/buildings"
                recordId={building?.id}
              ></RecordActionsBar>
            </Row>

            {/* Tab sections of resource */}
            <CustomTabs props={props}>
              <Tabs.TabPane key="#overview" tab={t("buildingUnits.overview")}>
                <BuildingDetails props={{ ...props }} building={building} setBuilding={setBuilding} />
              </Tabs.TabPane>

              <Tabs.TabPane key="#units" tab={t("buildings.buildingUnitsOverview")}>
                {/* <SlideDown className={"my-dropdown-slidedown"} closed={selectedBuildingUnits.length < 1}>
                  <Space style={{ paddingBottom: "16px" }}>
                    {selectedBuildingUnits.length > 0 && (
                      <InitialInvoiceAddForm
                        buildingUnitIds={selectedBuildingUnits}
                        displayed={true}
                        refreshScheduleList={null}
                      ></InitialInvoiceAddForm>
                    )}
                  </Space>
                </SlideDown> */}

                <BuildingUnitAddForm building={building} setBuilding={setBuilding} />

                <Table
                  size="small"
                  pagination={false}
                  style={{ height: "100%", marginTop: "16px" }}
                  loading={isLoading}
                  rowKey={"id"}
                  rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys) => {
                      setSelectedBuildingUnits([...selectedRowKeys]);
                    },
                  }}
                  columns={[
                    {
                      title: t("common.address"),
                      dataIndex: "id",
                      sorter: (a, b) => a.house_number - b.house_number,
                      render: (_, row, index) => {
                        return (
                          <React.Fragment key={row?.id}>
                            <Link to={"/buildings/" + row.building_id + "/unit/" + row?.id} style={{ fontWeight: 500 }}>
                              {row.street + " " + row.house_number}
                            </Link>
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      title: t("cleanings.partner"),
                      key: "partner",
                      render: (row) => {
                        return (
                          <PartnerLink partner={row?.service_rrules?.[row?.service_rrules?.length - 1]?.partner} />
                        );
                      },
                    },
                    {
                      title: t("schedules.cleaningDays"),
                      key: "cleaning_days",
                      dataIndex: "cleaning_days",
                      render: (days) => {
                        return days
                          .map((day) => {
                            return t("rrules.byweekday." + day);
                          })
                          .join(", ");
                      },
                    },
                    {
                      title: t("buildingUnits.city"),
                      dataIndex: "city",
                      sorter: (a, b) => a.city.localeCompare(b.city),
                    },
                    {
                      title: t("buildingUnits.district"),
                      dataIndex: "district",
                      sorter: (a, b) => a.district.localeCompare(b.district),
                    },
                    {
                      title: t("buildingUnits.floorCount"),
                      dataIndex: "floor_count",
                      sorter: (a, b) => a.floor_count - b.floor_count,
                    },
                    {
                      title: t("buildingUnits.groundFloorCount"),
                      dataIndex: "ground_floor_count",
                      sorter: (a, b) => a.ground_floor_count - b.ground_floor_count,
                    },
                    {
                      title: t("buildingUnits.basementFloorCount"),
                      dataIndex: "basement_floor_count",
                      sorter: (a, b) => a.basement_floor_count - b.basement_floor_count,
                    },
                  ]}
                  dataSource={building.building_units}
                ></Table>
              </Tabs.TabPane>

              <Tabs.TabPane key="#issuedOffers" tab={t("buildings.issuedOffersAndOrders")}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Divider orientation={"left"}>{t("buildings.issuedOffers")}</Divider>
                    <Table
                      size="small"
                      pagination={{ pageSize: 5 }}
                      loading={isLoading}
                      dataSource={building.offers}
                      columns={[
                        {
                          title: t("offers.headerClientName"),
                          key: "client_id",
                          dataIndex: "header_client_name",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/clients/" + row.client_id} style={{ borderBottom: "1px dotted" }}>
                                {text}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.summaryDetails.total"),
                          key: "offer_id",
                          dataIndex: "price",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/offers/" + row.id} style={{ fontWeight: 500 }}>
                                {new Intl.NumberFormat("cs-CZ", {
                                  style: "currency",
                                  currency: row.currency_iso_4217,
                                }).format(text)}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.dateIssued"),
                          key: "dateIssued",
                          dataIndex: "date_issued",
                        },
                      ]}
                    ></Table>
                  </Col>
                  <Col span={12}>
                    <Divider orientation={"left"}>{t("buildings.ordersOverview")}</Divider>
                    <Table
                      size="small"
                      loading={isLoading}
                      dataSource={building.orders}
                      pagination={{ pageSize: 5 }}
                      rowKey={"id"}
                      columns={[
                        {
                          title: t("offers.headerClientName"),
                          dataIndex: "header_client_name",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/clients/" + row.client_id} style={{ borderBottom: "1px dotted" }}>
                                {text}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.summaryDetails.total"),
                          dataIndex: "price",
                          render: (text, row, index) => {
                            return (
                              <Link to={"/orders/" + row.id} style={{ fontWeight: 500 }}>
                                {new Intl.NumberFormat("cs-CZ", {
                                  style: "currency",
                                  currency: row.currency_iso_4217,
                                }).format(text)}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("offers.dateIssued"),
                          dataIndex: "date_issued",
                        },
                      ]}
                    ></Table>
                  </Col>
                </Row>
              </Tabs.TabPane>

              <Tabs.TabPane key="#invoices" tab={t("buildingUnits.invoices")}>
                <BuildingInvoicePane />
              </Tabs.TabPane>

              <Tabs.TabPane key="#contacts" tab={t("buildingUnits.contacts")}>
                <Tabs defaultActiveKey="0">
                  <TabPane tab={t("contacts.onBuildings")} key="0">
                    <BuildingUnitContactPane
                      contacts={building.contacts}
                      contactableId={props.match.params.id}
                      contactableType={"building"}
                    />
                  </TabPane>

                  {building?.building_units?.map((buildingUnit, i) => {
                    return (
                      <>
                        <TabPane tab={`${buildingUnit?.street} ${buildingUnit?.house_number}`} key={i + 1}>
                          {buildingUnit?.contacts && (
                            <>
                              <ContactsPane
                                contacts={buildingUnit?.contacts}
                                contactable={{ id: buildingUnit?.id, type: "buildingUnit" }}
                              />
                            </>
                          )}
                        </TabPane>
                      </>
                    );
                  })}
                </Tabs>
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
        {/* <Col xs={24} lg={12} xxl={16}>
          <Card loading={isLoading} title={t("buildings.mapOverview")}>
            <BuildingsMapContainer
              buildingsDefaultList={[]}
              center={1}
              zoom={1}
            ></BuildingsMapContainer>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default BuildingProfile;
