import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { rrulestr } from "rrule";

const RruleDaySelect = ({ rrule, setSelectedDaysCount, postChange, hasFeedback }) => {
  const { t } = useTranslation();

  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const selectedDays = rrule ? rrulestr(rrule).origOptions.byweekday.map((weekday) => days[weekday.weekday]) : [];

  return (
    <Form.Item label={t("rrules.weekday")} name={"days"} required initialValue={selectedDays} hasFeedback={hasFeedback}>
      <Select
        mode={"multiple"}
        onChange={(values) => {
          if (setSelectedDaysCount) {
            setSelectedDaysCount(values.length);
          }
          if (postChange) {
            postChange(values);
          }
        }}
      >
        {days.map((day) => {
          return (
            <Select.Option key={day} value={day}>
              {t("rrules.byweekday." + day)}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default RruleDaySelect;
