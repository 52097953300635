import { Button, Col, DatePicker, Form, InputNumber, Row } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import "react-slidedown/lib/slidedown.css";
import PartnerSelect from "../../Partners/PartnerSelect";
import RruleDaySelect from "../RruleDaySelect";

const ScheduleSecondStepMassEdit = ({ form, moveToFirstStep, moveToThirdStep }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <RruleDaySelect rrule={null} />
      </Col>
      <Col span={12}>
        <Form.Item
          name={"date_from"}
          initialValue={
            form.getFieldValue("date_terminate") ? moment(form.getFieldValue("date_terminate")).add(1, "days") : null
          }
          label={t("schedules.dateFrom")}
          required
          hasFeedback
        >
          <DatePicker />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item name={"date_to"} initialValue={null} label={t("schedules.dateTo")}>
          <DatePicker />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <PartnerSelect name={"partner_id"} label={t("schedules.partner")} />
      </Col>
      <Col>
        <Form.Item label={t("schedules.serviceRatio")} name={"service_ratio"} initialValue={0.4} required>
          <InputNumber max={0.99} min={0.0} step={0.01}></InputNumber>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Button style={{ margin: "0 8px" }} block onClick={() => moveToFirstStep()}>
          {t("common.previous")}
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          block
          onClick={() => {
            form.validateFields();
            moveToThirdStep();
          }}
        >
          {t("common.next")}
        </Button>
      </Col>
    </Row>
  );
};

export default ScheduleSecondStepMassEdit;
