import { Button, DatePicker, Divider, Form, Modal, notification, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import moment from "moment";

/**
 * Account Fetch Modal - used for "downloading" trasactions from FIO API
 * @component
 * @alias AccountFetchModal
 * @property {object} account - object containing account details
 * @returns <Modal />
 */
const AccountFetchModal = ({ account }) => {
  const { t } = useTranslation();

  const [isFetchModalOpen, setFetchModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // contains dateFrom - dateTo
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return current >= moment().startOf("day");
    }
    const tooLate = (dates[0] && current > moment().startOf("day")) || (dates[0] && current.diff(dates[0], "days") > 7);
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 7;
    return tooEarly || tooLate;
  };

  // <DatePicker /> change handler
  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  const [accountFetchForm] = Form.useForm();

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setFetchModalOpen(true);
        }}
      >
        {t("finance.accounts.fetchBankingData")}
      </Button>
      <Modal
        title={t("finance.accounts.fetchBankingData")}
        visible={isFetchModalOpen}
        okText={t("finance.accounts.sync")}
        okButtonProps={{ loading: isLoading }}
        onOk={() => {
          accountFetchForm.submit();
        }}
        onCancel={() => {
          setFetchModalOpen(false);
        }}
      >
        <Form
          form={accountFetchForm}
          onFinish={(values) => {
            setLoading(true);
            values.date_from = values.range[0].format("YYYY-MM-DD");
            values.date_to = values.range[1].format("YYYY-MM-DD");
            delete values.range;

            coreApi
              .post("/accounts/fetch-remote/" + account?.id, values)
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });
                setFetchModalOpen(false);
              })
              .catch((error) => {
                notification.error({
                  message: error.response.data.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <Form.Item label={t("finance.accounts.interval")} name={"range"}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              value={hackValue || value}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setValue(val)}
              onOpenChange={onOpenChange}
            />
          </Form.Item>
          <Divider orientation={"left"}>{t("common.warning")}</Divider>
          <Typography.Paragraph>{t("finance.accounts.intervalWarning")}</Typography.Paragraph>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AccountFetchModal;
