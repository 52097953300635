import { DislikeOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, InputNumber, Modal, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-slidedown/lib/slidedown.css";
import { coreApi } from "../../api/calls";
import CleaningPromoTagSelect from "./CleaningPromoTagSelect";

/**
 * Mass Promo Modal - used for setting promo on multiple cleanings
 * @component
 * @alias MassPromoModal
 * @property {array} selectedRows - array of cleaning ids
 * @property {string} size - form size
 * @property {string} layout - form layout
 * @property {object} cleaning
 * @property {function} setSelectedRows
 * @property {function} refresh
 * @returns <Button /> component that opens a <Modal /> which contains form for setting the promo percentage
 */
const MassPromoModal = ({ selectedRows, size, layout, cleaning, setSelectedRows, refresh }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <Button
        icon={<DislikeOutlined />}
        size={"small"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("cleanings.massPromo")}
      </Button>

      <Modal
        width={"650px"}
        visible={isOpen}
        onCancel={() => {
          form.resetFields();
          setIsOpen(false);
        }}
        title={t("cleanings.massPromo")}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
      >
        <Form
          size={size}
          layout={layout}
          form={form}
          onFinish={(values) => {
            setLoading(true);
            coreApi
              .put("/cleanings/mass-edit/promo", {
                ...values,
                selectedRows: selectedRows,
              })
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });

                setSelectedRows([]);
                setIsOpen(false);
                refresh();
              })
              .catch((error) => {
                notification.error({
                  message: error.response.data.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item name={"promo"} label={t("cleanings.promo")} initialValue={cleaning?.promo}>
                <InputNumber
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  max={100}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <CleaningPromoTagSelect initialValue={cleaning?.promo_tag_id} />
            </Col>
            <Divider orientation={"left"}>{t("cleanings.promoCoveredBy")}</Divider>
            <Col xs={24} md={8}>
              <Form.Item
                name={"is_promo_partner_covered"}
                valuePropName={"checked"}
                label={t("cleanings.promoCoveredByPartner")}
                initialValue={true}
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name={"is_promo_anna_covered"}
                valuePropName={"checked"}
                label={t("cleanings.promoCoveredByAnna")}
                initialValue={true}
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name={"is_full_partner_share"}
                valuePropName={"checked"}
                label={t("cleanings.fullPartnerShare")}
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MassPromoModal;
