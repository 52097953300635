import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencySelector from "../Currencies/CurrencySelector";

const gutter = [16, 0];

const OfferAddForm = ({ client, building, addOffer, buttonSize }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const [form] = Form.useForm();

  return (
    <React.Fragment>
      <Button
        size={buttonSize}
        type={"primary"}
        onClick={() => {
          setModalOpen(true);
        }}
        icon={<PlusCircleOutlined />}
      >
        {t("common.add")}
      </Button>
      <Modal
        width={600}
        title={t("offers.addOffer")}
        visible={isModalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={t("common.add")}
        okButtonProps={{ loading: isFormSubmitting }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            console.log(values);
            setFormSubmitting(true);
            await addOffer(values);
            setFormSubmitting(false);
            setModalOpen(false);
          }}
          name={"addOfferForm"}
        >
          <Row>
            <Form.Item hidden name={"client_id"} initialValue={client?.id || client?.id_lead}>
              <Input></Input>
            </Form.Item>
            <Form.Item hidden name={"building_id"} initialValue={building?.id}>
              <Input></Input>
            </Form.Item>
            <Col span={24}>
              <Form.Item
                name={"header_client_name"}
                label={t("offers.headerClientName")}
                initialValue={client?.name}
                rules={[
                  {
                    required: true,
                    message: "Vyplň jméno klienta!",
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name={"header_client_street"}
                label={t("offers.headerClientStreet")}
                initialValue={client?.billing_address_street}
                rules={[
                  {
                    required: true,
                    message: "Vyplň ulici klienta!",
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={8}>
              <CurrencySelector required={true} />
            </Col>
            <Col span={8}>
              <Form.Item
                name={"offer_state_id"}
                label={t("offers.initialState")}
                rules={[
                  {
                    required: true,
                    message: "Vyplň úvodní stav!",
                  },
                ]}
              >
                <Select>
                  <Select.Option value={1}>{t("offers.states.waitingForInformations")}</Select.Option>
                  <Select.Option value={2}>{t("offers.states.offerProposal")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item name={"notes"} label={t("offers.notes")} initialValue={null}>
                <TextArea></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default OfferAddForm;
