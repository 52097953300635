import { UserSwitchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, notification, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-slidedown/lib/slidedown.css";
import { coreApi } from "../../api/calls";
import PartnerSelect from "../Partners/PartnerSelect";

/**
 * Mass Substitute Modal - used for setting substitute on multiple cleaning
 * @component
 * @alias MassSubstituteModal
 * @property {array} selectedRows - array of cleaning ids
 * @property {string} size - form size
 * @property {string} layout - form layout
 * @property {number} partnerId - partner_id
 * @property {function} setSelectedRows
 * @property {function} refresh
 * @returns <Button /> component that opens a <Modal /> which contains form for setting the claim tag
 */
const MassSubstituteModal = ({ selectedRows, size, layout, partnerId, setSelectedRows, refresh }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <Button
        icon={<UserSwitchOutlined />}
        size={"small"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("cleanings.massSubstitute")}
      </Button>

      <Modal
        width={"650px"}
        visible={isOpen}
        onCancel={() => {
          form.resetFields();
          setIsOpen(false);
        }}
        title={t("partners.multipleEdit.substitute")}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: loading }}
      >
        <Form
          size={size}
          layout={layout}
          form={form}
          onFinish={(values) => {
            setLoading(true);
            coreApi
              .put("/cleanings/mass-edit/substitutes", {
                default_partner: values.default_partner,
                substitute_id: values.substitute_id,
                cleaning_ids: [...selectedRows],
              })
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });

                setSelectedRows([]);
                refresh();
                setIsOpen(false);
              })
              .catch((error) => {
                notification.error({
                  message: error.response.data.message,
                });
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <PartnerSelect
                name={"default_partner"}
                label={t("cleanings.partner")}
                initialValue={partnerId}
                readOnly={true}
              />
            </Col>

            <Col xs={24} md={12}>
              <PartnerSelect name={"substitute_id"} label={t("cleanings.substitute")} disabledOption={partnerId} />
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MassSubstituteModal;
