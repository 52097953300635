import { Checkbox, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RRule, rrulestr } from "rrule";
import PartnerSelect from "../Partners/PartnerSelect";
import RruleDaySelect from "../Scheduler/RruleDaySelect";

const InitialBuildingUnitCleaningGenerator = ({ buildingUnit, form }) => {
  const { t } = useTranslation();

  const [rrule, setRrule] = useState("");
  const [cleaningDays, setCleaningDays] = useState([]);
  const [priceThisMonth, setPriceThisMonth] = useState();
  const [cleaningsStartDate, setCleaningsStartDate] = useState();
  const [potentialCleanings, setPotentialCleanings] = useState([]);
  const [newCleanings, setNewCleanings] = useState([]);

  useEffect(() => {
    if (rrule !== "" && cleaningDays.length > 0) {
      let ruleParsed = rrulestr(rrule);

      let potentialRule = new RRule({
        ...ruleParsed.origOptions,
        byweekday: cleaningDays,
        //startOf("month") does not work with momentjs
        dtstart: moment(cleaningsStartDate).subtract("60", "days").toDate(),
      });

      let newRule = new RRule({
        ...ruleParsed.origOptions,
        byweekday: cleaningDays,
        dtstart: moment(cleaningsStartDate).toDate(),
      });

      let potentialCleaningsDates = potentialRule.between(
        moment(cleaningsStartDate).startOf("month").toDate(),
        moment(cleaningsStartDate).endOf("month").toDate(),
        true
      );

      let newCleaningsDates = newRule.between(
        moment(cleaningsStartDate).toDate(),
        moment(cleaningsStartDate).endOf("month").toDate(),
        true
      );

      setPotentialCleanings(potentialCleaningsDates);
      setNewCleanings(newCleaningsDates);
      setPriceThisMonth(
        form.getFieldValue("order_price") * (newCleaningsDates.length / potentialCleaningsDates.length)
      );

      form.setFieldsValue({
        price_this_month:
          form.getFieldValue("order_price") * (newCleaningsDates.length / potentialCleaningsDates.length),
      });
    }
  }, [rrule, cleaningDays, cleaningsStartDate, form]);

  useEffect(() => {
    let cleanings = newCleanings.map((cleaning) => {
      return {
        date_expected: moment(cleaning),
        price: priceThisMonth / newCleanings.length,
        promo: 0,
        service_ratio: 0.4,
      };
    });

    form.setFieldsValue({ cleanings: [...cleanings] });
  }, [newCleanings, potentialCleanings, priceThisMonth, form]);

  const getRrrule = (selectedOrder) => {
    let result = selectedOrder[0].cleaning_service_groups
      ?.filter((cleaningServiceGroup) => {
        return cleaningServiceGroup?.rrule_blueprint?.includes("WEEKLY");
      })
      .sort(function (a, b) {
        return rrulestr(a.rrule_blueprint).options.interval - rrulestr(b.rrule_blueprint).options.interval;
      });

    return result[0]?.rrule_blueprint;
  };

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Form.Item hidden name={"building_unit_id"} initialValue={buildingUnit?.id}>
          <Input></Input>
        </Form.Item>
        <Col span={24}>
          <Divider orientation={"left"}>{buildingUnit.street + " " + buildingUnit.house_number}</Divider>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            name={"date_cleanings_start"}
            label={t("schedules.cleaningsFrom")}
            rules={[{ required: true }]}
            hasFeedback
            initialValue={moment()}
          >
            <DatePicker
              onChange={(value) => {
                setCleaningsStartDate(value);
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <RruleDaySelect
            postChange={(values) => {
              setCleaningDays(values);
            }}
            hasFeedback
          />
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"order_id"} label={t("offers.order")} rules={[{ required: true }]} hasFeedback>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => {
                let selectedOrder = buildingUnit.building.orders.filter((order) => {
                  return order?.id === value;
                });

                let building = buildingUnit.building;
                let unitCount = building.building_units.length;

                if (selectedOrder.length === 1) {
                  //let price = selectedOrder[0].price / selectedOrder[0]?.building?.building_units?.length;
                  setRrule(() => getRrrule(selectedOrder));
                  setPriceThisMonth(selectedOrder[0].price / unitCount);

                  form.setFieldsValue({ order_price: selectedOrder[0].price / unitCount });
                }
              }}
            >
              {buildingUnit.building.orders.map((order) => {
                return (
                  <Select.Option key={order?.id} value={order?.id}>
                    {t("offers.orderLabel", {
                      idOrder: order?.id,
                      from: moment(order.date_active_from).format("L"),
                      to: order?.date_active_to ? moment(order.date_active_to).format("L") : "...",
                    })}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Typography.Text type={"secondary"}>
            {t("finance.invoices.cleaningsBasedOnInputs", {
              newCleanings: newCleanings.length,
              potentialCleanings: potentialCleanings.length,
            })}
          </Typography.Text>
          <Divider />
        </Col>
        <Col>
          <Form.Item
            label={t("cleanings.serviceRatio")}
            name={"service_ratio"}
            rules={[
              {
                required: true,
                type: "number",
                min: 0.0,
                max: 0.9,
              },
            ]}
            hasFeedback
            initialValue={0.4}
          >
            <InputNumber min={0.0} max={0.9} step={0.05}></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <PartnerSelect name={"partner_id"} label={t("cleanings.partner")} required={true} />
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name={"order_price"} label={t("schedules.orderPrice")} rules={[{ required: true }]} hasFeedback>
            <InputNumber style={{ width: "100%" }} readOnly bordered={false}></InputNumber>
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name={"price_this_month"}
            label={t("schedules.priceThisMonth")}
            rules={[{ required: true }]}
            hasFeedback
          >
            <InputNumber
              value={priceThisMonth}
              style={{ width: "100%" }}
              onChange={(value) => {
                setPriceThisMonth(value);
              }}
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24}>
          <Form.List name={"cleanings"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => {
                  return (
                    <Row key={field.key} gutter={16}>
                      <Col>
                        <Form.Item
                          {...field}
                          label={t("cleanings.dateExpected")}
                          name={[field.name, "date_expected"]}
                          fieldKey={[field.fieldKey, "date_expected"]}
                          rules={[{ required: true }]}
                        >
                          <DatePicker size={"small"} format={"L"} disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          {...field}
                          label={t("cleanings.price")}
                          name={[field.name, "price"]}
                          fieldKey={[field.fieldKey, "price"]}
                          rules={[{ required: true }]}
                        >
                          <Input size={"small"} readOnly bordered={false}></Input>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          {...field}
                          label={t("cleanings.promo")}
                          name={[field.name, "promo"]}
                          fieldKey={[field.fieldKey, "promo"]}
                          initialValue={0}
                        >
                          <Input size={"small"}></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InitialBuildingUnitCleaningGenerator;
