import { Col, Divider, Form, Input, Row, Steps } from "antd";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddLineItemForm from "./AddLineItemForm";
import ClientInvoiceInfo from "./ClientInvoiceInfo";
import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceLocationForm from "./InvoiceLocationForm";
import InvoiceSelect from "./InvoiceSelect";
import InvoiceSummary from "./InvoiceSummary";
import PartnerInvoiceInfo from "./PartnerInvoiceInfo";

/**
 * @component
 * @alias CorrectiveDocumentForm
 * @property {object} form
 * @property {integer} currentStep
 * @property {integer} setStepTotal
 * @returns Form with steps of CorrectiveDocumentForm
 */
const CorrectiveDocumentForm = ({ form, currentStep, setStepTotal }) => {
  const { t } = useTranslation();

  // States
  const [invoice, setInvoice] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedRecipient, setSelectedRecipient] = useState({});

  const { Step } = Steps;

  useEffect(() => {
    setSelectedRecipient(invoice);
    setSelectedSupplier(invoice);

    console.log("Changed");
  }, [invoice]);

  useEffect(() => {
    form.resetFields();

    form.setFields([
      {
        name: "supplier_name",
        value: selectedSupplier && selectedSupplier?.supplier_name,
      },
      {
        name: "supplier_company_number",
        value: selectedSupplier && selectedSupplier?.supplier_company_number,
      },
      {
        name: "supplier_address",
        value: selectedSupplier && selectedSupplier?.supplier_address,
      },
      {
        name: "supplier_postal_code",
        value: selectedSupplier && selectedSupplier?.supplier_postal_code,
      },
      {
        name: "supplier_city",
        value: selectedSupplier && selectedSupplier?.supplier_city,
      },
      {
        name: "invoice_publisher_id",
        value: selectedSupplier && selectedSupplier?.invoice_publisher_id,
      },
      {
        name: "service_provider_id",
        value: selectedSupplier && selectedSupplier?.service_provider_id,
      },
      // ----------------------------------------------
      {
        name: "recipient_name",
        value: selectedRecipient && selectedRecipient?.recipient_name,
      },
      {
        name: "recipient_company_number",
        value: selectedRecipient && selectedRecipient?.recipient_company_number,
      },
      {
        name: "recipient_address",
        value: selectedRecipient && selectedRecipient?.recipient_address,
      },
      {
        name: "recipient_postal_code",
        value: selectedRecipient && selectedRecipient?.recipient_postal_code,
      },
      {
        name: "recipient_city",
        value: selectedRecipient && selectedRecipient?.recipient_city,
      },
      {
        name: "invoice_recipient_id",
        value: selectedRecipient && selectedRecipient?.invoice_recipient_id,
      },
      {
        name: "service_recipient_id",
        value: selectedRecipient && selectedRecipient?.service_recipient_id,
      },
      {
        name: "city_branch_id",
        value: invoice && invoice?.city_branch_id,
      },
      {
        name: "currency_iso_4217",
        value: invoice && invoice?.currency_iso_4217,
      },
      {
        name: "client_id",
        value: invoice && invoice?.client_id,
      },

      {
        name: "building_id",
        value: invoice && invoice?.building_id,
      },
    ]);
  }, [form, selectedRecipient, selectedSupplier, invoice]);

  const steps = [
    {
      title: t("finance.invoices.recipient") + " / " + t("finance.invoices.supplier"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Form.Item name={"client_id"} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={"building_id"} hidden>
                <Input />
              </Form.Item>
              <InvoiceSelect
                form={form}
                required={true}
                onChange={(e) => console.log(e)}
                showSearch={true}
                invoice={invoice}
                setInvoice={setInvoice}
              />
            </Col>
            <Col xs={24} md={12}>
              <Divider orientation="left">{t("finance.invoices.supplier")}</Divider>
            </Col>
            <Col xs={24} md={12}>
              <Divider orientation="left">{t("finance.invoices.recipient")}</Divider>
            </Col>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo isSupplier={true} isSummary={true} />
            </Col>
            <Col xs={24} md={12}>
              <ClientInvoiceInfo isSummary={true} isCompanyNumberRequired={false} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("finance.invoices.details"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Divider>{t("finance.invoices.details")}</Divider>
              <InvoiceDetailsForm />
            </Col>
            <Col xs={24} md={12}>
              <Divider>{t("finance.invoices.locale")}</Divider>
              <InvoiceLocationForm isSummary={true} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("finance.lineItems.lineItems"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("finance.lineItems.lineItems")}</Divider>
              <AddLineItemForm
                isCorrective={true}
                initialValue={[
                  {
                    content:
                      t("finance.lineItems.correctiveDocumentContent") +
                      " " +
                      invoice?.invoice_number +
                      " " +
                      t("finance.lineItems.correctiveDocumentContentReason"),
                  },
                ]}
              />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t("common.summary"),
      content: (
        <React.Fragment>
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24}>
              <Divider>{t("common.summary")}</Divider>
              <InvoiceSummary form={form} invoiceType={"partnerClient"} />
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    setStepTotal(steps.length - 1);
  }, [setStepTotal, steps.length]);

  return (
    <React.Fragment>
      <Row gutter={[16, 8]} style={{ width: "100%" }}>
        <Col span={24}>
          <Steps current={currentStep}>
            {steps.map((item, i) => (
              <Step key={i} title={item.title}></Step>
            ))}
          </Steps>
        </Col>
        <Divider></Divider>
        <Col span={24}>
          <div className="steps-content">{steps[currentStep].content}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CorrectiveDocumentForm;
