import { DownloadOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Divider, Form, notification, Row, Space, Statistic } from "antd";
import fileDownload from "js-file-download";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import PartnerBalanceCitySelector from "./PartnerBalanceCitySelector";
import { useParams } from "react-router-dom";

const PartnerBalancePane = ({ partner, ...props }) => {
  const { t } = useTranslation();

  const [balances, setBalances] = useState([]);

  // Display balance from all cities, or select specific one
  const [idCityBranch, setIdCityBranch] = useState(null);

  const params = useParams();

  const currency = partner?.city_branches[0]?.country_branch?.iso_4217_currency_code || "CZK";

  const [XML, setXML] = useState(null);

  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());
  const [activeCityBranch, setActiveCityBranch] = useState(null);

  const [balanceLoading, setBalanceLoading] = useState(false);

  //! this cant work without city_branch_id which isnt set
  useEffect(() => {
    setBalanceLoading(true);

    coreApi
      .get("partners/" + params?.id + "/balances", {
        params: {
          date_from: dateFrom.startOf("month").format("YYYY-MM-DD"),
          date_to: dateTo.endOf("month").format("YYYY-MM-DD"),
          city_branch_id: idCityBranch,
        },
      })
      .then((response) => {
        setBalances(response.data);
        setActiveCityBranch(response.data?.partner?.city_branch?.id);

        coreApi
          .post("payments/single-partner-payment", {
            date_to: dateTo,
            date_from: dateFrom,
            partner_id: params?.id,
            city_branch_id: idCityBranch || Number(response.data?.partner?.city_branch?.id),
          })
          .then((res) => {
            setXML(res.data.xml);
          })
          .catch((err) => setXML(null));
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setBalanceLoading(false);
      });
  }, [partner, idCityBranch, dateFrom, dateTo, params]);

  return (
    <React.Fragment>
      <Row gutter={[16, 0]}>
        <Col>
          <Form.Item label={t("finance.balances.period")}>
            <DatePicker.RangePicker
              defaultValue={[dateFrom, dateTo]}
              picker={"month"}
              format={"MMMM Y"}
              onChange={(selectedDates) => {
                setDateFrom(selectedDates[0]);
                setDateTo(selectedDates[1]);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <PartnerBalanceCitySelector
            name={"city_branch_id"}
            label={t("expansion.cities")}
            required={false}
            showSearch={true}
            setValue={setIdCityBranch}
            idPartner={params?.id}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Card title={t("finance.invoices.partnerBalance")} bordered={false}>
            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerClientInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_client_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesPublished")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_partner_provided_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerAnnaInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_anna_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.annaPartnerInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.anna_partner_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesReceived")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.partner_partner_received_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.correctiveDocuments")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.corrective_document_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />
            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={"Celkem"}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.partner?.balance_sum || 0)}
              valueStyle={{ color: "#096dd9", fontWeight: 500 }}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title={t("finance.invoices.workersBalance")} bordered={false}>
            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerClientInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_client_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesPublished")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_partner_provided_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerAnnaInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_anna_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.annaPartnerInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.anna_partner_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesReceived")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.partner_partner_received_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.correctiveDocuments")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.corrective_document_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />
            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={"Celkem"}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.workers_total?.balance_sum || 0)}
              valueStyle={{ color: "#096dd9", fontWeight: 500 }}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title={t("finance.invoices.totalBalance")} bordered={false}>
            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerClientInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_client_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesPublished")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_partner_provided_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerAnnaInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_anna_sum || 0)}
              valueStyle={{ color: "#10B981", fontSize: "20px" }}
            />

            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.annaPartnerInvoices")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.anna_partner_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.partnerPartnerInvoicesReceived")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.partner_partner_received_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />

            <Statistic
              loading={balanceLoading}
              title={t("finance.invoices.correctiveDocuments")}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.corrective_document_sum || 0)}
              valueStyle={{ color: "#EF4444", fontSize: "20px" }}
            />
            <Divider style={{ margin: "8px 0" }} />

            <Statistic
              loading={balanceLoading}
              title={"Celkem"}
              value={new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: currency,
              }).format(balances?.total?.balance_sum || 0)}
              valueStyle={{ color: "#096dd9", fontWeight: 500 }}
            />
          </Card>
        </Col>
      </Row>

      {XML && (
        <>
          <Divider />

          <Row gutter={[0, 16]} style={{ marginTop: "24px" }}>
            <Col span={24}>
              <pre style={{ fontStyle: "italic" }}>{XML?.split("<Orders>")[1].split("</Orders>")[0]}</pre>
            </Col>
          </Row>
        </>
      )}

      <Col span={8}>
        <Space style={{ marginTop: "12px" }}>
          {/* <Button type={"primary"} icon={<SendOutlined />}>
            {t("partners.sendBalanceEmail")}
          </Button> */}

          <Button
            type={"primary"}
            icon={<DownloadOutlined />}
            onClick={() => {
              coreApi
                .post("payments/single-partner-payment", {
                  date_to: dateTo,
                  date_from: dateFrom,
                  partner_id: params?.id,
                  city_branch_id: Number(activeCityBranch),
                })
                .then((res) => {
                  notification.success({ message: res.data.message });
                  fileDownload(
                    res.data.xml,
                    "client-payment-" +
                      params?.id +
                      "-" +
                      moment()?.format("YYYYMMDD") +
                      "-" +
                      moment().hour() +
                      moment().minute() +
                      moment().second() +
                      ".xml"
                  );
                })
                .catch((err) => console.log(err));
            }}
          >
            {t("partners.downloadBalanceOrder")}
          </Button>
        </Space>
      </Col>
    </React.Fragment>
  );
};

export default PartnerBalancePane;
