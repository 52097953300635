import { Button, Col, Divider, Form, Input, notification, Row, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { coreApi } from "../../api/calls";
import ContactActionBar from "./ContactActionBar";
import { useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";

/**
 * @component
 * @alias ContactProfile
 * @property {object} contact - parent state
 * @property {function} setContact - parent state setter
 * @returns Structured <Form /> for a contact detail
 */
const ContactProfile = ({ contact, setContact }) => {
  const { t } = useTranslation();

  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);
  const [deletingModalOpen, setDeletingModalOpen] = useState(false);

  let history = useHistory();

  const [contactForm] = Form.useForm();

  const gutter = [8, 0];

  const deleteContact = () => {
    coreApi
      .delete("contacts/" + contact?.id)
      .then((res) => {
        notification.success({
          message: t("common.recordDeleted"),
        });

        history.push("/contacts");
      })
      .catch((error) => {});
  };

  useEffect(() => {
    contactForm.resetFields();
  }, []);

  return (
    <React.Fragment>
      <Row gutter={gutter}>
        <Col span={24}>
          <Form
            form={contactForm}
            name="contact_form"
            onFinish={(values) => {
              setFormSending(true);
              coreApi
                .put("/contacts/" + values.contact_id, values)
                .then((response) => {
                  setFormReadOnly(true);
                  notification.success({
                    message: t("successNotifications.saveChanges.title"),
                  });
                })
                .catch((error) => {
                  notification.error({
                    message: t("errorNotifications.saveChanges.title"),
                    description: t("errorNotifications.saveChanges.message"),
                  });
                })
                .finally(() => {
                  setFormSending(false);
                });
            }}
          >
            <Row gutter={gutter}>
              <Col>
                <Form.Item hidden name="contact_id" initialValue={contact?.id}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <ContactActionBar
                  formInstance={contactForm}
                  isFormReadOnly={isFormReadOnly}
                  isFormSending={isFormSending}
                  setFormEditable={() => setFormReadOnly(false)}
                  setFormDisabled={() => setFormReadOnly(true)}
                  setDeletingModalOpen={setDeletingModalOpen}
                />
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={6}>
                <Form.Item name="first_name" label={t("contacts.firstName")} initialValue={contact?.first_name}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="last_name" label={t("contacts.lastName")} initialValue={contact?.last_name}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={6}>
                <Form.Item name="company_name" label={t("contacts.companyName")} initialValue={contact?.company_name}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={gutter}>
              <Col span={6}>
                <Form.Item name="email" label={t("contacts.email")} initialValue={contact?.email}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="phone" label={t("contacts.phone")} initialValue={contact?.phone}>
                  <Input readOnly={isFormReadOnly} bordered={!isFormReadOnly}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Divider></Divider>
            <Row gutter={gutter}>
              <Col span={12}>
                <Form.Item name="notes" label={t("contacts.notes")} initialValue={contact?.notes}>
                  <TextArea rows={4} readOnly={isFormReadOnly} bordered={!isFormReadOnly} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>

        <Modal
          visible={deletingModalOpen}
          title={t("contacts.delete.alertTitle")}
          onCancel={() => setDeletingModalOpen(false)}
          footer={[
            <Button onClick={() => setDeletingModalOpen(false)}>{t("contacts.storno")}</Button>,
            <Button
              type="danger"
              onClick={() => {
                Modal.confirm({
                  title: t("common.lastWarning"),
                  okText: t("common.delete"),
                  okButtonProps: { type: "danger" },
                  onOk: () => {
                    // Delete contact
                    deleteContact();
                    setDeletingModalOpen(false);
                  },
                });
              }}
            >
              {t("contacts.deleteContact")}
            </Button>,
          ]}
        >
          {t("contacts.delete.alertMessage")}
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default ContactProfile;
