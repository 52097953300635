import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PartnerInvoiceInfo = ({ isSupplier = false, isSummary = false }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={24}>
        <Form.Item
          name={isSupplier ? "supplier_name" : "recipient_name"}
          label={isSupplier ? t("finance.invoices.supplierName") : t("finance.invoices.recipientName")}
          rules={[{ required: true, message: "" }]}
        >
          <Input disabled={isSummary} bordered={!isSummary}></Input>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={isSupplier ? "supplier_company_number" : "recipient_company_number"}
          label={
            isSupplier ? t("finance.invoices.supplierCompanyNumber") : t("finance.invoices.recipientCompanyNumber")
          }
          rules={[{ required: true, message: "" }]}
        >
          <Input.TextArea rows={2} autoSize disabled={isSummary} bordered={!isSummary}></Input.TextArea>
        </Form.Item>
      </Col>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item
            name={isSupplier ? "supplier_address" : "recipient_address"}
            label={isSupplier ? t("finance.invoices.supplierAddress") : t("finance.invoices.recipientAddress")}
            rules={[{ required: true, message: "" }]}
          >
            <Input disabled={isSummary} bordered={!isSummary}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={isSupplier ? "supplier_postal_code" : "recipient_postal_code"}
            label={isSupplier ? t("finance.invoices.supplierPostalCode") : t("finance.invoices.recipientPostalCode")}
            rules={[
              {
                required: true,
                message: "",
                pattern: new RegExp("[0-9]{4,6}"),
              },
            ]}
          >
            <Input type={"number"} max={6} min={4} disabled={isSummary} bordered={!isSummary}></Input>
          </Form.Item>
        </Col>
      </Row>
      <Col span={24}>
        <Form.Item
          name={isSupplier ? "supplier_city" : "recipient_city"}
          label={isSupplier ? t("finance.invoices.supplierCity") : t("finance.invoices.recipientCity")}
          rules={[{ required: true, message: "" }]}
        >
          <Input disabled={isSummary} bordered={!isSummary}></Input>
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default PartnerInvoiceInfo;
