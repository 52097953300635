import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import BankingDetailsInputForm from "../BankingDetailsInputForm";
const gutter = [8, 0];

/**
 * @component
 * @alias ClientAddForm
 * @prop {function} addClient - function that sends post request to api
 * @prop {function} checkDuplicity - function for checking record duplicity in clients table - api get reqest with 'name' param
 * @prop {boolean} duplicity - boolean value that describes if duplicity exists
 * @prop {object} duplicateClient - object that contains duplicate client and it's then rendered as a link
 */

const ClientAddForm = ({ addClient, checkDuplicity, duplicity, duplicateClient }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isExternalDataFetching, setExternalDataFetching] = useState(false);

  const [checked, setChecked] = useState(false);

  let timesClicked = 0;

  const [form] = Form.useForm();

  const loadExternalData = (companyNumber) => {
    setExternalDataFetching(true);

    coreApi.get("/partners/get-public-data/" + companyNumber).then((response) => {
      setExternalDataFetching(false);

      form.setFieldsValue({
        name: response.data.company_name,
        billing_address_city: response.data.city,
        billing_address_street: response.data.street,
        billing_address_postal_code: response.data.postal_code,
        billing_address_house_number: response.data.house_number,
      });
    });
  };

  return (
    <React.Fragment>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="large"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("clients.addClient")}
      </Button>
      <Modal
        onCancel={() => {
          setModalOpen(false);
        }}
        onOk={() => {
          timesClicked++;
          form.submit();
        }}
        okText={t("common.add")}
        visible={isModalOpen}
        title={t("clients.addClient")}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            let isChecked = checked ? checked : true;

            if (!isChecked) {
              // check item if it's not checked
              await checkDuplicity(values);
              setChecked(true);
            }

            if (!duplicity && isChecked) {
              // item is checked and duplicity does NOT exist
              await addClient(values);
              form.resetFields();
              setModalOpen(false);
            } else if (timesClicked === 2 && duplicity && isChecked) {
              // is checked, duplicity exists and it's second time clicked
              await addClient(values);
              form.resetFields();
              setModalOpen(false);
            }

            setChecked(false);
          }}
          layout={"vertical"}
        >
          <Form.Item
            label={t("clients.name")}
            name={"name"}
            rules={[
              {
                required: true,
                message: t("clients.validation.name"),
              },
            ]}
          >
            <Input
              onChange={() => {
                // reset duplicity check procedure - new record to check
                timesClicked = 0;
                setChecked(false);
              }}
            ></Input>
          </Form.Item>
          <Row gutter={gutter}>
            <Col span={24}>
              <Form.Item name={"company_number"} label={t("clients.identificationNumber")}>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item hidden name={"tax_identification_number"} label={t("clients.taxIdentificationNumber")}>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={gutter}>
            <Col span={8}>
              <Form.Item>
                <Button
                  loading={isExternalDataFetching}
                  onClick={() => {
                    loadExternalData(form.getFieldValue("company_number"));
                  }}
                  size="small"
                >
                  {t("partners.loadExternalData")}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <FormItem>
                <Button
                  disabled={true}
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    alert("Not working yet.");
                  }}
                  size="small"
                >
                  {t("partners.selectFromLeads")}
                </Button>
              </FormItem>
            </Col>
          </Row>
          <Form.Item label={t("common.billingAddress")} required={true}>
            <Row gutter={gutter}>
              <Col span={16}>
                <Form.Item
                  name="billing_address_street"
                  rules={[
                    {
                      required: true,
                      message: t("clients.validation.billingAddressStreet"),
                    },
                  ]}
                >
                  <Input placeholder={t("common.billingAddressStreet")}></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="billing_address_house_number"
                  rules={[
                    {
                      required: true,
                      message: t("clients.validation.billingAddressHouseNumber"),
                    },
                  ]}
                >
                  <Input placeholder={t("common.billingAddressHouseNumber")}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={gutter}>
              <Col span={8}>
                <Form.Item
                  name="billing_address_postal_code"
                  rules={[
                    {
                      required: true,
                      message: t("clients.validation.billingAddressPostalCode"),
                    },
                  ]}
                >
                  <Input placeholder={t("common.billingAddressPostalCode")}></Input>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="billing_address_city"
                  rules={[
                    {
                      required: true,
                      message: t("clients.validation.billingAddressCity"),
                    },
                  ]}
                >
                  <Input placeholder={t("common.billingAddressCity")}></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <BankingDetailsInputForm form={form} setOpen={setModalOpen} addFunction={addClient} />
          {/* <Typography.Title level={4}>{t("menu.contacts")}</Typography.Title>
          <Form.List name="contacts">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <>
                      <Form.Item>
                        <Input.Group>
                          <Row gutter={[8, 16]}>
                            <Col span={12}>
                              <Input
                                required
                                placeholder={t("common.firstName")}
                              ></Input>
                            </Col>
                            <Col span={12}>
                              <Input placeholder={t("common.lastName")}></Input>
                            </Col>
                          </Row>
                        </Input.Group>
                        <Input.Group>
                          <Row gutter={[8, 16]}>
                            <Col span={9}>
                              <Input placeholder={t("common.email")}></Input>
                            </Col>
                            <Col span={9}>
                              <Input placeholder={t("common.phone")}></Input>
                            </Col>
                            <Col span={6}>
                              <Button
                                danger
                                type="dashed"
                                onClick={() => {
                                  remove(field.name);
                                }}
                                block
                              >
                                <MinusCircleOutlined />
                                {t("common.remove")}
                              </Button>
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>
                    </>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined />
                      {t("contacts.addContact")}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>*/}
        </Form>
        {duplicity && checked && (
          <Row>
            <Col span={24}>
              <p style={{ color: "red", textAlign: "center" }}>
                <p>
                  {t("clients.duplicate")}{" "}
                  <a
                    style={{ justifySelf: "center" }}
                    href={"/clients/" + duplicateClient?.id}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {duplicateClient.name}
                  </a>
                </p>

                {t("clientLeads.validation.duplicity")}
              </p>
            </Col>
          </Row>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default ClientAddForm;
