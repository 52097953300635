import React, { useState } from "react";
import { Button, Modal, Row, Col, DatePicker, Form, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import InvoiceExportButton from "../Invoices/InvoiceExportButton";
import { ExportOutlined } from "@ant-design/icons";
import CleaningExportButton from "../Cleanings/CleaningExportButton";
import BuildingUnitExportButton from "../Buildings/BuildingUnitExportButton";
import PartnerExportButton from "../Partners/PartnerExportButton";
import ContactExportButton from "../Contacts/ContactExportButton";
import ClientExportButton from "../Clients/ClientExportButton";
import PaymentExportButton from "../Transactions/PaymentExportButton";
import moment from "moment";

/**
 * City Export Modal
 * @component
 * @alias CityExportModal
 * @property {object} props - props of parent (index/profile) component
 * @property {object} city - contains details of city
 * @returns <Button /> that opens <Modal /> containing <Form />
 */
const CityExportModal = ({ props, city }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, "months").startOf("month"));
  const [dateTo, setDateTo] = useState(moment().subtract(1, "months").endOf("month"));
  const [exportAll, setExportAll] = useState(false);

  return (
    <React.Fragment>
      <Button type={"primary"} onClick={() => setOpen(true)} icon={<ExportOutlined />}>
        {t("exports.button")}
      </Button>
      <Modal visible={isOpen} onCancel={() => setOpen(false)} title={t("exports.title")}>
        <Form>
          <Form.Item
            name={"period"}
            initialValue={[
              moment().subtract(1, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month"),
            ]}
          >
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              allowClear={false}
              allowEmpty={false}
              onChange={(value) => {
                setDateFrom(value[0]);
                setDateTo(value[1]);
              }}
            ></DatePicker.RangePicker>
          </Form.Item>
        </Form>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <InvoiceExportButton
              exportAll={exportAll}
              invoiceTypeTranslationKey={"allInvoices"}
              invoiceExportTypeAPIEndpoint={""}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <CleaningExportButton
              exportAll={exportAll}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <BuildingUnitExportButton
              exportAll={exportAll}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <PartnerExportButton
              exportAll={exportAll}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <ContactExportButton
              exportAll={exportAll}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <ClientExportButton
              exportAll={exportAll}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <PaymentExportButton
              exportAll={exportAll}
              idCityBranch={props.match.params.id}
              cityName={city?.name}
              primary={true}
              dateFrom={dateFrom.format("YYYY-MM-DD")}
              dateTo={dateTo.format("YYYY-MM-DD")}
            />
          </Col>

          <Col span={12}>
            <Form.Item label="Exportovat za všechny města: ">
              <Checkbox onChange={(e) => setExportAll(e.target.checked)}></Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default CityExportModal;
