import { Card, Col, notification, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import EmployeeAddForm from "../components/EmployeeAddForm";
import { ClockCircleOutlined, UserOutlined, ToolOutlined, SafetyCertificateOutlined } from "@ant-design/icons";

const EmployeesIndex = ({ name }) => {
  const { t } = useTranslation();

  const [employees, setEmployees] = useState([]);
  const [isEmployeesListLoading, setEmployeesListLoading] = useState(false);
  const [isEmployeeFormSubmitting, setEmployeeFormSubmitting] = useState(false);

  useEffect(() => {
    setEmployeesListLoading(true);
    coreApi
      .get("/employees")
      .then((response) => {
        setEmployees(response.data);
        setEmployeesListLoading(false);
      })
      .catch();
  }, []);

  const addEmployee = (values) => {
    setEmployeeFormSubmitting(true);
    coreApi
      .post("/employees", values)
      .then((response) => {
        setEmployees([response.data.employee, ...employees]);
        notification.success({
          message: response.data.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      })
      .finally(() => {
        setEmployeeFormSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={24}>
          <Card size="small">
            <Table
              size="small"
              rowKey={"id"}
              loading={isEmployeesListLoading}
              columns={[
                {
                  title: t("common.fullName"),
                  key: "name",
                  sorter: (a, b) => a.last_name.localeCompare(b.last_name),
                  render: (employee) => (
                    <Link to={"/employees/" + employee.id}>{employee.first_name + " " + employee.last_name}</Link>
                  ),
                },
                {
                  title: t("employees.emailPersonal"),
                  key: "emailPersonal",
                  dataIndex: "email_personal",
                  sorter: (a, b) => a.email_personal.localeCompare(b.email_personal),
                  render: (email) => <a href={"mailto:" + email}>{email}</a>,
                },
                {
                  title: t("employees.email"),
                  key: "email",
                  dataIndex: "email_work",
                  sorter: (a, b) => a.email_work && b.email_work && a.email_work.localeCompare(b.email_work),
                  render: (email) => <a href={"mailto:" + email}>{email}</a>,
                },
                {
                  title: t("employees.phonePersonal"),
                  key: "phonePersonal",
                  dataIndex: "phone_personal",
                  sorter: (a, b) =>
                    a.phone_personal && b.phone_personal && a.phone_personal.localeCompare(b.phone_personal),
                  render: (phone) => <a href={"tel:" + phone}>{phone}</a>,
                },
                {
                  title: t("employees.phone"),
                  key: "phone",
                  dataIndex: "phone_work",
                  sorter: (a, b) => a.phone_work && b.phone_work && a.phone_work.localeCompare(b.phone_work),
                  render: (phone) => <a href={"tel:" + phone}>{phone}</a>,
                },
                {
                  title: t("employees.roles"),
                  key: "role",
                  render: (employee) => {
                    var role = employee?.user?.roles[0]?.name;
                    var color;
                    var icon;

                    // Set color and icon by role
                    if (role === "employee") {
                      color = "lime";
                      icon = <UserOutlined />;
                    } else if (role === "manager") {
                      color = "blue";
                      icon = <ToolOutlined />;
                    } else if (role === "admin") {
                      color = "red";
                      icon = <SafetyCertificateOutlined />;
                    }

                    return (
                      <React.Fragment>
                        {role ? (
                          <React.Fragment>
                            <Tag icon={icon} color={color}>
                              {t("roles." + role)}
                            </Tag>
                          </React.Fragment>
                        ) : (
                          <Tag icon={<ClockCircleOutlined />} color="default">
                            {t("roles.norole")}
                          </Tag>
                        )}
                      </React.Fragment>
                    );
                  },
                },
              ]}
              dataSource={employees}
            ></Table>
          </Card>
        </Col>
      </Row>
      <Row gutter={[8, 24]} style={{ paddingTop: "12px" }}>
        <Col span={24}>
          <EmployeeAddForm onFinishAction={addEmployee} isSubmitting={isEmployeeFormSubmitting} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EmployeesIndex;
