import { Card, Col, notification, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../../api/calls";

const CityClaimsAndPromosReport = ({ city, currencyIso4217, dateFrom, dateTo }) => {
  const { t } = useTranslation();

  const [reports, setReports] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/reports/city/" + city?.id + "/claims-and-promos", {
        params: {
          date_from: dateFrom ? dateFrom.format("YYYY-MM-DD") : null,
          date_to: dateTo ? dateTo.format("YYYY-MM-DD") : null,
        },
      })
      .then(({ data }) => {
        setReports(data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [city, dateFrom, dateTo]);

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card loading={loading} size={"small"} bodyStyle={{ padding: "10px" }}>
            <Table
              size={"small"}
              columns={[
                {
                  title: t("reports.city.claims"),
                  key: "tag_name",
                  dataIndex: "tag_name",
                },
                {
                  title: t("reports.city.claimCount"),
                  key: "claims_count",
                  dataIndex: "claims_count",
                },
              ]}
              rowKey={"id"}
              loading={loading}
              dataSource={reports?.claim_tags_stats}
              pagination={false}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card loading={loading} size={"small"} bodyStyle={{ padding: "10px" }}>
            <Table
              size={"small"}
              columns={[
                {
                  title: t("reports.city.promos"),
                  key: "tag_name",
                  dataIndex: "tag_name",
                },
                {
                  title: t("reports.city.promoCount"),
                  key: "promos_count",
                  dataIndex: "promos_count",
                },
                {
                  title: t("reports.city.promoSum"),
                  key: "promos_sum",
                  dataIndex: "promos_sum",
                  render: (sum) =>
                    new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(sum),
                },
              ]}
              rowKey={"id"}
              loading={loading}
              dataSource={reports?.promo_tags_stats}
              pagination={false}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={t("reports.city.claimsByEmployees")} size={"small"} bodyStyle={{ padding: "0px" }}>
            <Table
              size={"small"}
              columns={[
                {
                  title: t("employees.employee"),
                  key: "employee",
                  render: (data) => <Link to={"/employees/" + data?.employee_id}>{data.employee}</Link>,
                },
                {
                  title: t("reports.city.claimCount"),
                  key: "claims_count",
                  dataIndex: "claims_count",
                },
              ]}
              rowKey={"id"}
              loading={loading}
              dataSource={reports?.claims_by_employees}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title={t("reports.city.promosByEmployees")} size={"small"} bodyStyle={{ padding: "0px" }}>
            <Table
              size={"small"}
              columns={[
                {
                  title: t("employees.employee"),
                  key: "employee",
                  render: (data) => <Link to={"/employees/" + data?.employee_id}>{data.employee}</Link>,
                },
                {
                  title: t("reports.city.promoCount"),
                  key: "promos_count",
                  dataIndex: "promos_count",
                },
                {
                  title: t("reports.city.promoSum"),
                  key: "promos_sum",
                  dataIndex: "promos_sum",
                  render: (sum) =>
                    new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: currencyIso4217 || "CZK",
                    }).format(sum),
                },
              ]}
              rowKey={"id"}
              loading={loading}
              dataSource={reports?.promos_by_employees}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CityClaimsAndPromosReport;
