import { Col, Row, Space, Table, Tag, Form, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { coreApi } from "../../api/calls";
import InvoiceTypeSelect from "../Invoices/InvoiceTypeSelect";
import SmallMessage from "../SmallMessage";
import { useCallback } from "react";

/**
 * Building Units Invoices Pane
 * @component
 * @alias BuildingInvoicePane
 * @property {array} invoices
 * @returns <Table />
 */
const BuildingInvoicePane = () => {
  // Translations
  const { t } = useTranslation();

  // Id from URL
  const { id } = useParams();

  // States
  const [filterForm] = Form.useForm();
  const [invoices, setInvoices] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [sorter, setSorter] = useState({});

  // Values for filtering
  const [filters, setFilters] = useState({
    date_issued_from: null,
    date_issued_to: null,
    invoice_type: 1,
    page_size: 10,
    page: 1,
  });

  // Fetch data from API
  const fetchData = useCallback(() => {
    setDataLoading(true);

    coreApi
      .get("buildings/invoices/" + id, {
        params: {
          ...filters,
          sorterOrder: sorter?.order,
          sorterKey: sorter?.columnKey,
          date_issued_from: filters?.date_issued_from?.format("YYYY-MM-DD"),
          date_issued_to: filters?.date_issued_to?.format("YYYY-MM-DD"),
          invoice_type: filters?.invoice_type,
          page_size: 10,
          page: pagination?.current,
        },
      })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setPagination(pagination);
        setInvoices(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, [filters, id, pagination, sorter]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({ ...filters, page_size: pagination?.per_page, page: pagination?.current });

    setSorter(sorter);
    setPagination(pagination);
  };

  /**
   * Columns of the table
   */
  const invoiceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      render: (rowData) => {
        return (
          rowData?.transactions && (
            <Link style={{ borderBottom: "1px dotted" }} to={"/transactions/" + rowData?.transactions[0]?.id}>
              {rowData?.transactions[0]?.reference || rowData?.transactions[0]?.id}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      sorter: true,
      render: (rowData) => {
        return (
          <Link style={{ fontWeight: 500 }} to={"/invoices/" + rowData?.id}>
            {rowData?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.recipientName"),
      key: "recipient_name",
      dataIndex: "recipient_name",
      sorter: true,
    },
    {
      title: t("finance.invoices.recipientAddress"),
      key: "recipient_address",
      dataIndex: "recipient_address",
      sorter: true,
    },
    {
      title: t("finance.invoices.publisher"),
      key: "invoice_publisher_id",
      sorter: true,
      render: (rowData) => {
        return (
          rowData?.publisher && (
            <Link to={"/partners/" + rowData?.publisher?.id} style={{ borderBottom: "1px dotted" }}>
              {`${rowData?.publisher?.first_name} ${rowData?.publisher?.last_name}`}
            </Link>
          )
        );
      },
    },
    {
      title: t("finance.invoices.dateIssued"),
      key: "date_issued",
      dataIndex: "date_issued",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.dateDue"),
      key: "date_due",
      dataIndex: "date_due",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("DD.MM.YYYY");
      },
    },
    {
      title: t("finance.invoices.totalAmount"),
      key: "total_amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.total_amount);
      },
    },
    {
      title: t("finance.invoices.datePaid"),
      key: "date_paind",
      dataIndex: "date_paid",
      sorter: true,
      render: (rowData) => {
        return rowData ? (
          <Tag color="green">{moment(rowData).format("DD.MM.YYYY")}</Tag>
        ) : (
          <Tag color="red">{t("finance.invoices.unpaid")}</Tag>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Space>
            <Form layout={"inline"} form={filterForm} className="filterForm">
              <InvoiceTypeSelect
                initialValue={filters?.invoice_type}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    invoice_type: e,
                  });
                }}
              ></InvoiceTypeSelect>

              <Form.Item
                label={t("common.selectedPeriod")}
                name={"period"}
                initialValue={[filters?.date_issued_from, filters?.date_issued_to]}
              >
                <DatePicker.RangePicker
                  allowClear={false}
                  allowEmpty={false}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      date_issued_from: value[0] || moment().startOf("month"),
                      date_issued_to: value[1] || moment().endOf("month"),
                    });
                  }}
                ></DatePicker.RangePicker>
              </Form.Item>
            </Form>
          </Space>

          <SmallMessage resource="invoices" />
        </Col>

        <Col span={24}>
          <Table
            pagination={{
              ...pagination,
            }}
            size="small"
            loading={dataLoading}
            columns={invoiceTableColumns}
            dataSource={invoices}
            rowKey={"invoice_number"}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingInvoicePane;
