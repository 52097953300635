import { CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import PartnerSelect from "../components/Partners/PartnerSelect";

const ContactsIndex = (props) => {
  const { t } = useTranslation();

  const [contacts, setContacts] = useState([]);
  const [partners, setPartners] = useState([]);
  const [buildings, setBuildings] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [pagination, setPagination] = useState([]);

  const [selectedCells, setSelectedCells] = useState([]);

  let defaultFilters = {};
  const [filters, setFilters] = useState(defaultFilters);

  const [filterForm] = Form.useForm();

  const prepareDataForClipboard = () => {
    let outString = "";
    selectedCells.forEach((item) => {
      outString += item.email + "\n";
    });
    return outString;
  };

  const columns = [
    {
      title: t("contacts.name"),
      sorter: (a, b) => a?.last_name.localeCompare(b?.last_name),
      render: (rowData) => {
        return rowData?.contactables?.every((e) => e.ending_date !== null) ? (
          <del>
            <Link to={"/contacts/" + rowData?.id} style={{ fontWeight: 500 }}>
              {rowData?.first_name + " " + rowData?.last_name}
            </Link>
          </del>
        ) : (
          <Link to={"/contacts/" + rowData?.id} style={{ fontWeight: 500 }}>
            {!rowData?.first_name && !rowData?.last_name
              ? t("common.empty")
              : (rowData?.first_name || "") + " " + (rowData?.last_name || "")}
          </Link>
        );
      },
    },
    {
      title: t("contacts.companyName"),
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      render: (rowData) => {
        return rowData?.company_name ?? t("common.empty");
      },
    },
    {
      title: t("contacts.phone"),
      sorter: (a, b) => a.phone && b.phone && a.phone.localeCompare(b.phone),
      render: (rowData) => {
        return (
          <a href={`tel:${rowData.phone}`} style={{ borderBottom: "1px dotted" }}>
            {rowData?.phone}
          </a>
        );
      },
    },
    {
      title: (
        <Space>
          <Tooltip title={t("common.howToCopy")}>{t("contacts.email")}</Tooltip>
          {selectedCells.length > 0 && (
            <Tooltip title={t("common.copy")}>
              <Button
                style={{ border: "0px", background: "none" }}
                icon={<CopyOutlined />}
                onClick={() => {
                  try {
                    navigator.clipboard.writeText(prepareDataForClipboard());
                    message.success(t("common.copySuccess"), 1);
                  } catch (err) {
                    message.error(t("common.copyError"));
                  }

                  setTimeout(() => setSelectedCells(() => []), 250);
                }}
              ></Button>
            </Tooltip>
          )}
        </Space>
      ),
      sorter: (a, b) => a.email && b.email && a.email.localeCompare(b.email),
      className: "table-cell",
      render: (rowData) => {
        return (
          <span
            style={
              selectedCells.includes(rowData)
                ? {
                    height: "100%",
                    padding: "8px",
                    background: "rgba(105, 192, 255, 0.6)",
                    borderRadius: "5px",
                  }
                : { height: "100%", padding: "8px" }
            }
          >
            {rowData.email}
          </span>
        );
      },
      onCell: (record, _) => {
        return {
          onClick: (event) => {
            if (event.shiftKey) {
              if (selectedCells.includes(record)) {
                setSelectedCells(() => [...selectedCells.filter((item) => item !== record)]);
              } else {
                setSelectedCells(() => [...selectedCells, record]);
              }
            }
          },
        };
      },
    },
    {
      title: t("contacts.filters.building"),
      width: "20%",
      sorter: (a, b) =>
        a.buildings?.[0]?.reference &&
        b.buildings?.[0]?.reference &&
        a.buildings?.[0]?.reference.localeCompare(b.buildings?.[0]?.reference),
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            {rowData?.buildings?.map((building) => {
              return (
                <Tooltip title={building?.reference}>
                  <Link to={`/buildings/${building?.id}`} style={{ borderBottom: "1px dotted" }}>
                    {building?.reference}
                  </Link>
                </Tooltip>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t("schedules.cleaningDays"),
      width: "20%",
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            {rowData.buildings?.map((building) => {
              let cleaningDays = building?.building_units[0]?.cleaning_days;

              return (
                cleaningDays.length > 0 && (
                  <div style={{ display: "flex" }}>
                    {cleaningDays.map((cleaningDay) => {
                      return (
                        <Tag color="default">
                          {cleaningDay?.includes(",")
                            ? cleaningDay.split(",").map((day, i) => {
                                return t(`rrules.byweekday.${day}`) + " ";
                              })
                            : t(`rrules.byweekday.${cleaningDay}`)}
                        </Tag>
                      );
                    })}
                  </div>
                )
              );
            })}
          </div>
        );
      },
    },
    {
      title: t("contacts.contactType"),
      width: "30%",
      render: (contact) => {
        return (
          <Row gutter={[8, 0]}>
            {contact?.tags
              ?.filter((a, i) => contact?.tags.findIndex((s) => a.tag_id === s.tag_id) === i)
              ?.map((tag) => {
                return (
                  <Col span={5} key={tag?.id}>
                    <Tag
                      color={t(`contacts.tagColors.${tag?.tag?.key}`)}
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      {tag?.tag?.translation?.tag_name}
                    </Tag>
                  </Col>
                );
              })}
          </Row>
        );
      },
    },
  ];

  const setSearchParamsToFilters = useCallback(() => {
    if (!props.location.search) return;

    let filtersFromUrl = {};

    if (new URLSearchParams(props.location.search).get("partner_id"))
      filtersFromUrl = {
        ...filtersFromUrl,
        partner_id: Number(new URLSearchParams(props.location.search).get("partner_id")),
      };
    if (new URLSearchParams(props.location.search).get("email"))
      filtersFromUrl = {
        ...filtersFromUrl,
        email: String(new URLSearchParams(props.location.search).get("email")),
      };
    if (new URLSearchParams(props.location.search).get("building_id"))
      filtersFromUrl = {
        ...filtersFromUrl,
        building_id: new URLSearchParams(props.location.search).get("building_id")?.split(",").map(Number),
      };

    setFilters({ ...filtersFromUrl });

    return filtersFromUrl;
  }, [props.location.search]);

  const setFormFields = useCallback((filters) => {
    filterForm.setFields([
      { name: "email", value: filters?.email },
      { name: "partner_id", value: filters?.partner_id },
      { name: "building_id", value: filters?.building_id },
      { name: "is_invoice", value: filters?.is_invoice },
      { name: "is_operation", value: filters?.is_operation },
    ]);
  }, []);

  // On first load
  useEffect(() => {
    setLoading(true);

    axios
      .all([coreApi.get("/contacts"), coreApi.get("/partners/all")])
      .then(
        axios.spread((contacts, partners) => {
          let { data, ...pagination } = contacts.data;
          setContacts([...data]);
          setPagination({ ...pagination });
          setPartners([...partners.data]);
        })
      )
      .catch((err) => console.log(err))
      .finally(() => {
        // sets filters state based on url search params and returns filters before its in state variable
        let _filters = setSearchParamsToFilters();

        setFormFields(_filters);
        setLoading(false);
      });
  }, []);

  // On filters changes
  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/contacts", { params: { ...filters } })
      .then((res) => {
        let { data, ...rest } = res.data;
        setContacts(data);
        setPagination(rest);
      })
      .finally(() => setLoading(false));

    props.history.push({
      pathname: props.location.pathname,
      search: new URLSearchParams({ ...filters }).toString(),
    });
  }, [filters, props.history, props.location.pathname]);

  // On partner_id changes
  useEffect(() => {
    setLoading(true);
    if (filters.partner_id)
      coreApi
        .get("/buildings/by-partner", {
          params: { partner_id: filters.partner_id },
        })
        .then((res) => setBuildings([...res.data]))
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
  }, [filters.partner_id, filters, props.history, props.location.pathname]);

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Col span={24}>
              <Form form={filterForm} layout="horizontal" className="filterForm">
                <Row gutter={[16, 0]}>
                  <Col span={8}>
                    <PartnerSelect
                      name={"partner_id"}
                      label={t("contacts.filters.partner")}
                      initialValue={filters.partner_id}
                      onChange={(e) => setFilters({ ...filters, partner_id: e.id })}
                    />
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={"building_id"}
                      label={t("contacts.filters.building")}
                      initialValue={filters.building_id}
                      style={{ marginBottom: "12px" }}
                    >
                      <Select
                        mode={"tags"}
                        onChange={(val) => {
                          if (val?.length) {
                            setFilters({ ...filters, building_id: val });
                          } else {
                            let { building_id, ...rest } = filters;
                            setFilters({ ...rest });
                          }
                        }}
                      >
                        {buildings.map((building) => {
                          return (
                            <Select.Option key={building?.id} value={building?.id}>
                              {building.reference}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={"email"}
                      label={t("contacts.filters.email")}
                      initialValue={filters.email}
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        onChange={(val) => {
                          if (val.target.value === undefined || val.target.value === "" || val.target.value === null) {
                            let { email, ...rest } = filters;
                            setFilters({
                              ...rest,
                            });
                          } else {
                            setFilters({
                              ...filters,
                              email: val.target.value,
                            });
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={t("contacts.isInvoice")}>
                      <Checkbox
                        onChange={(val) => {
                          if (
                            val.target.checked === undefined ||
                            val.target.checked === false ||
                            val.target.checked === null
                          ) {
                            let { is_invoice, ...rest } = filters;
                            setFilters({ ...rest });
                          } else {
                            setFilters({
                              ...filters,
                              is_invoice: 1,
                            });
                          }
                        }}
                      ></Checkbox>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={t("contacts.isOperation")}>
                      <Checkbox
                        onChange={(val) => {
                          if (
                            val.target.checked === undefined ||
                            val.target.checked === false ||
                            val.target.checked === null
                          ) {
                            let { is_operation, ...rest } = filters;
                            setFilters({ ...rest });
                          } else {
                            setFilters({
                              ...filters,
                              is_operation: 1,
                            });
                          }
                        }}
                      ></Checkbox>
                    </Form.Item>
                  </Col>

                  {/* Filters reset button */}
                  <Form.Item style={{ marginLeft: "12px" }}>
                    <Link
                      onClick={() => {
                        filterForm.resetFields();
                        setFilters(defaultFilters);
                      }}
                    >
                      {t("common.resetFilters")}
                    </Link>
                  </Form.Item>
                </Row>
              </Form>
            </Col>

            <Table
              size={"small"}
              loading={isLoading}
              rowKey={"id"}
              dataSource={contacts}
              style={{ pointerEvents: "stroke" }}
              columns={columns}
              pagination={{
                pageSize: pagination?.per_page,
                total: pagination?.total,
                showSizeChanger: false,
                onChange: (e) => {
                  setFilters({ ...filters, page: e });
                },
                showTotal: (total, range) => (
                  <p>
                    {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                    {" " + t("common.paginationPartTwo") + " "}
                    <strong>{total}</strong> {t("common.paginationPartThree")}
                  </p>
                ),
              }}
            ></Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ContactsIndex;
