import { Button, Col, Form, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Money Return Pane
 * @component
 * @alias MoneyReturnPane
 * @property {number} buildingId - id of a building unit
 * @property {number} total - total sum of invoice and transaction(s)
 * @returns
 */
const MoneyReturnPane = ({ idClient, buildingId, invoice, total }) => {
  const [building, setBuilding] = useState({});

  const { t } = useTranslation();

  const [moneyReturnForm] = Form.useForm();

  const [dataLoading, setDataLoading] = useState(true);

  const [client, setClient] = useState({});
  const [account, setAccount] = useState({});
  const [countryBranch, setCountryBranch] = useState({});

  // Default date due (16th day of the current month)
  const [dateDue, setDateDue] = useState(new Date(moment()?.format("M") + "-16-" + moment()?.format("YYYY")));
  const [XML, setXML] = useState(null);

  useEffect(() => {
    coreApi.get("/buildings/" + buildingId).then((response) => {
      setBuilding(response.data);

      axios
        .all([coreApi.get("/cities/" + response.data.city_branch_id), coreApi.get("/clients/" + idClient)])
        .then(
          axios.spread((city, client) => {
            setClient(client.data);

            if (city.data) {
              coreApi
                .get("/countries/" + city.data.country_branch_id)
                .then((res) => {
                  setCountryBranch(res.data);
                })
                .catch((err) => console.log(err));

              coreApi
                .get("/accounts/all/" + city.data.account_id)
                .then((res) => {
                  setAccount(res.data);
                })
                .catch((err) => console.log(err));
            }
          })
        )
        .catch((err) => console.log(err))
        .finally(() => setDataLoading(false));
    });
  }, [buildingId]);

  const showModal = () => {
    let payload = {
      message:
        moment()?.subtract(1, "months")?.format("MM") +
        "/" +
        moment()?.subtract(1, "months")?.format("YYYY") +
        " " +
        building?.city +
        " " +
        building?.building_units[0]?.street +
        " - vrácení přeplatku",
      account_id_from: account?.id,
      date: moment(dateDue)?.format("YYYY-MM-DD"),
      client_id_to: client?.id,
      country_branch_id_to: countryBranch?.id,
      variable_symbol: invoice?.corrective_document?.invoice_number || invoice?.invoice_number,
      amount: total,
    };

    coreApi
      .post("payments/generate-payment", {
        transactions: [{ ...payload }],
      })
      .then((res) => {
        setXML(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsModalVisible(true);
      });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <React.Fragment>
      {/* check if is overpaid or underpaid, if total < 0, that means invoice is overpaid */}
      {total < 0 ? (
        <Button type="primary" size="small" onClick={showModal}>
          {t("finance.balances.overpaid")}
        </Button>
      ) : (
        <Button type="primary" size="small" disabled="true">
          {t("finance.balances.partiallyPaid")}
        </Button>
      )}

      <Modal
        title={t("buildings.moneyReturn")}
        visible={isModalVisible}
        onOk={handleOk}
        okText={t("finance.moneyReturn.generateXML")}
        onCancel={handleCancel}
        width={600}
      >
        {XML && (
          <>
            <Col span={24}>
              <pre style={{ fontStyle: "italic", marginBottom: "0 !important" }}>
                {XML?.split("<Orders>")[1]?.split("</Orders>")[0]}
              </pre>
            </Col>
          </>
        )}
        {/* <Form
          form={moneyReturnForm}
          layout="vertical"
          onFinish={(values) => {
            let payload = { ...values };

            payload.date = moment(payload.date, "YYYY-MM-DD");

            coreApi
              .post("payments/generate-payment", {
                transactions: [{ ...payload }],
              })
              .then((res) => {
                setXML(res.data);
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setIsModalVisible(true);
              });

            // coreApi
            //   .post(
            //     "payments/generate-payment",
            //     {
            //       transactions: [{ ...payload }],
            //     },
            //     { responseType: "blob" }
            //   )
            //   .then((res) =>
            //     fileDownload(
            //       res.data,
            //       "payment-" +
            //         building?.registration_number +
            //         " - " +
            //         moment()?.format("YYYYMMDD") +
            //         "-" +
            //         moment().hour() +
            //         moment().minute() +
            //         moment().second() +
            //         ".xml"
            //     )
            //   )
            //   .catch((err) => console.log(err));
          }}
        >
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col span={12}>
              <Form.Item
                name="account_id_from"
                label={t("finance.moneyReturn.accountFrom")}
                valuePropName={"value"}
                initialValue={account?.id}
                rules={[
                  {
                    required: true,
                    message: t("finance.moneyReturn.validation.accountFrom"),
                  },
                ]}
              >
                <Select defaultValue={account?.id} loading={account?.name ? false : true}>
                  <Select.Option key={account?.id} value={account?.id}>
                    {account?.name}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="date"
                label={t("finance.moneyReturn.date")}
                initialValue={moment(dateDue, "DD-MM-YYYY")}
                rules={[
                  {
                    required: true,
                    message: t("finance.moneyReturn.validation.date"),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} defaultPickerValue={moment(dateDue, "DD-MM-YYYY")}></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="variable_symbol"
                label={t("finance.moneyReturn.variableSymbol")}
                initialValue={""}
                rules={[
                  {
                    required: true,
                    message: t("finance.moneyReturn.validation.variableSymbol"),
                  },
                ]}
              >
                <Input maxLength={10}></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="amount"
                label={t("finance.moneyReturn.amount")}
                initialValue={0}
                rules={[
                  {
                    required: true,
                    message: t("finance.moneyReturn.validation.amount"),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => {
                    return new Intl.NumberFormat("cs-CZ", {
                      style: "currency",
                      currency: countryBranch?.iso_4217_currency_code || "CZK",
                    }).format(value);
                  }}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                ></InputNumber>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                name="message"
                label={t("finance.moneyReturn.message")}
                initialValue={"Vracení přeplatku za období " + moment()?.format("MM") + "/" + moment()?.format("YYYY")}
              >
                <TextArea></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form> */}
      </Modal>
    </React.Fragment>
  );
};

export default MoneyReturnPane;
