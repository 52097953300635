import { Button, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import AssignTransactionModal from "./AssignTransactionModal";
import { useSelector } from "react-redux";
import { getAreaScope } from "../../actions";
import { Link } from "react-router-dom";

const UnassignedTransactionsPane = () => {
  const { t } = useTranslation();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope?.areaScope;

  const [dataLoading, setDataLoading] = useState(true);

  const [transactions, setTransactions] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({ total: 0 });
  const [currentPage, setCurrentPage] = useState(1);

  const [isModalOpen, setModalOpen] = useState(false);
  const [assigningTransaction, setAssigningTransaction] = useState({});

  const [sorter, setSorter] = useState();
  const [filters, setFilters] = useState([]);

  const decrementTotalTransactions = () => {
    setPaginationDetails({
      ...paginationDetails,
      total: paginationDetails.total - 1,
    });
  };

  const tableColumns = [
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      dataIndex: "id",
      render: (id) => (
        <Link to={"/transactions/" + id} style={{ fontWeight: "bold" }}>
          {id}
        </Link>
      ),
    },
    {
      title: t("finance.transactions.accountNumberFrom"),
      key: "account_number_from",
      render: (transaction) => (
        <Typography.Text copyable key={transaction.id}>
          {transaction.account_number_from + "/" + transaction.bank_code_from}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNameFrom"),
      key: "account_name_from",
      dataIndex: "account_name_from",
    },
    {
      title: t("finance.transactions.variableSymbol"),
      key: "variable_symbol",
      dataIndex: "variable_symbol",
      render: (variableSymbol) => {
        if (variableSymbol) {
          return (
            <Typography.Text copyable key={variableSymbol}>
              {variableSymbol}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: t("finance.transactions.amount"),
      key: "amount_free",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.amount_free);
      },
      filterMultiple: false,
      filters: [
        {
          text: t("finance.transactions.incoming"),
          value: "incoming",
        },
        {
          text: t("finance.transactions.outgoing"),
          value: "outgoing",
        },
      ],
    },
    {
      title: t("finance.transactions.date"),
      key: "date_transaction",
      dataIndex: "date_transaction",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("L");
      },
    },
    {
      title: t("finance.accounts.accountTo"),
      key: "accounts.account_number",
      render: (rowData) => {
        if (rowData?.account)
          return (
            (rowData?.account?.account_prefix ? rowData?.account?.account_prefix + "-" : "") +
            rowData.account.account_number +
            "/" +
            rowData.account.account_bank_code
          );
        else return <Tag color={"red"}>{t("finance.accounts.unknownAccount")}</Tag>;
      },
    },
    {
      title: t("common.actions"),
      key: "link",
      render: (rowData) => {
        return (
          <React.Fragment>
            <Button
              onClick={() => {
                setModalOpen(true);
                setAssigningTransaction({ ...rowData });
              }}
            >
              {t("finance.transactions.assign")}
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    setDataLoading(true);
    coreApi
      .get("/transactions", {
        params: {
          city_branch_id: selectedCity,
          linked: 0,
          page: currentPage,
          order_by_column: sorter?.columnKey || null,
          order_by_desc: sorter?.order === "descend" ? "DESC" : null,
          ...filters,
        },
      })
      .then((res) => {
        let { data, ...pagination } = res.data;
        setTransactions(data);
        setPaginationDetails(pagination);
      })
      .catch((err) => console.log(err))
      .finally(() => setDataLoading(false));
  }, [currentPage, sorter, selectedCity, filters]);

  return (
    <React.Fragment>
      <Table
        size="small"
        loading={dataLoading}
        columns={tableColumns}
        title={() => <h4>{t("finance.transactions.unassignedTransactionsCount") + ": " + paginationDetails?.total}</h4>}
        dataSource={transactions}
        pagination={{
          current: paginationDetails?.current_page,
          pageSize: paginationDetails?.per_page,
          total: paginationDetails?.total,
          page: currentPage,
          showSizeChanger: false,
          onChange: (e) => {
            setCurrentPage(e);
          },
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
        }}
        onChange={(pagination, filters, sorter) => {
          setSorter(sorter);
          setFilters({
            paymentType: filters?.amount_free?.[0] || null,
          });
        }}
        rowKey={"id"}
      ></Table>
      <AssignTransactionModal
        transaction={assigningTransaction}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        transactions={transactions}
        setTransactions={setTransactions}
        decrementTotalTransactions={decrementTotalTransactions}
      ></AssignTransactionModal>
    </React.Fragment>
  );
};

export default UnassignedTransactionsPane;
