import { notification, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import MoneyReturnPane from "../Buildings/MoneyReturnPane";
import IconTooltip from "../IconTooltip";

/**
 * @component
 * @alias BalancePane
 * @prop {object} client -  client with all it's details
 */
const BalancePane = ({ client }) => {
  const { t } = useTranslation();

  const [dataLoading, setDataLoading] = useState(true);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState({});

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setDataLoading(true);
      coreApi
        .get("invoices/partner-client", {
          params: {
            client_id: client?.id,
            page: filters?.page,
          },
        })
        .then((res) => {
          let { data, ...pagination } = res.data;

          setData(data);
          setPagination({ ...pagination });
        })

        .catch((err) => notification.error({ message: err.response.data.message }))
        .finally(() => setDataLoading(false));
    }

    return () => {
      mounted = false;
    };
  }, [client, filters]);

  // defining table columns
  const balanceTableColumns = [
    {
      title: t("finance.balances.period"),
      key: "date_issued",
      dataIndex: "date_issued",
      render: (rowData) => {
        return moment(rowData).format("YYYY") + "/" + moment(rowData).format("MM");
      },
    },
    {
      title: t("finance.invoices.invoiceNumber"),
      key: "invoice_number",
      render: (invoice) => {
        return (
          <Link to={"/invoices/" + invoice?.id} style={{ fontWeight: 500 }}>
            {invoice?.invoice_number}
          </Link>
        );
      },
    },
    {
      title: t("finance.invoices.invoices"),
      key: "invoices",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "total_amount",
          render: (rowData) => {
            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(rowData.total_amount);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "partnerClientInvoices",
          render: (rowData) => {
            let paidTransaction = () => {
              let sum = 0;

              rowData?.transactions?.forEach((transaction) => {
                sum += parseFloat(transaction?.pivot?.amount);
              });

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(paidTransaction());
          },
        },
      ],
    },
    {
      title: t("finance.invoices.correctiveDocuments"),
      key: "correctiveDocuments",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "correctiveDocuments",
          render: (rowData) => {
            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(rowData.corrective_document?.total_amount || 0);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "correctiveDocumentsTransactions",
          render: (rowData) => {
            let paidCorrectiveTransaction = () => {
              let sum = 0;

              if (rowData?.corrective_document) {
                rowData?.corrective_document?.transactions?.forEach((trans) => {
                  sum += parseFloat(trans?.pivot?.amount || 0);
                });
              }

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(paidCorrectiveTransaction());
          },
        },
      ],
    },
    {
      title: t("finance.lineItems.totalAmount"),
      key: "totalInvoices",
      children: [
        {
          title: t("finance.balances.toBePaid"),
          key: "totalToBePaid",
          render: (rowData) => {
            let invoice_total = parseFloat(rowData?.total_amount);
            let corrective_total = rowData?.corrective_document?.total_amount
              ? parseFloat(rowData.corrective_document.total_amount)
              : 0;
            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(invoice_total + corrective_total);
          },
        },
        {
          title: t("finance.balances.paid"),
          key: "totalTransactions",
          render: (rowData) => {
            let paidTransaction = () => {
              let sum = 0;

              rowData?.transactions?.forEach((transaction) => {
                sum += parseFloat(transaction?.pivot?.amount);
              });

              return sum;
            };

            let paidCorrectiveTransaction = () => {
              let sum = 0;

              if (rowData?.corrective_document) {
                rowData?.corrective_document?.transactions?.forEach((trans) => {
                  sum += parseFloat(trans?.pivot?.amount || 0);
                });
              }

              return sum;
            };

            return new Intl.NumberFormat("cs-CZ", {
              style: "currency",
              currency: rowData.currency_iso_4217 || "CZK",
            }).format(paidTransaction() + paidCorrectiveTransaction());
          },
        },
      ],
    },
    {
      title: t("finance.balances.difference"),
      key: "account_name_from",
      render: (rowData) => {
        let invoice_total = parseFloat(rowData?.total_amount);
        let corrective_total = rowData?.corrective_document?.total_amount
          ? parseFloat(rowData.corrective_document.total_amount)
          : 0;

        let paidTransaction = () => {
          let sum = 0;

          rowData?.transactions?.forEach((trans) => {
            sum += parseFloat(trans?.pivot?.amount || 0);
          });

          if (rowData?.corrective_document) {
            rowData?.corrective_document?.transactions?.forEach((trans) => {
              sum += parseFloat(trans?.pivot?.amount || 0);
            });
          }

          return sum;
        };

        let total = invoice_total + corrective_total - paidTransaction();
        return (
          <>
            <span>
              {new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: rowData.currency_iso_4217 || "CZK",
              }).format(total)}
              {total !== 0 ? (
                total > 0 ? (
                  <IconTooltip message={t("finance.balances.unpaid")} />
                ) : (
                  <IconTooltip message={t("finance.balances.overpaid")} />
                )
              ) : (
                <IconTooltip message={t("finance.balances.paid")} />
              )}
            </span>
          </>
        );
      },
    },
    {
      title: t("common.action"),
      key: "invoice_number",
      render: (invoice) => {
        let invoice_total = parseFloat(invoice?.total_amount);
        let corrective_total = invoice?.corrective_document?.total_amount
          ? parseFloat(invoice.corrective_document.total_amount)
          : 0;

        let paidTransaction = () => {
          let sum = 0;

          invoice?.transactions?.forEach((trans) => {
            sum += parseFloat(trans?.pivot?.amount || 0);
          });

          if (invoice?.corrective_document) {
            invoice?.corrective_document?.transactions?.forEach((trans) => {
              sum += parseFloat(trans?.pivot?.amount || 0);
            });
          }

          return sum;
        };

        let total = invoice_total - corrective_total - paidTransaction();

        return (
          <MoneyReturnPane
            idClient={invoice?.client_id}
            buildingId={invoice?.building_id}
            total={total}
            invoice={invoice}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment key={"BalancePane"}>
      <Table
        size="small"
        loading={dataLoading}
        bordered
        columns={balanceTableColumns}
        dataSource={[...data]}
        rowKey={"id"}
        pagination={{
          current: pagination?.current_page,
          pageSize: pagination?.per_page,
          total: pagination?.total,
          page: pagination?.current_page,
          showSizeChanger: false,
          onChange: (e) => {
            setFilters({ ...filters, page: e });
          },
        }}
      ></Table>
    </React.Fragment>
  );
};

export default BalancePane;
