import { useEffect, useState } from "react";

/**
 * Handle tab change.
 *
 * @hook
 * @alias useTabChange
 * @param {array} props
 * @returns state, setSate
 */
function useTabChange(props, initialValue) {
  const [state, setState] = useState(props.location.hash || initialValue);

  useEffect(() => {
    props.history.push(state);
  }, [state]);

  return [state, setState];
}

export default useTabChange;
