import { SendOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import SendEmailModal from "../Emails/SendEmailModal";

const OfferSendModal = ({ id, isOrder }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState("");

  return (
    <React.Fragment>
      <Button
        icon={<SendOutlined />}
        onClick={() => {
          coreApi
            .get("/offers/offer-template/" + id)
            .then((response) => {
              setEmailTemplate(response.data);
              setModalOpen(true);
            })
            .catch()
            .finally();
        }}
      >
        {isOrder ? t("offers.sendOrderEmail") : t("offers.sendOfferEmail")}
      </Button>
      <SendEmailModal
        isOpen={isModalOpen}
        close={() => setModalOpen(false)}
        template={emailTemplate}
        title={t("emails.sendOfferEmail")}
        idAttachmentRecord={id}
      />
    </React.Fragment>
  );
};

export default OfferSendModal;
