import React, { useEffect } from "react";
import ContactsPane from "../ContactsPane";

/**
 * Building Unit Conctact Pane
 * @component
 * @alias ContactPane
 * @property {array} contacts
 * @returns <ContactsPane /> with all contacts that belongs to the buildingUnit
 */
const ContactPane = ({ contacts, contactableId, contactableType }) => {
  let contactable = {
    id: contactableId,
    type: contactableType,
  };

  return (
    <React.Fragment>
      <ContactsPane contactable={contactable} contacts={contacts} allContacts={true}></ContactsPane>
    </React.Fragment>
  );
};

export default ContactPane;
