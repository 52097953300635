import React, { useEffect, useState } from "react";
import { notification, Select, Form } from "antd";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

/**
 * Account Selector - on mount fetches all bank accounts
 * @component
 * @alias AccountSelector
 * @property {number} initialValue - init value for select (account_id)
 * @property {bool} readOnly - sets Form Item read-only
 * @property {bool} required - sets Form Item required - default false
 * @returns <Form.Item /> containing <Select />
 */
const AccountSelector = ({ initialValue, required, readOnly }) => {
  const { t } = useTranslation();

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    coreApi
      .get("/accounts/all")
      .then((response) => {
        if (isMounted) {
          setAccounts(response.data);
        }
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    // react hook useEffect cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item
      name="account_id"
      label={t("finance.accounts.account")}
      rules={[
        {
          required: required,
          message: t("expansion.validation.account.required"),
        },
      ]}
      initialValue={initialValue}
    >
      <Select loading={loading} disabled={readOnly}>
        {accounts.map((account) => {
          return (
            <Select.Option key={account?.id} value={account?.id}>
              {account.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default AccountSelector;
