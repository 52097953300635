import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

// defined gutter for <Row(s) />
const gutter = [16, 24];

/**
 * Building Unit Add Form
 * @component
 * @alias BuildingUnitAddForm
 * @property {function} onFinishAction - functions that trigers on <Form /> onFinishAction, used for adding to React state
 * @property {object} city - contains city details
 * @returns <Button /> which opens the <Modal /> component containing <Form />
 */
const BuildingUnitAddForm = ({ building, setBuilding }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [addBuildingUnitsForm] = Form.useForm();
  const [contacts, setContacts] = useState([]);

  return (
    <React.Fragment>
      <Button
        size="large"
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("buildings.addBuildingUnit")}
      </Button>
      <Modal
        okText={t("common.create")}
        title={t("buildings.addBuildingUnit")}
        visible={isModalOpen}
        onOk={addBuildingUnitsForm.submit}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          form={addBuildingUnitsForm}
          onFinish={(values) => {
            coreApi
              .post("buildings/units", {
                ...values,
                building_id: building?.id,
                city: building?.city,
                district: building?.district,
              })
              .then((response) => {
                setBuilding({
                  ...building,
                  building_units: [response.data.building_unit, ...building.building_units],
                });

                addBuildingUnitsForm.resetFields();
              })
              .catch((err) => console.log(err));

            setModalOpen(false);
          }}
        >
          <Row gutter={[8, 16]}>
            <Col span={16}>
              <Form.Item
                hasFeedback
                name={"street"}
                key={"street"}
                rules={[
                  {
                    required: true,
                    message: t("buildingUnits.validation.street"),
                  },
                ]}
              >
                <Input placeholder={t("buildingUnits.street")}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                hasFeedback
                name={"house_number"}
                key={"house_number"}
                rules={[
                  {
                    required: true,
                    message: t("buildingUnits.validation.houseNumbering"),
                  },
                ]}
              >
                <Input placeholder={t("buildingUnits.houseNumbering")}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item hasFeedback name={"floor_count"} key={"floor_count"}>
                <Input placeholder={t("buildingUnits.floorCount")}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item hasFeedback name={"ground_floor_count"} key={"ground_floor_count"}>
                <Input placeholder={t("buildingUnits.groundFloorCount")} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item hasFeedback name={"basement_floor_count"} key={"basement_floor_count"}>
                <Input placeholder={t("buildingUnits.basementFloorCount")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default BuildingUnitAddForm;
