import { Button, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PartnerDocumentsPane = ({ partner, loading }) => {
  const { t } = useTranslation();

  return (
    <Table
      loading={loading}
      dataSource={partner.documents}
      rowKey={"id"}
      columns={[
        {
          title: t("documents.name"),
          key: "name",
          render: (document) => <Link to={"/documents/" + document.id}>{document.filename}</Link>,
        },
        {
          title: t("documents.path"),
          key: "path",
          dataIndex: "path",
        },
        {
          title: t("common.actions"),
          key: "download",
          render: () => {
            <Button>{t("documents.download")}</Button>;
          },
        },
      ]}
    />
  );
};

export default PartnerDocumentsPane;
