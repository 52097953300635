import { Col, Divider, notification, Row } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import CleaningsCalendar from "../Cleanings/CleaningsCalendar";
import CleaningScheduleCell from "../Cleanings/CleaningScheduleCell";
import SchedulesList from "../Scheduler/SchedulesList";

/**
 * Building Unit Schedule Pane
 * @component
 * @alias BuildingUnitSchedulerPane
 * @property {object} buildingUnit
 * @returns Structed content that contains <CleaningCalendar />
 */
const BuildingUnitSchedulerPane = ({ buildingUnit }) => {
  const { t } = useTranslation();

  const [isOneTimeCleaningFormOpen, setOneTimeCleaningFormOpen] = useState(false);
  const [isRecurringCleaningFormOpen, setRecurringCleaningFormOpen] = useState(false);

  const [recurringCleanings, setRecurringCleanings] = useState([]);
  const [oneTimeCleanings, setOneTimeCleanings] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedCleanings, setSelectedCleanings] = useState([]);

  const handleCalendarChange = useCallback(
    async (date) => {
      let dateFrom = date.clone().startOf("month");
      let dateTo = date.clone().endOf("month");

      setLoading(true);

      axios
        .all([
          coreApi.get("/cleanings/recurring", {
            params: {
              building_unit_id: buildingUnit?.id,
              date_from: dateFrom.format("YYYY-MM-DD"),
              date_to: dateTo.format("YYYY-MM-DD"),
            },
          }),
          coreApi.get("/cleanings/one-time", {
            params: {
              building_unit_id: buildingUnit?.id,
              date_from: dateFrom.format("YYYY-MM-DD"),
              date_to: dateTo.format("YYYY-MM-DD"),
            },
          }),
          coreApi.get("/buildings/units/rules/" + buildingUnit?.id),
        ])
        .then(
          axios.spread((recurringCleaningsResponse, oneTimeCleaningsResponse, rulesResponse) => {
            setRecurringCleanings(() => [...recurringCleaningsResponse.data]);
            setOneTimeCleanings(() => [...oneTimeCleaningsResponse.data]);
            setSchedules(() => [...rulesResponse.data]);
          })
        )
        .catch((error) => {
          notification.error({ message: error?.response?.data?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [buildingUnit]
  );

  const getDayFilteredCleanings = (value) => {
    let cleaningsMerged = [...recurringCleanings, ...oneTimeCleanings];

    let cleanings = cleaningsMerged.filter((o) => o?.date_expected === value?.format("YYYY-MM-DD"));

    return cleanings;
  };

  useEffect(() => {
    setSelectedCleanings(() => getDayFilteredCleanings(selectedDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneTimeCleanings, recurringCleanings]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setSelectedCleanings(() => getDayFilteredCleanings(selectedDate));
    handleCalendarChange(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, buildingUnit]);

  const dateCellRender = (value) => {
    return <CleaningScheduleCell cleanings={getDayFilteredCleanings(value)} />;
  };

  return (
    <React.Fragment>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Divider orientation={"left"}>{t("schedules.currentScheduleRules")}</Divider>
          <SchedulesList
            buildingUnit={buildingUnit}
            schedules={schedules}
            loading={loading}
            recurringCleanings={recurringCleanings}
            refreshScheduleList={() => {
              handleCalendarChange(selectedDate);
            }}
          />
        </Col>
        <Col span={24}>
          <CleaningsCalendar
            loading={loading}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedCleanings={selectedCleanings}
            setSelectedCleanings={setSelectedCleanings}
            isOneTimeCleaningFormOpen={isOneTimeCleaningFormOpen}
            setOneTimeCleaningFormOpen={setOneTimeCleaningFormOpen}
            isRecurringCleaningFormOpen={isRecurringCleaningFormOpen}
            setRecurringCleaningFormOpen={setRecurringCleaningFormOpen}
            dateCellRender={dateCellRender}
            handleCalendarChange={handleCalendarChange}
            buildingUnit={buildingUnit}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingUnitSchedulerPane;
