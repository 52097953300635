import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import PartnerSelect from "../components/Partners/PartnerSelect";

const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "null" ||
      obj[propName] === "undefined" ||
      obj[propName] === "" ||
      obj[propName] === false
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

/**
 * Page with all buildings
 * @component
 * @alias BuildingsIndex
 * @returns <Card /> with content
 */
const BuildingsIndex = (props) => {
  const { t } = useTranslation();

  const [filterForm] = Form.useForm();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = Number(areaScope.areaScope);
  const [params, setParams] = useState(new URLSearchParams(props.location.search));

  // States
  const [cities, setCities] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isDataLoading, setDataLoading] = useState(false);
  const [autocompletePostalCodes, setAutocompletePostalCodes] = useState([]);
  const [autocompleteDistricts, setAutocompleteDistricts] = useState([]);
  const [autocompleteRegNums, setAutocompleteRegNums] = useState([]);

  let defaultFilters = {
    city_branch_id:
      selectedCity !== 0
        ? Number(selectedCity)
        : params.get("city_branch_id")
        ? Number(params.get("city_branch_id"))
        : null,
    street: String(params.get("street")) || "",

    // Autocomplete values
    district: params.get("district") ? String(params.get("district")) : "",
    postal_code: params.get("postal_code") ? String(params.get("postal_code")) : "",
    registration_number: params.get("registration_number") ? String(params.get("registration_number")) : "",

    contract_not_signed: params.get("contract_not_signed") === "true" ? true : false,
    contract_terminated: params.get("contract_terminated") === "true" ? true : false,
    is_waiting: params.get("is_waiting") === "true" ? true : false,
    is_active: params.get("is_active") === "true" ? true : false,
    employee_id: Number(params.get("employee_id")) || null,
    page: Number(params.get("page")) || null,
    partner_id: Number(params.get("partner_id")) || null,
  };

  // Values for filtering
  const [filters, setFilters] = useState(defaultFilters);

  // Colums of table
  const buildingTableColumns = [
    {
      title: t("buildings.registrationNumber"),
      dataIndex: "registration_number",
      key: "building_id",
      width: "15%",
      sorter: (a, b) => a.registration_number - b.registration_number,
      render: (text, row, index) => {
        return (
          <React.Fragment key={index}>
            <Tooltip placement="topLeft" title={row.registration_number}>
              <Link to={`/buildings/${row.id}`} style={{ fontWeight: 500 }}>
                {row?.registration_number ? (
                  row?.ending_date ? (
                    <del>{row?.registration_number}</del>
                  ) : (
                    row?.registration_number
                  )
                ) : (
                  <Tag color={"red"}>{t("buildings.withoutRegNum")}</Tag>
                )}
              </Link>
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      title: t("buildings.reference"),
      dataIndex: "reference",
      key: "reference",
      width: "25%",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
      render: (text, row, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {row?.ending_date ? (
              <del>
                <Link to={`/buildings/${row.id}`} style={{ fontWeight: 500 }}>
                  {text}
                </Link>
              </del>
            ) : (
              <Link to={`/buildings/${row.id}`} style={{ fontWeight: 500 }}>
                {text}
              </Link>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: t("buildings.clientName"),
      dataIndex: "client.name",
      width: "30%",
      sorter: (a, b) => a.client.name.localeCompare(b.client.name),
      render: (text, row, index) => {
        return (
          <Tooltip placement="topLeft" title={row?.client?.name}>
            <Link to={"/clients/" + row.client_id} style={{ borderBottom: "1px dotted" }}>
              {row?.client?.name}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      width: "10%",
      title: t("buildings.city"),
      dataIndex: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      width: "15%",
      title: t("buildings.district"),
      dataIndex: "district",
      sorter: (a, b) => a.district.localeCompare(b.district),
    },
    {
      width: "10%",
      title: t("buildings.postalCode"),
      dataIndex: "postal_code",
      sorter: (a, b) => a.postal_code.localeCompare(b.postal_code),
    },
  ];

  useEffect(() => {
    let isMounted = true;

    axios
      .all([coreApi.get("cities"), coreApi.get("employees/all")])
      .then(
        axios.spread((cities, employees) => {
          if (isMounted) {
            setCities([...cities.data]);
            setEmployees([...employees.data]);
          }
        })
      )
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setLoading(false));

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!filters.city_branch_id) return;
    if (filters.city_branch_id === 0) return;

    filterForm.setFieldsValue({ ...cleanObject(filters) });
    setDataLoading(true);

    coreApi
      .get("/buildings", {
        params: {
          ...cleanObject(filters),
        },
      })
      .then((res) => {
        let { data, ...pagination } = res.data;
        setBuildings([...data]);

        props.history.push({
          pathname: "/buildings",
          search: "?" + new URLSearchParams({ ...cleanObject(filters) }).toString(),
        });

        setPaginationDetails({ ...pagination });
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setDataLoading(false));
  }, [filters]);

  useEffect(() => {
    if (selectedCity === 0) return;

    filterForm.resetFields();
    setFilters({ city_branch_id: selectedCity });

    axios
      .all([
        coreApi.get("/buildings/postal-codes", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
        coreApi.get("/buildings/districts", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
        coreApi.get("/buildings/registration-numbers", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
      ])
      .then(
        axios.spread((postalCodes, districts, regNums) => {
          setAutocompletePostalCodes(
            postalCodes.data.map((item) => ({
              value: item.postal_code,
            }))
          );

          setAutocompleteDistricts(
            districts.data.map((item) => ({
              value: item.district,
            }))
          );

          setAutocompleteRegNums(
            regNums.data.map((item) => ({
              value: item.registration_number,
            }))
          );
        })
      )
      .catch((err) =>
        notification.error({
          message: err.response.data.message,
        })
      );
  }, [selectedCity]);

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24} gutter={[16, 16]}>
          <Card>
            <Col span={24} gutter={[16, 16]}>
              <Form
                className="filterForm"
                form={filterForm}
                onFinish={(values) => {
                  setFilters({
                    ...values,
                    date_signed: values.date_signed?.format("YYYY-MM-DD"),
                    date_active_to: values.date_active_to?.format("YYYY-MM-DD"),
                    page: 1,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") filterForm.submit();
                }}
              >
                <Row gutter={[8, 0]}>
                  <Col span={4}>
                    <Form.Item
                      name="city_branch_id"
                      rules={[
                        {
                          required: true,
                          message: t("expansion.selectCity"),
                        },
                      ]}
                      initialValue={filters.city_branch_id}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        loading={isLoading}
                        disabled={isLoading}
                        allowClear={true}
                        placeholder={t("common.city")}
                        onChange={(val) => {
                          if (!val) return;

                          setAutocompleteRegNums([]);
                          setAutocompleteDistricts([]);
                          setAutocompletePostalCodes([]);

                          const params = {
                            city_branch_id: val,
                          };

                          axios
                            .all([
                              coreApi.get("/buildings/postal-codes", {
                                params: { ...params },
                              }),
                              coreApi.get("/buildings/districts", {
                                params: { ...params },
                              }),
                              coreApi.get("/buildings/registration-numbers", {
                                params: { ...params },
                              }),
                            ])
                            .then(
                              axios.spread((postalCodes, districts, regNums) => {
                                setAutocompletePostalCodes(
                                  postalCodes.data.map((item) => ({
                                    value: item.postal_code,
                                  }))
                                );

                                setAutocompleteDistricts(
                                  districts.data.map((item) => ({
                                    value: item.district,
                                  }))
                                );

                                setAutocompleteRegNums(
                                  regNums.data.map((item) => ({
                                    value: item.registration_number,
                                  }))
                                );
                              })
                            )
                            .catch((err) =>
                              notification.error({
                                message: err.response.data.message,
                              })
                            );
                        }}
                      >
                        {cities.map((city) => {
                          return (
                            <Select.Option key={city.id} value={city.id}>
                              {city.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item name="street">
                      <Input placeholder={t("buildingUnits.street")}></Input>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item name="district" initialValue={filters?.district}>
                      <AutoComplete
                        allowClear={true}
                        autoClearSearchValue={true}
                        dropdownMatchSelectWidth={false}
                        notFoundContent={
                          !autocompleteDistricts.length > 0 && (
                            <Spin
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            />
                          )
                        }
                        onSearch={(value) => autocompleteDistricts?.filter((item) => item.value.includes(value))}
                        placeholder={t("common.district")}
                        options={autocompleteDistricts}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      ></AutoComplete>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item name="postal_code" initialValue={filters?.postal_code}>
                      <AutoComplete
                        allowClear={true}
                        backfill={true}
                        notFoundContent={
                          !autocompletePostalCodes.length > 0 && (
                            <Spin
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            />
                          )
                        }
                        placeholder={t("common.billingAddressPostalCode")}
                        options={autocompletePostalCodes}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      ></AutoComplete>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item name="registration_number" initialValue={filters?.registration_number}>
                      <AutoComplete
                        allowClear={true}
                        backfill={true}
                        notFoundContent={
                          !autocompleteRegNums.length > 0 && (
                            <Spin
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            />
                          )
                        }
                        placeholder={t("buildings.registrationNumber")}
                        options={autocompleteRegNums}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      ></AutoComplete>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <PartnerSelect
                      name={"partner_id"}
                      validationMessage={t("cleanings.validation.partners")}
                      initialValue={filters?.partner_id || null}
                      required={false}
                      showSearch={true}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          partner_id: e?.id,
                        });
                      }}
                    />
                  </Col>
                </Row>

                <Row gutter={[8, 0]}>
                  <Col span={3}>
                    <Form.Item
                      name="contract_not_signed"
                      label={t("buildings.withoutRegNum")}
                      valuePropName="checked"
                      initialValue={filters?.contract_not_signed || false}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name="contract_terminated"
                      label={t("buildings.contractEnded")}
                      valuePropName="checked"
                      initialValue={filters?.contract_terminated || false}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={"is_waiting"}
                      label={t("buildings.isWaiting")}
                      valuePropName={"checked"}
                      initialValue={filters?.is_waiting || false}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name={"is_active"}
                      label={t("buildings.isActive")}
                      valuePropName={"checked"}
                      initialValue={filters?.is_active || false}
                    >
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="employee_id" initialValue={filters?.employee_id}>
                      <Select placeholder={t("employees.employee")} allowClear={true} autoClearSearchValue={true}>
                        {employees.map((employee) => {
                          return (
                            <Select.Option value={employee.id} key={employee.id}>
                              {employee.first_name + " " + employee.last_name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="date_signed">
                      <DatePicker style={{ width: "100%" }} placeholder={t("buildings.dateSigned")}></DatePicker>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="date_active_to">
                      <DatePicker style={{ width: "100%" }} placeholder={t("buildings.dateActiveTo")}></DatePicker>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[8, 0]} style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ display: "flex", gap: "12px" }}>
                    {/* Filters reset button */}
                    <Form.Item>
                      <Link
                        onClick={() => {
                          setParams(new URLSearchParams(null));
                          filterForm.resetFields();
                          setFilters(defaultFilters);
                        }}
                      >
                        {t("common.resetFilters")}
                      </Link>
                    </Form.Item>

                    <Button
                      type="primary"
                      style={{ width: "150px" }}
                      onClick={(e) => {
                        filterForm.submit();
                      }}
                    >
                      {t("common.search")}
                    </Button>
                  </div>
                </Row>
              </Form>
            </Col>
            <Divider orientation="left">{t("common.data")}</Divider>
            <Row>
              <Col span={24}>
                <Table
                  pagination={{
                    current: paginationDetails?.current_page,
                    pageSize: paginationDetails?.per_page,
                    total: paginationDetails?.total,
                    showTotal: (total, range) => (
                      <p>
                        {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                        {" " + t("common.paginationPartTwo") + " "}
                        <strong>{total}</strong> {t("common.paginationPartThree")}
                      </p>
                    ),
                  }}
                  onChange={(e) => {
                    setFilters({ ...filters, ...e, page: e.current });
                    console.log(e);
                  }}
                  locale={{
                    emptyText: (
                      <span>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={filters.city_branch_id ? t("common.noDataFound") : t("common.noData")}
                        />
                      </span>
                    ),
                  }}
                  size="small"
                  loading={isDataLoading}
                  dataSource={buildings}
                  columns={buildingTableColumns}
                  rowKey={"id"}
                  expandable={{
                    expandedRowRender: (buildings) => {
                      return (
                        <p style={{ margin: 0 }}>
                          {buildings.building_units.map((buildingUnit) => {
                            return (
                              <React.Fragment key={buildingUnit?.id}>
                                <Link to={"/buildings/" + buildingUnit.building_id + "/unit/" + buildingUnit?.id}>
                                  {buildingUnit.street + " " + buildingUnit.house_number}
                                </Link>
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </p>
                      );
                    },
                    expandIcon: ({ expanded, onExpand, record, expandable }) =>
                      expandable &&
                      (expanded ? (
                        <Tooltip placement="top" title={t("buildings.hideBuildingUnits")}>
                          <DownOutlined onClick={(e) => onExpand(record, e)} style={{ transition: "linear 1s 0.2s" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="top" title={t("buildings.showBuildingUnits")}>
                          <RightOutlined
                            onClick={(e) => onExpand(record, e)}
                            style={{ transition: "linear 1s 0.2s" }}
                          />
                        </Tooltip>
                      )),
                    rowExpandable: (buildings) => buildings?.building_units?.length > 0,
                  }}
                ></Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingsIndex;
