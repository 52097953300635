import { Breadcrumb } from "antd";
import React from "react";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { NavLink } from "react-router-dom";
import {
  AccountName,
  BuildingName,
  BuildingUnitName,
  CityName,
  ClientName,
  ContactName,
  CountryName,
  CustomCityLink,
  CustomOrderLink,
  CustomUnitLink,
  EmployeeName,
  InvoiceName,
  OfferName,
  OrderName,
  PartnerName,
} from "../../helpers";

// Custom URL or titles for breadcrumb
const routes = [
  {
    path: "/",
    breadcrumb: "Domů",
  },
  {
    path: "/buildings",
    breadcrumb: "Budovy",
  },
  {
    path: "/offers",
    breadcrumb: "Nabídky",
  },
  {
    path: "/offers/:id",
    breadcrumb: OfferName,
  },
  {
    path: "/orders",
    breadcrumb: CustomOrderLink,
  },
  {
    path: "/orders/:id",
    breadcrumb: OrderName,
  },
  {
    path: "/buildings/:building_id",
    breadcrumb: BuildingName,
  },
  {
    path: "/buildings/:building_id/unit",
    breadcrumb: CustomUnitLink,
  },
  {
    path: "/buildings/:building_id/unit/:id",
    breadcrumb: BuildingUnitName,
  },
  {
    path: "/building-units",
    breadcrumb: "Vchody",
  },
  {
    path: "/partners",
    breadcrumb: "Partneři",
  },
  {
    path: "/partners/:id",
    breadcrumb: PartnerName,
  },
  {
    path: "/clients",
    breadcrumb: "Klienti",
  },
  {
    path: "/clients/:id",
    breadcrumb: ClientName,
  },
  {
    path: "/contacts",
    breadcrumb: "Kontakty",
  },
  {
    path: "/contacts/:id",
    breadcrumb: ContactName,
  },
  {
    path: "/cleanings",
    breadcrumb: "Úklidy",
  },
  {
    path: "/claims",
    breadcrumb: "Reklamace",
  },
  {
    path: "/invoices",
    breadcrumb: "Faktury",
  },
  {
    path: "/invoices/:id",
    breadcrumb: InvoiceName,
  },
  {
    path: "/accounts",
    breadcrumb: "Bankovní účty",
  },
  {
    path: "/accounts/:id",
    breadcrumb: AccountName,
  },
  {
    path: "/unlinked-transactions",
    breadcrumb: "Nenavázané transakce",
  },
  {
    path: "/countries",
    breadcrumb: "Státy",
  },
  {
    path: "/countries/:id",
    breadcrumb: CountryName,
  },
  {
    path: "/countries/:country_id/cities/",
    breadcrumb: CustomCityLink,
  },
  {
    path: "/countries/:country_id/cities/:id",
    breadcrumb: CityName,
  },
  {
    path: "/city-reports",
    breadcrumb: "Reporty měst",
  },
  {
    path: "/country-reports",
    breadcrumb: "Reporty států",
  },
  {
    path: "/employees",
    breadcrumb: "Pracovníci",
  },
  {
    path: "/employees/:id",
    breadcrumb: EmployeeName,
  },
  {
    path: "/emails",
    breadcrumb: "Komunikace",
  },
];

/**
 * Breadcrumbs displayed under the header bar.
 *
 * @component
 * @alias CustomBreadcrumb
 * @param {array} breadcrumbs
 * @returns <Breadcrumb />
 */
const CustomBreadcrumb = ({ breadcrumbs }) => {
  return (
    <Breadcrumb
      separator="\"
      style={{
        padding: "8px",
        paddingLeft: "37px",
        background: "#fff",
        boxShadow: "0 0 30px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Create link for every item of breadcrumb */}
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Breadcrumb.Item key={match.url}>
          <NavLink to={match.url}>{breadcrumb}</NavLink>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default withBreadcrumbs(routes)(CustomBreadcrumb);
