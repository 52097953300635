import { Checkbox, Form, Space, Table, Tag, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ClientsList = ({ clientsList, isLoading, pagination, filters, setFilters, handleBalanceSynchronization }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("clients.name"),
      key: "name",
      width: "50%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (item) =>
        item.representative_client_id ? (
          <Space>
            <Tooltip placement="topLeft" title={item.name}>
              <Link to={"/clients/" + item.id} style={{ fontWeight: 500 }}>
                {item.name}
              </Link>
            </Tooltip>

            <Tooltip placement="topLeft" title={item?.representative?.name}>
              <Link to={"/clients/" + item?.representative_client_id}>
                <Tag color={"blue"}>{t("clients.representedBy")}</Tag>
              </Link>
            </Tooltip>
          </Space>
        ) : (
          <Tooltip placement="topLeft" title={item.name}>
            <Link to={"/clients/" + item.id} style={{ fontWeight: 500 }}>
              {item.name}
            </Link>
          </Tooltip>
        ),
    },
    {
      title: t("clients.identificationNumber"),
      key: "company_number",
      dataIndex: "company_number",
      sorter: (a, b) => a.company_number.localeCompare(b.company_number),
    },
    {
      title: t("common.billingAddressCity"),
      key: "billing_address_city",
      dataIndex: "billing_address_city",
      sorter: (a, b) => a.billing_address_city.localeCompare(b.billing_address_city),
    },
    {
      title: t("finance.balances.balance"),
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (rowData) => {
        let color = "#52c41a";

        if (rowData?.balance < 0) color = "#faad14";
        if (rowData?.balance > 0) color = "#f5222d";

        return (
          <Tooltip title={t("finance.invoices.synchronizeClientBalance")} placement="topLeft">
            <div onClick={() => handleBalanceSynchronization(rowData?.id)} style={{ cursor: "pointer" }}>
              <strong style={{ color: color }}>
                {new Intl.NumberFormat("cs-CZ", {
                  style: "currency",
                  currency: rowData.currency_iso_4217 || "CZK",
                }).format(rowData?.balance || 0)}
              </strong>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Table
      size={"small"}
      loading={isLoading}
      dataSource={clientsList}
      columns={columns}
      rowKey={"id"}
      pagination={{
        ...pagination,
        onChange: (page, pageSize) => {
          setFilters({ ...filters, page_size: pageSize, page: page });
        },
        showTotal: (total, range) => (
          <p>
            {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
            {" " + t("common.paginationPartTwo") + " "}
            <strong>{total}</strong> {t("common.paginationPartThree")}
          </p>
        ),
      }}
      // rowSelection={{
      //   selectedRowKeys,
      //   onChange: (e) => setSelectedRowKeys(e),
      //   type: "checkbox",
      // }}
      title={() => (
        <Form layout={"inline"} className="filterForm">
          {/* <Form.Item label={t("clients.activeClients")} name={"active"} valuePropName={"checked"}>
            <Checkbox
              onChange={(e) => {
                setFilters({ ...filters, active: e.target.checked });
              }}
            ></Checkbox>
          </Form.Item> */}

          <Form.Item label={t("clients.negativeBalance")} name={"negativeBalance"} valuePropName={"checked"}>
            <Checkbox
              onChange={(e) => {
                setFilters({ ...filters, negativeBalance: e.target.checked });
              }}
            ></Checkbox>
          </Form.Item>

          <Form.Item label={t("clients.positiveBalance")} name={"positiveBalance"} valuePropName={"checked"}>
            <Checkbox
              onChange={(e) => {
                setFilters({ ...filters, positiveBalance: e.target.checked });
              }}
            ></Checkbox>
          </Form.Item>

          {/* <Form.Item
            label={t("partners.period")}
            name={"period"}
            initialValue={[
              moment().subtract(1, "months").startOf("month"),
              moment().subtract(1, "months").endOf("month"),
            ]}
          >
            <DatePicker.RangePicker
              allowClear={false}
              allowEmpty={false}
              onChange={(value) => {
                setFilters({
                  ...filters,
                  date_from: value[0].format("YYYY-MM-DD"),
                  date_to: value[1].format("YYYY-MM-DD"),
                });
              }}
            ></DatePicker.RangePicker>
          </Form.Item> */}
          {/* <Button
            disabled={selectedRowKeys.length > 0 ? false : true}
            onClick={() => {
              console.log(selectedRowKeys);
              coreApi
                .post("/payments/clients", {
                  account_id_from: 1,
                  // date_from: filters.date_from,
                  // date_to: filters.date_to,
                  clients: selectedRowKeys.map((id) => ({
                    client_id: id,
                  })),
                })
                .then((res) => {
                  notification.success({
                    message: res.data.message,
                  });
                  fileDownload(
                    res.data.xml,
                    "clients-payments-" +
                      moment()?.format("YYYYMMDD") +
                      "-" +
                      moment().hour() +
                      moment().minute() +
                      moment().second() +
                      ".xml"
                  );
                })
                .catch((err) =>
                  notification.error({
                    message: err.response.data.message,
                  })
                );
            }}
          >
            {t("finance.moneyReturn.generateXML")}
          </Button> */}
        </Form>
      )}
    ></Table>
  );
};

export default ClientsList;
