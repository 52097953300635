import { Button, Col, DatePicker, Form, InputNumber, notification, Row, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import PartnerSelect from "../Partners/PartnerSelect";
import moment from "moment";

const AddSchedule = ({ buildingUnitId, schedules, refreshScheduleList }) => {
  const { t } = useTranslation();

  //  const [isLoading, setLoading] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const [partners, setPartners] = useState([]);
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const [addForm] = Form.useForm();

  useEffect(() => {
    //setLoading(true);
    coreApi
      .get("/partners")
      .then((res) => setPartners([...res.data]))
      .catch((err) => console.log(err));
    //.finally(() => setLoading(false));
  }, []);

  return (
    <React.Fragment key="add_schedule">
      <Button
        type={"primary"}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("schedules.addScheduleRules")}
      </Button>
      <Modal
        visible={isOpen}
        title={t("schedules.addScheduleRules")}
        onCancel={() => {
          addForm.resetFields();
          setOpen(false);
        }}
        onOk={() => addForm.submit()}
      >
        <Form
          form={addForm}
          onFinish={(values) => {
            let payload = {
              ...values,
              date_from: values.date_from.format("YYYY-MM-DD"),
              date_to: values.date_to ? values.date_to.format("YYYY-MM-DD") : null,
            };

            let newRuleState = {
              date_from: values.date_from.format("YYYY-MM-DD"),
              date_to: values.date_to ? values.date_to.format("YYYY-MM-DD") : null,
              offer_id: values.order_id,
              partner_id: values.partner_id,
              service_ratio: values.service_ratio,
              substitute_id: null,
              partner: partners.find((partner) => partner.id === values.partner_id),
            };

            coreApi
              .post("/schedules", payload)
              .then((res) => {
                newRuleState = {
                  ...newRuleState,
                  rules: [...res.data],
                };
                refreshScheduleList([...schedules, newRuleState]);
                addForm.resetFields();
                setOpen(false);
              })
              .catch((err) =>
                notification.error({
                  message:
                    err.response.data?.errors?.date_from ??
                    err.response.data?.errors?.date_to ??
                    err.response.data.message,
                })
              );
          }}
        >
          <Col>
            <Form.Item hidden name={"building_unit_id"} initialValue={buildingUnitId}></Form.Item>
          </Col>
          <Row gutter={[8, 0]}>
            <Col span={24}>
              <Form.Item
                label={t("schedules.cleaningDay")}
                name={"cleaning_days"}
                rules={[
                  {
                    required: true,
                    message: t("schedules.validation.cleaningDay"),
                  },
                ]}
              >
                <Select mode="tags">
                  {days.map((day) => {
                    return (
                      <Select.Option key={day} value={day}>
                        {t("rrules.byweekday." + day)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("schedules.dateFrom")}
                name={"date_from"}
                rules={[
                  {
                    required: true,
                    message: t("schedules.validation.dateFrom"),
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().startOf("month").format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("schedules.dateTo")} name={"date_to"}>
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().endOf("month").format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <PartnerSelect required label={t("schedules.partner")} name={"partner_id"} />
            </Col>
            <Col>
              <Form.Item
                label={t("schedules.serviceRatio")}
                name={"service_ratio"}
                initialValue={0.4}
                rules={[
                  {
                    required: true,
                    message: t("schedules.validation.serviceRatio"),
                  },
                ]}
              >
                <InputNumber step={0.01}></InputNumber>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label={t("schedules.substitute")}
                name={"substitute_id"}
              >
                <Select></Select>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AddSchedule;
