import { DatePicker, Divider, Form } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceContext } from "../../pages/InvoiceProfile";

const InvoiceDetailsPane = ({ readOnly }) => {
  const { t } = useTranslation();

  const { invoice } = useContext(InvoiceContext);

  return (
    <React.Fragment>
      <Divider orientation="left">{t("finance.invoices.details")}</Divider>
      <Form.Item
        name="date_issued"
        label={t("finance.invoices.dateIssued")}
        initialValue={moment(invoice?.date_issued)}
      >
        <DatePicker
          disabled={readOnly}
          format={"LL"}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="date_due"
        label={t("finance.invoices.dateDue")}
        initialValue={moment(invoice?.date_due)}
      >
        <DatePicker
          disabled={readOnly}
          format={"LL"}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="date_sent_to_recipient"
        label={t("finance.invoices.dateSentToRecipient")}
        initialValue={
          invoice?.date_sent_to_recipient
            ? moment(invoice?.date_sent_to_recipient)
            : null
        }
      >
        {invoice?.date_sent_to_recipient || !readOnly ? (
          <DatePicker
            disabled={readOnly}
            format={"LL"}
            style={{ width: "100%" }}
          />
        ) : (
          t("finance.invoices.notSent")
        )}
      </Form.Item>
      <Form.Item
        name="date_sent_to_publisher"
        label={t("finance.invoices.dateSentToPublisher")}
        initialValue={
          invoice?.date_sent_to_publisher
            ? moment(invoice?.date_sent_to_publisher)
            : null
        }
      >
        {invoice?.date_sent_to_publisher || !readOnly ? (
          <DatePicker
            disabled={readOnly}
            format={"LL"}
            style={{ width: "100%" }}
          />
        ) : (
          t("finance.invoices.notSent")
        )}
      </Form.Item>
    </React.Fragment>
  );
};

export default InvoiceDetailsPane;
