import { Card, Col, Form, notification, Row, Skeleton, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import BuildingAddForm from "../components/Buildings/BuildingAddForm";
// import ContactAddForm from "../components/ContactAddForm";
import BuildingListWithOffers from "../components/Buildings/BuildingListWithOffers";
import BalancePane from "../components/Clients/BalancePane";
import InvoicePane from "../components/Clients/InvoicePane";
import RepresentativeGeneralPane from "../components/Clients/RepresentativeGeneralPane";
import RepresentedGeneralPane from "../components/Clients/RepresentedGeneralPane";
import TransactionPane from "../components/Clients/TransactionPane";
import ProfileFormActionSpace from "../components/ProfileFormActionSpace";
import RecordActionsBar from "../components/RecordActionsBar";

const gutter = [16, 24];

const ClientProfile = (props) => {
  const { t } = useTranslation();

  const [client, setClient] = useState({});

  const [buildings, setBuildings] = useState([{ building_units: [{}] }]);

  const [deleteRedirect, setDeleteRedirect] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [isFormSending, setFormSending] = useState(false);
  const [isRepresentative, setRepresentative] = useState(false);

  const [detailForm] = Form.useForm();

  useEffect(() => {
    detailForm.resetFields();

    coreApi
      .get("/clients/" + props.match.params.id)
      .then((res) => {
        let { buildings, ...client } = res.data;

        let representative = client?.representative_client_id === null ? true : false;

        setRepresentative(representative);
        setClient({ ...client });
        setBuildings([...buildings]);
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoading(false));
  }, [props.match.params.id]);

  const addBuildingWithUnitsAndContacts = (values) => {
    coreApi
      .post("/buildings/with-units-and-contacts", {
        building: { ...values },
      })
      .then((res) => {
        notification.success({ message: res.data.message });
        setBuildings([{ ...res.data.building }, ...buildings]);
      })
      .catch((err) =>
        notification.error({
          message: err.response.data.message,
          description: err.response.data.description,
        })
      );
  };

  /** handles TabsPane change and adds the key to url */
  const handleTabsPaneChange = (key) => {
    props.history.push(key);
  };

  return (
    <React.Fragment>
      <Skeleton loading={isPageLoading}>
        <Row gutter={gutter}>
          <Col span="24">
            <Card loading={isPageLoading}>
              <Row gutter={[0, 24]} style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography.Title>{client.name}</Typography.Title>
                  {!isRepresentative && (
                    <Typography.Text type="secondary">
                      <span>
                        {t("clients.representedBy")}
                        {": "}
                        <Link to={"/clients/" + client?.represented_by?.id}>{client?.represented_by?.name}</Link>
                      </span>
                    </Typography.Text>
                  )}
                </div>

                <RecordActionsBar
                  redirectState={deleteRedirect}
                  setRedirect={() => {
                    setDeleteRedirect(true);
                  }}
                  firstModalTitle={t("clients.delete.alertTitle")}
                  firstModalContent={t("clients.delete.alertContent")}
                  entityRoute="/clients"
                  recordId={client?.id}
                />
              </Row>

              <Tabs defaultActiveKey={props.location.hash || "#overview"} onChange={handleTabsPaneChange}>
                <Tabs.TabPane tab={t("clients.overview")} key="#overview">
                  <Row gutter={[16, 24]}>
                    <Col span={24}>
                      <ProfileFormActionSpace
                        style={{ float: "left !important" }}
                        formInstance={detailForm}
                        isFormReadOnly={isFormReadOnly}
                        isFormSending={isFormSending}
                        setFormEditable={() => setFormReadOnly(false)}
                        setFormDisabled={() => setFormReadOnly(true)}
                      />
                    </Col>
                  </Row>
                  <Form
                    form={detailForm}
                    onFinish={(values) => {
                      setFormSending(true);
                      coreApi
                        .put("/clients/" + client?.id, { ...values })
                        .then((res) => {
                          setFormReadOnly(true);
                          notification.success({ message: res.data.message });
                        })
                        .catch((err) => {
                          notification.error({
                            message: err.response.data.message,
                          });
                        })
                        .finally(() => {
                          setFormSending(false);
                        });
                    }}
                  >
                    {isRepresentative ? (
                      <RepresentativeGeneralPane buildings={buildings} client={client} readOnly={isFormReadOnly} />
                    ) : (
                      <RepresentedGeneralPane client={client} readOnly={isFormReadOnly} />
                    )}
                  </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("menu.buildings")} key="#buildings">
                  <Col span={24}>
                    <BuildingListWithOffers clientLead={client} buildings={buildings}></BuildingListWithOffers>
                    <BuildingAddForm client={client} onFinishAction={addBuildingWithUnitsAndContacts} />
                  </Col>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("finance.invoices.invoices")} key="#invoices">
                  <InvoicePane client={client}></InvoicePane>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("finance.balances.balance")} key="#balance">
                  <BalancePane client={client}></BalancePane>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("finance.transactions.transaction")} key="#transactions">
                  <TransactionPane
                    transactions={client.transactions}
                    // accountPrefix={client?.account_prefix}
                    // accountNumber={client?.account_number}
                    // accountBankCode={client?.account_bank_code}
                    // accountIban={client?.account_iban}
                    // accountSwift={client?.account_swift}
                  ></TransactionPane>
                </Tabs.TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Skeleton>
    </React.Fragment>
  );
};

export default ClientProfile;
