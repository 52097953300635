import { Card, Col, notification, Row, Statistic } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import ClientAddForm from "../components/Clients/ClientAddForm";
import ClientsList from "../components/Clients/ClientsList";

const gutter = [16, 24];

const ClientsIndex = () => {
  const { t } = useTranslation();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  const [isLoading, setLoading] = useState(false);

  const [clientsList, setClientsList] = useState([]);
  const [pagination, setPagination] = useState({});

  const [stats, setStats] = useState({});

  const [filters, setFilters] = useState({
    date_from: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
    date_to: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
  });

  const [duplicity, setDuplicity] = useState(false);

  const [duplicateClient, setDuplicateClient] = useState({});

  useEffect(() => {
    setLoading(true);

    axios
      .all([
        coreApi.get("/clients", {
          params: {
            ...filters,
            city_branch_id: selectedCity,
          },
        }),
        coreApi.get("/clients/basic-stats", {
          params: { city_branch_id: selectedCity },
        }),
      ])
      .then(
        axios.spread((clients, basicStats) => {
          let { data, ...pagination } = clients.data;
          setPagination(pagination);
          setClientsList(data);

          setStats(basicStats.data);
        })
      )
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setLoading(false));
  }, [filters, selectedCity]);

  const addClient = (values) => {
    coreApi
      .post("/clients", values)
      .then((response) => {
        setClientsList([response.data.client, ...clientsList]);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      });
  };

  const checkDuplicity = (values) => {
    setDuplicity(false);
    coreApi
      .get("/clients/check-duplicity", { params: { ...values } })
      .then((res) => {
        setDuplicity(false);
      })
      .catch((err) => {
        setDuplicateClient({ ...err.response.data.client });
        setDuplicity(true);
      });
  };

  /**
   * Recalculates balance of client.
   *
   * @param {int} clientId - id of the client
   */
  const handleBalanceSynchronization = (clientId) => {
    setLoading(true);

    coreApi
      .get(`/clients/${clientId}/recalculate-balance`)
      .then((response) => {
        let responseClient = response.data?.client;

        let clients = clientsList;

        let index = clientsList?.findIndex((client) => {
          return client.id === responseClient?.id;
        });

        clients[index] = responseClient;

        setClientsList([...clients]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row gutter={gutter}>
        <Col span={6}>
          <Card>
            <Statistic loading={isLoading} title={t("clients.activeClients")} value={stats?.count}></Statistic>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic loading={isLoading} title={t("clients.newClients")} value={stats?.new}></Statistic>
          </Card>
        </Col>
        <Col span={6}></Col>
        <Col span={6}></Col>
        <Col span={24}>
          <Card>
            <ClientsList
              clientsList={clientsList}
              isLoading={isLoading}
              pagination={pagination}
              filters={filters}
              setFilters={setFilters}
              handleBalanceSynchronization={handleBalanceSynchronization}
            ></ClientsList>
          </Card>
        </Col>
        <Col span={24}>
          <ClientAddForm
            addClient={addClient}
            checkDuplicity={checkDuplicity}
            duplicity={duplicity}
            duplicateClient={duplicateClient}
          ></ClientAddForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClientsIndex;
