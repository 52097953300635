import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EmailInput from "./EmailInput";

const EmployeeAddForm = ({ onFinishAction }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [addEmployeeForm] = Form.useForm();

  return (
    <React.Fragment>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("employees.addEmployee")}
      </Button>
      <Modal
        visible={isModalOpen}
        title={t("employees.addEmployee")}
        onOk={() => {
          addEmployeeForm.submit();
        }}
        okText={t("common.add")}
        okButtonProps={{ loading: isSubmitting }}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <Form
          form={addEmployeeForm}
          layout="vertical"
          onFinish={async (values) => {
            setSubmitting(true);
            await onFinishAction(values);
            setSubmitting(false);
            setModalOpen(false);
          }}
        >
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t("common.firstName")}
                rules={[
                  {
                    required: true,
                    message: t("employees.validation.firstName"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t("common.lastName")}
                rules={[
                  {
                    required: true,
                    message: t("employees.validation.firstName"),
                  },
                ]}
              >
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <EmailInput
                name="email_work"
                label={t("employees.email")}
                validationMessage={t("employees.validation.email")}
                required={false}
              />
            </Col>
            <Col span={12}>
              <EmailInput
                name="email_personal"
                label={t("employees.emailPersonal")}
                validationMessage={t("employees.validation.email")}
                required={true}
              />
            </Col>
            <Col span={12}>
              <Form.Item name="phone_work" label={t("employees.phone")} rules={[]} normalize={() => {}}>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone_personal" label={t("employees.phonePersonal")} rules={[]}>
                <Input></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="company_number" label={t("employees.companyIdentificationNumber")} rules={[]}>
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EmployeeAddForm;
