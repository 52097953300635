import React from "react";
import { Link } from "react-router-dom";

/**
 * Building Unit Link - turns building unit street and house number to clickable link that leads to a building unit profile
 * @component
 * @alias BuildingUnitLink
 * @property {object} buildingUnit - details of buildingUnit
 * @returns <Link />
 */
const BuildingUnitLink = ({ buildingUnit }) => {
  return (
    <Link
      to={"/buildings/" + buildingUnit?.building_id + "/unit/" + buildingUnit.id}
      style={{ borderBottom: "1px dotted" }}
    >
      {buildingUnit.street + " " + buildingUnit.house_number}
    </Link>
  );
};

export default BuildingUnitLink;
