import { Editor } from "@tinymce/tinymce-react";
import { Col, Form, Input, Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import EmailFromSelect from "./EmailFromSelect";

/**
 * @component
 * @alias SendEmailModal
 * @property {bool} isOpen - sets <Modal /> visibility
 * @property {function} close - parent close function
 * @property {string} template - used as a editor default content
 * @property {string} title - title for <Modal />
 * @property {number} idAttachmentRecord
 * @returns <Modal /> with <Form /> for sending emails based on template
 */
const SendEmailModal = ({
  isOpen,
  close,
  template,
  title,
  idAttachmentRecord,
}) => {
  const { t } = useTranslation();

  const [emailForm] = Form.useForm();

  const [isMailSending, setMailSending] = useState(false);
  const [editorContent, setEditorContent] = useState(template);

  useEffect(() => {
    setEditorContent(template);
  }, [template]);

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        close();
      }}
      title={title}
      okText={t("emails.sendEmail")}
      onOk={() => {
        emailForm.submit();
      }}
      okButtonProps={{ loading: isMailSending }}
      width={"80%"}
    >
      <Form
        form={emailForm}
        onFinish={(values) => {
          values.message = editorContent;
          setMailSending(true);
          coreApi
            .post("/mailer", values)
            .then((response) => {
              close();
              notification.success({
                message: t("emails.mailSent"),
                description: response?.message,
              });
            })
            .catch((error) => {
              notification.error({
                message: t("emails.mailError"),
                description: error?.message,
              });
            })
            .finally(() => {
              setMailSending(false);
            });
        }}
        layout="horizontal"
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <EmailFromSelect />
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              hidden
              name={"id_attachment_record"}
              initialValue={idAttachmentRecord}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              name={"to"}
              label={t("emails.to")}
              rules={[{ required: true }]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name={"cc"} label={t("emails.cc")}>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name={"bcc"} label={t("emails.bcc")}>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"subject"}
              label={t("emails.subject")}
              rules={[{ required: true }]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Editor
              initialValue={editorContent}
              init={{
                height: 700,
                content_css:
                  process.env.REACT_APP_CORE_SERVER + "css/emails.css",
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SendEmailModal;
