import { Calendar, Modal, notification, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import CleaningDetailPopup from "../Cleanings/CleaningDetailPopup";
import CleaningListSimple from "../Cleanings/CleaningListSimple";
import DailyAgendaPane from "../Cleanings/DailyAgendaPane";

const PartnerCalendarPane = ({ partner }) => {
  const { t } = useTranslation();

  const [recurringCleanings, setRecurringCleanings] = useState([]);
  const [oneTimeCleanings, setOneTimeCleanings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isCleaningDetailModalOpen, setCleaningDetailModalOpen] = useState(false);
  const [isDailyAgendaOpen, setDailyAgendaOpen] = useState(false);

  //! unused state - temporarly replaced with const
  // const [selectedCleaning, setSelectedCleaning] = useState({
  //   price: 0.0,
  //   type: "",
  //   building: "",
  // });

  const selectedCleaning = {
    price: 0.0,
    type: "",
    building: "",
  };

  const [dayDetailEvents, setDayDetailEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [clickedDate, setClickedDate] = useState();

  const handleCalendarChange = useCallback(
    (date) => {
      let params = {
        partner_id: partner?.id,
        date_from: date.clone().startOf("month").format("YYYY-MM-DD"),
        date_to: date.clone().endOf("month").format("YYYY-MM-DD"),
        page_size: 200,
      };

      setLoading(true);

      axios
        .all([
          coreApi.get("/cleanings/recurring", {
            params: params,
          }),
          coreApi.get("/cleanings/one-time", {
            params: params,
          }),
        ])
        .then(
          axios.spread((recurringCleaningsResponse, oneTimeCleaningsResponse) => {
            setRecurringCleanings(recurringCleaningsResponse.data);
            setOneTimeCleanings(oneTimeCleaningsResponse.data);
          })
        )
        .catch((error) => {
          notification.error({ message: error?.response?.data?.message });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [partner]
  );

  useEffect(() => {
    handleCalendarChange(selectedDate);
  }, [handleCalendarChange, selectedDate]);

  const dateCellRender = (value) => {
    let cleaningsMerged = [...recurringCleanings, ...oneTimeCleanings];

    let cleanings = cleaningsMerged.filter((o) => o.date_expected === value.format("YYYY-MM-DD"));

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={() => {
          setDailyAgendaOpen(true);
        }}
      >
        <DailyAgendaPane cleanings={cleanings} />
      </div>
    );
  };

  return (
    <React.Fragment>
      <CleaningDetailPopup
        isOpen={isCleaningDetailModalOpen}
        cleaningDate={selectedCleaning.date_expected ? moment(selectedCleaning.date_expected).format("LL") : ""}
        cleaningPrice={selectedCleaning.price}
        cleaningType={selectedCleaning.type}
        buildingStreet={selectedCleaning.building}
        onCancel={() => {
          setCleaningDetailModalOpen(false);
        }}
      ></CleaningDetailPopup>
      <Modal
        onCancel={() => {
          setDailyAgendaOpen(false);
        }}
        visible={isDailyAgendaOpen}
        title={clickedDate ? clickedDate.format("LL") : ""}
        cancelText={t("common.close")}
      >
        <CleaningListSimple cleanings={dayDetailEvents} />
      </Modal>
      <Spin spinning={loading}>
        <Calendar
          onSelect={(moment) => {
            let cleaningsMerged = [...recurringCleanings, ...oneTimeCleanings];

            let cleanings = cleaningsMerged.filter((o) => o.date_expected === moment.format("YYYY-MM-DD"));
            setDayDetailEvents([...cleanings]);
            setClickedDate(moment);
          }}
          onPanelChange={(moment) => {
            setSelectedDate(moment);
          }}
          dateCellRender={dateCellRender}
        ></Calendar>
      </Spin>
    </React.Fragment>
  );
};

export default PartnerCalendarPane;
