import { LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, notification, Space, Table, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../../api/calls";
import PartnerAddWorkerForm from "./PartnerAddWorkerForm";
import PartnerEditWorkerForm from "./PartnerEditWorkerForm";
import PartnerLinkWorkerForm from "./PartnerLinkWorkerForm";

const PartnerWorkersTable = ({ idPartner, workers }) => {
  const { t } = useTranslation();

  const [isAddWorkerModalOpen, setAddWorkerModalOpen] = useState(false);
  const [isAddWorkerSubmitting, setAddWorkerSubmitting] = useState(false);
  const [isLinkWorkerModalOpen, setLinkWorkerModalOpen] = useState(false);
  const [isLinkWorkerSubmitting, setLinkWorkerSubmitting] = useState(false);
  const [isEditWorkerModalOpen, setEditWorkerModalOpen] = useState(false);
  const [isEditWorkerSubmitting, setEditWorkerSubmitting] = useState(false);

  const [selectedWorker, setSelectedWorker] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    contract_from: "",
    contract_to: "",
  });

  const [workersList, setWorkersList] = useState([]);

  const addNewWorker = (values) => {
    setAddWorkerSubmitting(true);

    // bloody momentjs
    values.date_from = values?.date_from?.format("YYYY-MM-DD");
    values.date_to = values?.date_to?.format("YYYY-MM-DD");

    coreApi
      .post("/partners/contractors/" + idPartner + "/add-worker", values)
      .then((response) => {
        setWorkersList(response.data.workers);
        setAddWorkerModalOpen(false);
        notification.success({ message: response.data.message });
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setAddWorkerSubmitting(false);
      });
  };

  const linkWorker = (values) => {
    setLinkWorkerSubmitting(true);

    // bloody momentjs
    values.date_from = values.date_from?.format("YYYY-MM-DD");
    values.date_to = values.date_to?.format("YYYY-MM-DD");
    coreApi
      .post("/partners/contractors/" + idPartner + "/link-worker", values)
      .then((response) => {
        setWorkersList(response.data.workers);
        setLinkWorkerModalOpen(false);
        notification.success({ message: response.data.message });
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLinkWorkerSubmitting(false);
      });
  };

  const editWorker = (values) => {
    setEditWorkerSubmitting(true);
    coreApi
      .put("/partners/workers/" + values.partner_id, values)
      .then((response) => {
        setEditWorkerModalOpen(false);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setEditWorkerSubmitting(false);
      });
  };

  useEffect(() => {
    setWorkersList(workers);
  }, [workers]);

  return (
    <React.Fragment>
      <Table
        columns={[
          {
            title: t("common.fullName"),
            key: "name",
            render: (worker) => <Link to={"/partners/" + worker.id}>{worker.first_name + " " + worker.last_name}</Link>,
          },
          {
            title: t("common.email"),
            key: "email",
            dataIndex: "email",
            render: (email) => <a href={"mailto:" + email}>{email}</a>,
          },
          {
            title: t("common.phone"),
            key: "phone",
            dataIndex: "phone",
            render: (phone) => <a href={"tel:" + phone}>{phone}</a>,
          },
          {
            title: t("partners.contractFrom"),
            key: "date_from",
            render: (worker) => {
              return worker.pivot.date_from ? moment(worker.pivot.date_from).format("LL") : "";
            },
          },
          {
            title: t("partners.contractTo"),
            key: "date_to",
            render: (worker) => {
              return worker.pivot.date_to ? moment(worker.pivot.date_to).format("LL") : "";
            },
          },
          {
            title: t("common.actions"),
            key: "actions",
            render: (worker) => (
              <Space>
                <Button
                  size="small"
                  onClick={() => {
                    setEditWorkerModalOpen(true);
                    setSelectedWorker(worker);
                  }}
                >
                  {t("common.edit")}
                </Button>
                <Button
                  size="small"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: t("partners.deleteAlerts.alertTitle"),
                      content: t("partners.deleteAlerts.alertContent"),
                      onOk: () => {
                        Modal.confirm({
                          title: t("common.lastWarning"),
                          onOk: () => {
                            coreApi
                              .delete("/partners/contractors/" + idPartner + "/unlink-worker", {
                                params: { worker_id: worker.id },
                              })
                              .then((res) => {
                                notification.success({
                                  message: res.data.message,
                                });
                                setWorkersList([...workersList.filter((item) => item.id !== worker.id)]);
                              })
                              .catch((err) =>
                                notification.error({
                                  message: err.response.data.message,
                                })
                              );
                          },
                        });
                      },
                    });
                  }}
                >
                  {t("common.deleteLink")}
                </Button>
              </Space>
            ),
          },
        ]}
        // if the idPartner is 0 (the default state) or null then the partner is still fetching, therefore we mark the table as loading
        loading={idPartner}
        dataSource={workersList}
        rowKey="id"
      ></Table>
      <PartnerAddWorkerForm
        isOpen={isAddWorkerModalOpen}
        close={() => {
          setAddWorkerModalOpen(false);
        }}
        addWorker={addNewWorker}
        idContractor={idPartner}
        submitting={isAddWorkerSubmitting}
      />
      <PartnerLinkWorkerForm
        isOpen={isLinkWorkerModalOpen}
        close={() => {
          setLinkWorkerModalOpen(false);
        }}
        idContractor={idPartner}
        linkWorker={linkWorker}
        submitting={isLinkWorkerSubmitting}
      />

      <PartnerEditWorkerForm
        isOpen={isEditWorkerModalOpen}
        close={() => {
          setEditWorkerModalOpen(false);
        }}
        worker={selectedWorker}
        editWorker={editWorker}
        submitting={isEditWorkerSubmitting}
        contractorId={idPartner}
      />
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setAddWorkerModalOpen(true);
          }}
        >
          {t("partners.addNewWorker")}
        </Button>
        <Button
          type="primary"
          icon={<LinkOutlined />}
          onClick={() => {
            setLinkWorkerModalOpen(true);
          }}
        >
          {t("partners.linkWorker")}
        </Button>
      </Space>
    </React.Fragment>
  );
};

export default PartnerWorkersTable;
