import { Divider, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PartnerContactSection = ({ partner, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider orientation="left">{t("common.contactInformation")}</Divider>
      <Form.Item
        name="email"
        label={t("common.email")}
        initialValue={partner?.email}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
      <Form.Item
        name="phone"
        label={t("common.phone")}
        initialValue={partner?.phone}
      >
        <Input readOnly={readOnly} bordered={!readOnly}></Input>
      </Form.Item>
    </React.Fragment>
  );
};

export default PartnerContactSection;
