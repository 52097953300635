import { Button, Col, Modal, Row, Form, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const ClientLinkRepresentedForm = ({ buildings, client, addRepresentedClient }) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [addForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [representedClients, setRepresentedClients] = useState([]);

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/clients-represented/all")
      .then((res) => setRepresentedClients([...res.data]))
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Button onClick={() => setModalOpen(true)} type={"primary"}>
        {t("clients.addClient")}
      </Button>
      <Modal
        visible={isModalOpen}
        onCancel={() => setModalOpen(false)}
        title={t("clients.addRepresentedClientTitle")}
        onOk={() => addForm.submit()}
      >
        <Form
          form={addForm}
          onFinish={(values) => {
            addRepresentedClient({ ...values });
            setModalOpen(false);
          }}
        >
          <Row gutter={[16, 24]}>
            <Form.Item hidden name={"client_id"} initialValue={client?.id}></Form.Item>
            <Col span={24}>
              <Form.Item label={t("clients.representedClientSelect")} name={"client_id_represented"}>
                <Select
                  loading={loading}
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {representedClients.map((represented) => (
                    <Select.Option value={represented?.id} key={represented?.id}>
                      {represented?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t("clients.transferBuildings")} name={"building_ids"}>
                <Select
                  mode="multiple"
                  allowClear
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {/* {Array.isArray(client?.buildings) ? ( */}
                  {buildings?.map((building) => (
                    <Select.Option value={building?.id} key={building?.id}>
                      {building?.reference}
                    </Select.Option>
                  ))}
                  {/* ) : (
                    <Select.Option
                      value={client.buildings?.id}
                      key={client.buildings?.id}
                    >
                      {client.buildings?.reference}
                    </Select.Option>
                  )} */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ClientLinkRepresentedForm;
