import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Form, Row, Col } from "antd";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { coreApi } from "../../api/calls";
import { useTranslation } from "react-i18next";

const OfferPDFModal = ({ offer, isOrder }) => {
  const { t } = useTranslation();

  const [isPdfDownloading, setPdfDownloading] = useState(false);

  const [isMultiplePDFs, setMultiplePDFs] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const getBuildingUnitsCount = () => {
    return offer.building.building_units.length > 1 ? setMultiplePDFs(true) : setMultiplePDFs(false);
  };

  useEffect(() => {
    getBuildingUnitsCount();
  }, [offer]);

  const donwloadFile = () => {
    setPdfDownloading(true);
    let url = isOrder ? "/orders/download/pdf/" : "/offers/download/pdf/";
    isMultiplePDFs
      ? offer.building.building_units.forEach((unit) => {
          coreApi
            .get(url + offer?.id + "/units", {
              responseType: "blob",
              params: {
                building_unit_id: unit.building_unit_id,
              },
            })
            .then((res) => {
              let fileName =
                (isOrder ? t("offers.order") : t("offers.offer")) +
                "-" +
                offer?.id +
                "-" +
                unit.building_unit_id +
                ".pdf";

              fileDownload(res.data, fileName);
            })
            .catch((err) => notification.error({ message: err.response.data.message }))
            .finally(() => {
              setPdfDownloading(false);
            });
        })
      : coreApi
          .get(url + offer?.id, {
            responseType: "blob",
          })
          .then((res) => {
            let fileName = (isOrder ? t("offers.order") : t("offers.offer")) + "-" + offer?.id + ".pdf";

            fileDownload(res.data, fileName);
          })
          .catch((err) => notification.error({ message: err.response.data.message }))
          .finally(() => {
            setPdfDownloading(false);
          });
  };

  const downloadForUnit = (unit) => {
    setPdfDownloading(true);
    let url = isOrder ? "/orders/download/pdf/" : "/offers/download/pdf/";
    coreApi
      .get(url + offer?.id + "/units", {
        responseType: "blob",
        params: {
          building_unit_id: unit.building_unit_id,
        },
      })
      .then((res) => {
        let fileName =
          (isOrder ? t("offers.order") : t("offers.offer")) + "-" + unit.street + "-" + unit.house_number + ".pdf";

        fileDownload(res.data, fileName);
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message ? err.response.data.message : t("offers.pdfError") });
      })
      .finally(() => {
        setPdfDownloading(false);
      });
  };

  return (
    <React.Fragment>
      <Button
        loading={isPdfDownloading}
        icon={<FilePdfOutlined />}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("finance.invoices.downloadPdf")}
      </Button>
      <Modal visible={isModalOpen} title={t("offers.pdfDownloadModal")} onCancel={() => setModalOpen(false)}>
        <Row gutter={[16, 16]}>
          <Form layout={"horizontal"}>
            <Col span={24}>
              <Form.Item>
                <Button
                  type={"primary"}
                  loading={isPdfDownloading}
                  onClick={() => {
                    donwloadFile();
                  }}
                  icon={<FilePdfOutlined />}
                >
                  {t("offers.downloadAllPDFs")}
                </Button>
              </Form.Item>
            </Col>
            {offer?.building?.building_units?.map((unit, index) => (
              <Col key={index} span={24}>
                <Form.Item label={unit.street + " " + unit.house_number}>
                  <Button
                    loading={isPdfDownloading}
                    icon={<FilePdfOutlined />}
                    type={"primary"}
                    onClick={() => {
                      downloadForUnit(unit);
                    }}
                  >
                    {t("offers.donwloadPDF")}
                  </Button>
                </Form.Item>
              </Col>
            ))}
          </Form>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default OfferPDFModal;
