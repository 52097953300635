import { Card, Col, Statistic } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

const InvoicesStatBar = ({
  loading,
  allInvoices,
  paidInvoices,
  unpaidDueInvoices,
  unpaidOverdueInvoices,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={6}>
        <Card>
          <Statistic
            loading={loading}
            title={t("finance.invoices.invoices")}
            value={allInvoices}
          ></Statistic>
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            loading={loading}
            title={t("finance.invoices.paidPlural")}
            value={paidInvoices}
            valueStyle={{ color: "#52c41a" }}
            prefix={<CheckCircleTwoTone twoToneColor={"#52c41a"} />}
          ></Statistic>
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            loading={loading}
            title={t("finance.invoices.unpaidDue")}
            value={unpaidDueInvoices}
            valueStyle={{ color: "#fa541c" }}
            prefix={<ClockCircleTwoTone twoToneColor={"#fa541c"} />}
          ></Statistic>
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            loading={loading}
            title={t("finance.invoices.unpaidOverdue")}
            value={unpaidOverdueInvoices}
            valueStyle={{ color: "#f5222d" }}
            prefix={<ExclamationCircleTwoTone twoToneColor={"#f5222d"} />}
          ></Statistic>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InvoicesStatBar;
