import { Card, Col, notification, Progress, Row, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../api/calls";
import TransactionProfilePane from "../components/Transactions/TransactionProfilePane";
import CustomTabs from "../components/custom/CustomTabs";
import TransactionInvoicesPane from "../components/Transactions/TransactionInvoicesPane";

const TransactionProfile = (props) => {
  const { t } = useTranslation();

  const [transaction, setTransaction] = useState(null);
  const [isTransactionLoading, setTransactionLoading] = useState(false);

  useEffect(() => {
    setTransactionLoading(true);
    coreApi
      .get("/transactions/" + props.match.params.id)
      .then((response) => {
        setTransaction(response.data);
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
      })
      .finally(() => {
        setTransactionLoading(false);
      });
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <Row gutter={[16, 24]} style={{ marginBottom: "24px" }}>
        <Col span={24}>
          <Card loading={isTransactionLoading}>
            <Typography.Title>{t("finance.transactions.transaction") + " " + transaction?.id}</Typography.Title>

            <Col span={10}>
              Transakce je využita na{" "}
              <strong>{(Math.abs(transaction?.amount - transaction?.amount_free) / transaction?.amount) * 100}%</strong>
              .
              <div style={{ width: 200 }}>
                <Progress
                  size="small"
                  width={100}
                  percent={(Math.abs(transaction?.amount - transaction?.amount_free) / transaction?.amount) * 100}
                  status={
                    (Math.abs(transaction?.amount - transaction?.amount_free) / transaction?.amount) * 100 > 100 &&
                    "exception"
                  }
                />
              </div>
            </Col>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Card loading={isTransactionLoading}>
            <CustomTabs defaultActiveKey="#transactionProfilePane" props={props}>
              <Tabs.TabPane key="#transactionProfilePane" tab={t("common.profile")}>
                <TransactionProfilePane transaction={transaction} />
              </Tabs.TabPane>
              <Tabs.TabPane key="#transactionInvoicesPane" tab={t("finance.invoices.invoices")}>
                <TransactionInvoicesPane invoices={transaction?.invoices} />
              </Tabs.TabPane>
            </CustomTabs>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TransactionProfile;
