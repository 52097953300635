import { Button, Form, Modal, notification } from "antd";
import React, { useState } from "react";
import { coreApi } from "../../api/calls";

/**
 * Account Change Token Modal - modal form for changing banck account access token
 * @component
 * @alias AccountChangeTokenModal
 * @property {object} props - takes props from higher (profile) component
 * @returns <Button /> and <Modal /> containing <Form />
 */
const AccountChangeTokenModal = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const [tokenForm] = Form.useForm();

  return (
    <React.Fragment>
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {props.label}
      </Button>
      <Modal
        visible={isModalOpen}
        title={props.label}
        onOk={() => {
          tokenForm.submit();
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        okText={props.label}
        okButtonProps={{ loading: isFormSubmitting }}
      >
        <Form
          onFinish={(values) => {
            setFormSubmitting(true);
            if (values.access_token_valid_until) {
              values.access_token_valid_until =
                values.access_token_valid_until.toISOString();
            }
            if (values.control_token_valid_until) {
              values.control_token_valid_until =
                values.control_token_valid_until.toISOString();
            }

            coreApi
              .patch(props.path, values)
              .then((response) => {
                notification.success({ message: response.data.message });
                setModalOpen(false);
              })
              .catch((error) => {
                notification.error({
                  message: error.response.data.message,
                });
              })
              .finally(() => {});
          }}
          form={tokenForm}
        >
          {props.children}
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AccountChangeTokenModal;
