import { CheckOutlined, CloseOutlined, EditOutlined, InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { coreApi } from "../api/calls";
import CancelAndDuplicateModal from "../components/Offers/CancelAndDuplicateModal";
import CancelModal from "../components/Offers/CancelModal";
import ConfirmOfferModal from "../components/Offers/ConfirmOfferModal";
import OfferDetailsPane from "../components/Offers/OfferDetailsPane";
import OfferItemList from "../components/Offers/OfferItemList";
import OfferPDFModal from "../components/Offers/OfferPDFModal";
import OfferSendModal from "../components/Offers/OfferSendModal";
import ProfileFormActionSpace from "../components/ProfileFormActionSpace";

export const OfferContext = React.createContext();

/**
 * Detail of the offer.
 *
 * @component
 * @alias OfferProfile
 * @param {object} props
 * @returns <OfferContext.Provider />
 */
const OfferProfile = (props) => {
  // Translations
  const { t } = useTranslation();

  // Forms
  const [offerForm] = Form.useForm();
  const [priceForm] = Form.useForm();

  // States
  const [isLoading, setLoading] = useState(true);
  const [isOrder, setIsOrder] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isEditingPrice, setEditingPrice] = useState(false);
  const [clientName, setClientName] = useState();
  const [isFormSending, setFormSending] = useState(false);
  const [isFormReadOnly, setFormReadOnly] = useState(true);
  const [modalInfoMinutesEstimationData, setModalInfoMinutesEstimationData] = useState([]);
  const [offer, setOffer] = useState({});

  // Refs
  const user = useRef({ ...JSON.parse(localStorage.getItem("user")) });

  useEffect(() => {
    setLoading(true);

    // Find out if record is the offer or the order
    if (props.location.pathname.includes("offer")) {
      coreApi
        .get("/offers/" + props.match.params.id)
        .then((res) => {
          setOffer({ ...res.data });

          setModalInfoMinutesEstimationData(() => [...res.data.building.city_branch.cleaning_minutes_estimation]);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    } else if (props.location.pathname.includes("order")) {
      setIsOrder(true);

      coreApi
        .get("/orders/" + props.match.params.id)
        .then((res) => {
          if (res.data?.date_confirmed) {
            setIsConfirmed(true);
          }

          setOffer({ ...res.data });

          setModalInfoMinutesEstimationData([...res.data.building.city_branch.cleaning_minutes_estimation]);
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.match.params.id, props.location.pathname]);

  /**
   * Refresh offer when something cahnge
   *
   * @param {object} newOffer
   */
  const refreshOffer = (newOffer) => {
    setLoading(true);

    if ({ ...newOffer }?.date_confirmed) setIsConfirmed(true);

    setOffer({ ...newOffer });

    setModalInfoMinutesEstimationData([...newOffer.building.city_branch.cleaning_minutes_estimation]);

    setLoading(false);
  };

  // Title for card with offer/order items that contains button for displaying help modal
  // that contains usefull informations about pricing offer items
  const cardTitle = (
    <>
      {t("offers.offer")}
      <Button
        style={{ float: "right", border: "0px" }}
        icon={<InfoCircleOutlined />}
        onClick={() =>
          Modal.info({
            title: "Pomocné informace",
            content: modalInfoContent,
            okButtonProps: { type: "primary" },
            okText: "Ok",
            width: 800,
          })
        }
      />
    </>
  );

  // Assembled info modal content
  const modalInfoContent = (
    <>
      <br></br>
      <Typography.Title level={5}>{t("cleaningMinutes.title") + ":"}</Typography.Title>
      <br></br>
      <Table
        size="small"
        pagination={false}
        columns={[
          {
            title: t("cleaningMinutes.floorCount"),
            dataIndex: "floor_count",
            align: "center",
          },
          {
            title: t("cleaningMinutes.minutesPerGroundFloor"),
            dataIndex: "minutes_per_ground_floor",
            align: "center",
          },
          {
            title: t("cleaningMinutes.minutesPerFloor"),
            dataIndex: "minutes_per_floor",
            align: "center",
          },
        ]}
        dataSource={modalInfoMinutesEstimationData}
      ></Table>
      <br></br>
      <Typography.Title level={5}>{"Další možné služby:"}</Typography.Title>
      <ul>
        <li>{"zametení a vytření schoů do sklepa a sklepních chodeb"}</li>
        <li>{"zametení a vytření sušárny"}</li>
        <li>{"zametení a vytření prádelny"}</li>
        <li>{"mytí skleněných vyplní nad vchodovými a zadními dveřmi"}</li>
        <li>{"mytí oken (z dostupné části, neotvíratelná část pouze z vnitřní strany, štafle zajistí dům)"}</li>
        <li>{"mytí a leštění skel u vchodových dveří a dveří na dvůr"}</li>
        <li>{"mytí a leštění skel u vchodových a zadních dveří"}</li>
        <li>{"zametení prostoru okolo popelnic (včetně prostoru pod nimi)"}</li>
        <li>{"otření kachliček lemující schodiště"}</li>
        <li>{"mytí rámů vchodových a zadních dveří"}</li>
      </ul>
    </>
  );

  return (
    <Row gutter={[0, 16]}>
      <OfferContext.Provider value={{ offer, isOrder, isConfirmed, refreshOffer }}>
        <Col span={24}>
          <Badge.Ribbon
            text={t("offers.states." + offer?.state?.translation_key)}
            placement={"end"}
            color={
              offer.state?.id === 5 || offer.state?.id === 6 || offer.state?.id === 7
                ? "red"
                : isConfirmed && isOrder
                ? "green"
                : "blue"
            }
          >
            <Card loading={isLoading}>
              <Row>
                <div>
                  <Typography.Title>
                    {isOrder ? t("offers.order") : t("offers.offer")}
                    {" " + String(offer?.id).padStart(6, "0")}

                    <div style={{ color: "#1890ff", fontWeight: 400, marginTop: "10px", fontSize: "16px" }}>
                      {clientName && (
                        <Link to={"/clients/" + offer?.client_id}>
                          <Space size={[6, 12]}>
                            <UserOutlined />
                            {clientName}
                          </Space>
                        </Link>
                      )}
                    </div>
                  </Typography.Title>
                </div>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Space>
                    <OfferPDFModal offer={offer} isOrder={isOrder} />
                    <OfferSendModal id={offer?.id} isOrder={isOrder} />

                    {!isOrder ? (
                      <ProfileFormActionSpace
                        formInstance={offerForm}
                        isFormReadOnly={isFormReadOnly}
                        isFormSending={isFormSending}
                        setFormEditable={() => setFormReadOnly(false)}
                        setFormDisabled={() => setFormReadOnly(true)}
                      />
                    ) : user.current.is_admin === 1 ? (
                      <ProfileFormActionSpace
                        formInstance={offerForm}
                        isFormReadOnly={isFormReadOnly}
                        isFormSending={isFormSending}
                        setFormEditable={() => setFormReadOnly(false)}
                        setFormDisabled={() => setFormReadOnly(true)}
                      />
                    ) : null}

                    {/* <Col style={{ float: "right" }} span={3}> */}
                    {offer?.state?.id > 1 ? (
                      <ConfirmOfferModal
                        idOffer={offer?.id}
                        isOfferConfirmed={isOrder}
                        isOrderConfirmed={isConfirmed}
                        isDisabled={
                          offer?.state?.id === 5 || offer?.state?.id === 6 || offer?.state?.id === 7 ? true : false
                        }
                      ></ConfirmOfferModal>
                    ) : (
                      <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        onClick={() =>
                          Modal.confirm({
                            title: t("offers.confirmStateChange"),
                            okButtonProps: { type: "primary" },
                            onOk: () => {
                              coreApi
                                .post("/offers/update-state/" + offer?.id, {
                                  offer_state_id: 2,
                                })
                                .then((res) => refreshOffer({ ...res.data.offer }))
                                .catch((err) => console.log(err));
                            },
                          })
                        }
                      >
                        {t("offers.informationsProvided")}
                      </Button>
                    )}
                  </Space>
                  {!(offer?.state?.id === 5 || offer?.state?.id === 6 || offer?.state?.id === 7) && (
                    <Space style={{ float: "right" }}>
                      {isConfirmed && <CancelAndDuplicateModal offer={offer} refreshOffer={refreshOffer} />}
                      <CancelModal offer={offer} refreshOffer={refreshOffer} isOrder={isOrder} />
                    </Space>
                  )}
                </Col>
              </Row>
            </Card>
          </Badge.Ribbon>
        </Col>
        <Col span={24}>
          <OfferDetailsPane
            setClientName={setClientName}
            url={props.location.pathname}
            formInstance={offerForm}
            isFormReadOnly={isFormReadOnly}
            setFormSending={setFormSending}
            setFormReadOnly={setFormReadOnly}
            isFormSending={isFormSending}
            offerForm={offerForm}
            setFormEditable={() => setFormReadOnly(false)}
            setFormDisabled={() => setFormReadOnly(true)}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Col span={18}>
              <Card loading={isLoading} title={cardTitle}>
                <OfferItemList />
              </Card>
            </Col>
            <Col span={6}>
              <Card loading={isLoading} title={t("offers.summary")}>
                <Statistic
                  title={t("offers.summaryDetails.subtotal")}
                  value={new Intl.NumberFormat("cs-CZ", {
                    style: "currency",
                    currency: offer?.currency_iso_4217 || "CZK",
                  }).format(offer?.price)}
                ></Statistic>
                <hr></hr>
                <Row gutter={[8, 16]}>
                  <Col span={18}>
                    {isEditingPrice ? (
                      <Form
                        form={priceForm}
                        onKeyPress={(e) => (e.key === "Enter" ? e.preventDefault() : null)}
                        onFinish={(values) => {
                          coreApi
                            .put("/offers/" + offer?.id, { ...offer, ...values })
                            .then((res) => {
                              notification.success({
                                message: res.data.message,
                              });
                              refreshOffer({ ...res.data.offer });
                            })
                            .catch((err) =>
                              notification.error({
                                message: err.response.data.message,
                              })
                            );
                        }}
                      >
                        <Form.Item name={"price"} initialValue={offer?.price}>
                          <Input></Input>
                        </Form.Item>
                      </Form>
                    ) : (
                      <Statistic
                        title={t("offers.summaryDetails.total")}
                        value={new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: offer?.currency_iso_4217 || "CZK",
                        }).format(offer?.price)}
                      ></Statistic>
                    )}
                  </Col>
                  {!isOrder && (
                    <Col span={6}>
                      {isEditingPrice ? (
                        <Row gutter={[24, 0]}>
                          <Col span={12}>
                            <Button
                              icon={<CheckOutlined />}
                              onClick={() => {
                                priceForm.submit();
                                setEditingPrice(!isEditingPrice);
                              }}
                            ></Button>
                          </Col>
                          <Col span={12}>
                            <Button icon={<CloseOutlined />} onClick={() => setEditingPrice(!isEditingPrice)}></Button>
                          </Col>
                        </Row>
                      ) : (
                        <Col span={24}>
                          <Button
                            icon={<EditOutlined />}
                            style={{ border: "0px" }}
                            onClick={() => setEditingPrice(!isEditingPrice)}
                          ></Button>
                        </Col>
                      )}
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </OfferContext.Provider>
    </Row>
  );
};

export default OfferProfile;
