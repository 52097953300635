import { Col, Divider, Row, Table, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/**
 * @component
 * @alias ContactBuildingListPane
 * @property  {array} buildings - array of building units objects
 * @returns <Modal /> component with <Form /> inside
 */
const ContactBuildingListPane = ({ tags = null, buildings, buildingUnits }) => {
  // Translations
  const { t } = useTranslation();

  /**
   * Columns of the table with buildings.
   *
   * @constant
   * @type {array}
   */
  const buildingsColumns = [
    {
      title: t("buildings.registrationNumber"),
      key: "registration_number",
      render: (building) => {
        return (
          <Link to={`/buildings/${building.id}`} style={{ fontWeight: 500 }}>
            {building?.registration_number}
          </Link>
        );
      },
    },
    {
      title: t("buildings.city"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("buildings.district"),
      dataIndex: "district",
      key: "district",
    },
    {
      title: t("buildings.postalCode"),
      dataIndex: "postal_code",
      key: "postal_code",
    },
    {
      title: t("contacts.contactType"),
      key: "tags",
      width: "25%",
      render: (building) => {
        return (
          <div style={{ display: "flex", gap: "12px" }}>
            {tags?.map((tag) => {
              return (
                building?.id === tag?.contactable_id &&
                tag?.contactable_type === "building" && (
                  <Tag key={tag?.id} color={t(`contacts.tagColors.${tag?.tag?.key}`)} style={{ textAlign: "center" }}>
                    {tag?.tag?.translation?.tag_name}
                  </Tag>
                )
              );
            })}
          </div>
        );
      },
    },
  ];

  /**
   * Columns of the table with building unit.
   *
   * @constant
   * @type {array}
   */
  const buildingUnitsColumns = [
    {
      title: t("buildingUnits.street"),
      render: (buildingUnit) => {
        return (
          <Link to={`/buildings/${buildingUnit.building_id}/unit/${buildingUnit.id}`} style={{ fontWeight: 500 }}>
            {buildingUnit.street + " " + buildingUnit.house_number}
          </Link>
        );
      },
    },
    {
      title: t("buildingUnits.city"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("buildingUnits.floorCount"),
      dataIndex: "floor_count",
      key: "floor_count",
    },
    {
      title: t("buildingUnits.groundFloorCount"),
      dataIndex: "ground_floor_count",
      key: "ground_floor_count",
    },
    {
      title: t("buildingUnits.basementFloorCount"),
      dataIndex: "basement_floor_count",
      key: "basement_floor_count",
    },
    {
      title: t("contacts.contactType"),
      key: "tags",
      width: "25%",
      render: (buildingUnit) => {
        return (
          <div style={{ display: "flex", gap: "12px" }}>
            {tags?.map((tag) => {
              return (
                buildingUnit?.id === tag?.contactable_id &&
                tag?.contactable_type === "buildingUnit" && (
                  <Tag key={tag?.id} color={t(`contacts.tagColors.${tag?.tag?.key}`)} style={{ textAlign: "center" }}>
                    {tag?.tag?.translation?.tag_name}
                  </Tag>
                )
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Divider orientation="left">{t("menu.buildings")}</Divider>
      <Table size="small" dataSource={buildings} columns={buildingsColumns} />

      <Divider orientation="left">{t("menu.buildingUnits")}</Divider>
      <Table size="small" dataSource={buildingUnits} columns={buildingUnitsColumns} />
    </React.Fragment>
  );
};

export default ContactBuildingListPane;
