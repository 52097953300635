import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @alias ClientInvoiceInfo
 * @property {boolean} isSummary
 * @property {boolean} isCompanyNumberRequired
 * @returns Client info for invoice
 */
const ClientInvoiceInfo = ({ isSummary = false, isCompanyNumberRequired = true }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col span={24}>
        <Form.Item
          name={"recipient_name"}
          label={t("finance.invoices.recipientName")}
          rules={[{ required: true, message: "" }]}
        >
          <Input.TextArea rows={2} autoSize disabled={isSummary} bordered={!isSummary}></Input.TextArea>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={"recipient_company_number"}
          label={t("finance.invoices.recipientCompanyNumber")}
          rules={[{ required: isCompanyNumberRequired, message: "" }]}
        >
          <Input disabled={isSummary} bordered={!isSummary}></Input>
        </Form.Item>
      </Col>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item
            name={"recipient_address"}
            label={t("finance.invoices.recipientAddress")}
            rules={[{ required: true, message: "" }]}
          >
            <Input disabled={isSummary} bordered={!isSummary}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"recipient_postal_code"}
            label={t("finance.invoices.recipientPostalCode")}
            rules={[
              {
                required: true,
                message: "",
                pattern: new RegExp("[0-9]{4,6}"),
              },
            ]}
          >
            <Input type={"number"} max={6} min={4} disabled={isSummary} bordered={!isSummary}></Input>
          </Form.Item>
        </Col>
      </Row>
      <Col span={24}>
        <Form.Item
          name={"recipient_city"}
          label={t("finance.invoices.recipientCity")}
          rules={[{ required: true, message: "" }]}
        >
          <Input disabled={isSummary} bordered={!isSummary}></Input>
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

export default ClientInvoiceInfo;
