import { Checkbox, Col, DatePicker, Divider, Form, InputNumber, Row } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import ClaimFormFragment from "../Claims/ClaimFormFragment";
import PartnerSelect from "../Partners/PartnerSelect";
import CleaningPromoTagSelect from "./CleaningPromoTagSelect";

/**
 * One Time Cleaning Edit Form
 * @component
 * @alias OneTimeCleaningEditForm
 * @property {object} cleaning - cleaning to edit
 * @property {object} form - form object
 * @property {function} onFinish - parent onFinish action for form submit
 * @property {string} layout - form layout
 * @property {string} size - form size
 * @returns Structured <Form /> component, that contains all necessary fields to edit a cleaning.
 */
const OneTimeCleaningEditForm = ({ cleaning, form, onFinish, layout, size }) => {
  const { t } = useTranslation();

  return (
    <Form
      size={size}
      layout={layout}
      form={form}
      onFinish={(values) => {
        onFinish(values, "/cleanings/one-time/");
      }}
    >
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <PartnerSelect
            name={"default_partner"}
            label={t("cleanings.partner")}
            initialValue={cleaning?.partner_id}
            readOnly={true}
          />
        </Col>
        <Col xs={24} md={12}>
          <PartnerSelect
            name={"substitute_id"}
            label={t("cleanings.substitute")}
            initialValue={cleaning?.substitute_id}
            disabledOption={cleaning?.partner_id}
          />
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"date_expected"}
            label={t("cleanings.dateExpected")}
            initialValue={cleaning?.date_expected ? moment(cleaning?.date_expected) : null}
          >
            <DatePicker disabled bordered={false} format="L" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"date_real"}
            label={t("cleanings.dateReal")}
            initialValue={cleaning?.date_real ? moment(cleaning?.date_real) : null}
          >
            <DatePicker format="L" />
          </Form.Item>
        </Col>
        <Divider orientation={"left"}>{t("cleanings.values")}</Divider>
        <Col xs={24} md={12}>
          <Form.Item name={"price"} label={t("cleanings.price")} initialValue={cleaning?.price}>
            <InputNumber readOnly disabled bordered={false} />
          </Form.Item>
          <Form.Item name={"promo"} label={t("cleanings.promo")} initialValue={cleaning?.promo}>
            <InputNumber />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"partner_profit"}
            label={t("cleanings.partnerProfit")}
            initialValue={cleaning?.partner_profit}
          >
            <InputNumber disabled />
          </Form.Item>
          <Form.Item
            name={"substitute_profit"}
            label={t("cleanings.substituteProfit")}
            initialValue={cleaning?.substitute_profit}
          >
            <InputNumber disabled />
          </Form.Item>
          <Form.Item name={"anna_profit"} label={t("cleanings.annaProfit")} initialValue={cleaning?.anna_profit}>
            <InputNumber disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <CleaningPromoTagSelect initialValue={cleaning?.promo_tag_id} />
        </Col>
        <Divider orientation={"left"}>{t("cleanings.promoCoveredBy")}</Divider>
        <Col xs={24} md={8}>
          <Form.Item
            name={"is_promo_partner_covered"}
            valuePropName={"checked"}
            label={t("cleanings.promoCoveredByPartner")}
            initialValue={cleaning?.is_promo_partner_covered}
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"is_promo_anna_covered"}
            valuePropName={"checked"}
            label={t("cleanings.promoCoveredByAnna")}
            initialValue={cleaning?.is_promo_anna_covered}
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"is_full_partner_share"}
            valuePropName={"checked"}
            label={t("cleanings.fullPartnerShare")}
            initialValue={cleaning?.is_full_partner_share}
          >
            <Checkbox></Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation={"left"}>{t("cleanings.claims")}</Divider>
      <ClaimFormFragment form={form} cleaning={cleaning} />
    </Form>
  );
};

export default OneTimeCleaningEditForm;
