import { Col, Divider, Row, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ClientBasicInfoSection = ({ client, readOnly }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Divider>{t("clients.generalInformation")}</Divider>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item name={"name"} label={t("clients.name")} initialValue={client?.name}>
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"company_number"}
            label={t("clients.identificationNumber")}
            initialValue={client?.company_number}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"tax_identification_number"}
            label={t("clients.taxIdentificationNumber")}
            initialValue={client?.tax_identification_number}
          >
            <Input readOnly={readOnly} bordered={!readOnly}></Input>
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ClientBasicInfoSection;
