import { Form, Input } from "antd";
import React from "react";

const rfc5322Regex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailInput = ({
  name,
  validationMessage,
  label,
  initialValue,
  readOnly,
  required,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={[
        {
          required: required,
          pattern: rfc5322Regex,
          message: validationMessage,
        },
      ]}
    >
      <Input type="email" readOnly={readOnly} bordered={!readOnly}></Input>
    </Form.Item>
  );
};

export default EmailInput;
