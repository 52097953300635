import { Table, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @component
 * @alias TransactionPane
 * @prop {string} accountNumber
 * @prop {string} accountBankCode
 * @prop {string} accountSwift
 */
const TransactionPane = ({ transactions }) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoading, setDataLoading] = useState();
  const [sorter, setSorter] = useState();

  const tableColumns = [
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      dataIndex: "id",
    },
    {
      title: t("finance.transactions.accountNumberFrom"),
      key: "account_number_from",
      render: (transaction) => (
        <Typography.Text copyable key={transaction.id}>
          {transaction.account_number_from + "/" + transaction.bank_code_from}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNameFrom"),
      key: "account_name_from",
      dataIndex: "account_name_from",
    },
    {
      title: t("finance.transactions.variableSymbol"),
      key: "variable_symbol",
      dataIndex: "variable_symbol",
      render: (variableSymbol) => (
        <Typography.Text copyable key={variableSymbol}>
          {variableSymbol}
        </Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.amount"),
      key: "amount",
      sorter: true,
      render: (rowData) => {
        return new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: rowData.currency_iso_4217 || "CZK",
        }).format(rowData.pivot.amount);
      },
    },
    {
      title: t("finance.transactions.date"),
      key: "date_transaction",
      dataIndex: "date_transaction",
      sorter: true,
      render: (rowData) => {
        return moment(rowData).format("L");
      },
    },
  ];

  // useEffect(() => {
  // setDataLoading(true);
  // coreApi
  // 	.get("/transactions", {
  // 		params: {
  // 			account_number: accountNumber,
  // 			account_bank_code: accountBankCode,
  // 			account_swift: accountSwift,
  // 			page: currentPage,
  // 			order_by_column: sorter?.columnKey || null,
  // 			order_by_desc: sorter?.order === "descend" ? "DESC" : null,
  // 		},
  // 	})
  // 	.then((res) => setTransactionsList({ ...res.data }))
  // 	.catch((err) => console.log(err))
  // 	.finally(() => setDataLoading(false));
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPage, sorter]);

  return (
    <React.Fragment>
      <Table
        key={"transaction-table"}
        size="small"
        loading={dataLoading}
        dataSource={transactions}
        columns={tableColumns}
        pagination={{
          current: transactions?.current_page,
          pageSize: transactions?.per_page,
          total: transactions?.total,
          page: currentPage,
          showSizeChanger: false,
          onChange: (e) => {
            setCurrentPage(e);
          },
        }}
        onChange={(pagination, filter, sorter) => {
          setSorter(sorter);
        }}
        rowKey={"id"}
      ></Table>
    </React.Fragment>
  );
};

export default TransactionPane;
