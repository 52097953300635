import { Input, Col, Row, Form, Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AddableLineItem from "./AddableLineItem";
import AddLineItemForm from "./AddLineItemForm";
import ClientInvoiceInfo from "./ClientInvoiceInfo";
import InvoiceDetailsForm from "./InvoiceDetailsForm";
import InvoiceLocationForm from "./InvoiceLocationForm";
import PartnerInvoiceInfo from "./PartnerInvoiceInfo";

const InvoiceSummary = ({ form, invoiceType = null }) => {
  const { t } = useTranslation();

  const name = form.getFieldValue("supplier_name") + " - " + form.getFieldValue("recipient_name");

  const contentBasedOnInvoiceType = () => {
    switch (invoiceType) {
      case "partnerClient":
        return (
          <React.Fragment>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo isSupplier={true} isSummary={true} />
            </Col>
            <Col xs={24} md={12}>
              <ClientInvoiceInfo isSummary={true} isCompanyNumberRequired={false} />
            </Col>
          </React.Fragment>
        );
      case "partnerPartner":
        return (
          <React.Fragment>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo isSummary={true} />
            </Col>
            <Col xs={24} md={12}>
              <PartnerInvoiceInfo isSupplier={true} isSummary={true} />
            </Col>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Col offset={3} span={18}>
        <Form.Item name={"name"} label={t("finance.invoices.invoiceName")} initialValue={name}>
          <Input disabled={true} bordered={false}></Input>
        </Form.Item>
        <Divider></Divider>
      </Col>
      <Col span={24}>
        <Row>{contentBasedOnInvoiceType()}</Row>
      </Col>
      <Col span={24}>
        <Divider> {t("finance.invoices.locale")}</Divider>
        <Row>
          <Col span={12}>
            <InvoiceDetailsForm isSummary={true} />
          </Col>
          <Col span={12}>
            <InvoiceLocationForm isSummary={true} />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Divider orientation="center">{t("finance.lineItems.lineItems")}</Divider>
        <AddLineItemForm isSummary={true} idBuilding={form.getFieldValue("building_unit_id")} />
      </Col>
    </React.Fragment>
  );
};

export default InvoiceSummary;
