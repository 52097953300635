import { Form, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";

const PartnerSelect = ({
  label,
  name,
  initialValue,
  disabledOption,
  readOnly,
  required,
  validationMessage,
  size,
  setIdPartner = null,
  onChange = null,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    coreApi
      .get("/partners/all")
      .then((response) => {
        if (isMounted) {
          setPartners(response.data);
        }
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      initialValue={initialValue}
      valuePropName={"value"}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
    >
      <Select
        size={size}
        allowClear
        disabled={readOnly}
        bordered={!readOnly}
        loading={loading}
        showSearch={true}
        placeholder={t("partners.selectPartner")}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(e) => {
          onChange && onChange(partners.find((item) => item.id === e));
        }}
      >
        {partners.map((partner) => {
          return (
            <Select.Option
              key={partner.id}
              value={partner.id}
              disabled={disabledOption && partner.id === disabledOption}
            >
              {`${partner.first_name} ${partner.last_name}`}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default PartnerSelect;
