import { Button, Col, Descriptions, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import PartnerSelect from "../../Partners/PartnerSelect";

const ScheduleThirdStepMassEdit = ({ selectedBuildingUnits, moveToSecondStep, form, submitting }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Descriptions size={"small"} title={t("common.summary")}>
          {selectedBuildingUnits.map((buildingUnit, index) => {
            return (
              <React.Fragment key={index}>
                <Descriptions.Item>{buildingUnit.street + " " + buildingUnit.house_number}</Descriptions.Item>
                <Descriptions.Item>
                  {t("schedules.rulesCount") + " " + buildingUnit.service_rrules.length}
                </Descriptions.Item>
                <Descriptions.Item>
                  {t("schedules.rulesCount") + " " + buildingUnit.service_rrules.length}
                </Descriptions.Item>
              </React.Fragment>
            );
          })}
        </Descriptions>
        <Descriptions size={"small"} title={t("schedules.termination")}>
          <Descriptions.Item span={2}>{t("schedules.dateTo")}</Descriptions.Item>
          <Descriptions.Item>{form.getFieldValue("date_terminate").format("LL")}</Descriptions.Item>
        </Descriptions>
        <Descriptions size={"small"} title={t("schedules.newRules")}>
          <Descriptions.Item span={2}>{t("schedules.dateFrom")}</Descriptions.Item>
          <Descriptions.Item>{form.getFieldValue("date_from")?.format("LL") || ""}</Descriptions.Item>
          <Descriptions.Item span={2}>{t("schedules.dateTo")}</Descriptions.Item>
          <Descriptions.Item>{form.getFieldValue("date_to")?.format("LL") || t("common.undefined")}</Descriptions.Item>

          <Descriptions.Item span={2}>{t("schedules.serviceRatio")}</Descriptions.Item>
          <Descriptions.Item>
            {form.getFieldValue("service_ratio") * 100 + "%" || t("common.undefined")}
          </Descriptions.Item>

          <Descriptions.Item span={2}>{t("schedules.cleaningDays")}</Descriptions.Item>
          <Descriptions.Item>
            {form
              .getFieldValue("days")
              .map((day) => {
                return t("rrules.byweekday." + day);
              })
              .join(", ")}
          </Descriptions.Item>

          <Descriptions.Item span={2}>{t("schedules.partner")}</Descriptions.Item>
          <Descriptions.Item>
            {form.getFieldValue("partner_id") ? (
              <PartnerSelect
                name={"partnerek"}
                size={"small"}
                readOnly
                initialValue={form.getFieldValue("partner_id")}
              />
            ) : (
              t("common.undefined")
            )}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={12}>
        <Button style={{ margin: "0 8px" }} block onClick={() => moveToSecondStep()}>
          {t("common.previous")}
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          loading={submitting}
          block
          onClick={() => {
            form.submit();
          }}
        >
          {t("common.finish")}
        </Button>
      </Col>
    </Row>
  );
};

export default ScheduleThirdStepMassEdit;
