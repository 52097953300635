import { CheckOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, InputNumber, notification, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import { OfferContext } from "../../pages/OfferProfile";
import CustomRegularityModal from "./CustomRegularityModal";

const OfferItemAdd = ({ regularityList, calculateHoursPerMonth, addCleaningGroup, isOrder }) => {
  const { t } = useTranslation();

  const { offer, refreshOffer } = useContext(OfferContext);
  const [isAdding, setAdding] = useState(false);
  const [regularityModalOpen, setRegularityModalOpen] = useState(false);

  const [addForm] = Form.useForm();

  const hourPrice = 170;

  let count = 0;
  let minutes = 0;
  let cleaningGroup = {};

  let hpm = 0;

  const handleCountChange = (value) => {
    count = value;
    handleCalculation();
  };

  const handleMinutesChange = (value) => {
    minutes = value;
    handleCalculation();
  };

  const handleCleaningGroupChange = (value) => {
    cleaningGroup = offer?.cleaning_service_groups?.find((group) => group.id === value);
    handleCalculation();
  };

  const handleCalculation = () => {
    hpm = calculateHoursPerMonth(cleaningGroup, minutes, count);

    if (hpm > 0) {
      addForm.setFieldsValue({ expected_hours_per_month: hpm.toFixed(3) });
      calculatePrice();
    }
  };

  const calculatePrice = () => {
    let price = hpm * hourPrice;
    addForm.setFieldsValue({ expected_price_per_month: price.toFixed(2) });
  };

  return (
    <Form
      form={addForm}
      onFinish={(values) => {
        let newItem = {
          service: values.service,
          cleaning_service_group_id: values.cleaning_service_group_id,
          service_count: Number(values.service_count),
          expected_minutes_per_cleaning: Number(values.expected_minutes_per_cleaning),
          expected_hours_per_month: Number(values.expected_hours_per_month),
          expected_price_per_month: Number(values.expected_price_per_month),
        };

        coreApi
          .post("cleaning-service-group-contents", newItem)
          .then((res) => {
            refreshOffer(res.data.offer);
            notification.success({ message: res.data.message });
          })
          .catch((err) => notification.error({ message: err?.response?.data?.message }));
        addForm.resetFields();
      }}
    >
      <Row gutter={[8, 0]}>
        {isOrder ? (
          ""
        ) : (
          <React.Fragment>
            <Divider orientation="left"></Divider>
            {isAdding ? (
              <>
                <Col span={3}>
                  <Form.Item
                    name="cleaning_service_group_id"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("offers.itemPlaceholders.regularity")}
                      onChange={(value) => {
                        handleCleaningGroupChange(value);
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            <button
                              style={{
                                flex: "none",
                                border: "none",
                                display: "block",
                                cursor: "pointer",
                              }}
                              onClick={() => setRegularityModalOpen(true)}
                            >
                              <PlusOutlined /> {t("common.add")}
                            </button>
                          </div>
                        </div>
                      )}
                    >
                      {regularityList.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name="service"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <TextArea autoSize={true}></TextArea>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item
                    name="service_count"
                    hasFeedback
                    initialValue={0}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      placeholder={t("offers.itemPlaceholders.serviceCount")}
                      onChange={(value) => handleCountChange(value)}
                    ></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="expected_minutes_per_cleaning"
                    hasFeedback
                    initialValue={0}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      placeholder={t("offers.itemPlaceholders.minutesPerCleaning")}
                      onChange={(value) => handleMinutesChange(value)}
                    ></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="expected_hours_per_month" initialValue={0}>
                    <InputNumber
                      value={0}
                      style={{ width: "100%" }}
                      // readOnly={true}
                    ></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="expected_price_per_month" initialValue={0}>
                    <Input
                      value={0}
                      // readOnly={true}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Button
                        htmlType="submit"
                        icon={<CheckOutlined />}
                        onClick={() => {
                          addForm
                            .validateFields()
                            .then((valid) => {
                              addForm.submit();
                              setAdding(false);
                            })
                            .catch((e) => {});
                        }}
                      ></Button>
                    </Col>
                    <Col>
                      <Button
                        icon={<StopOutlined />}
                        onClick={(e) => {
                          setAdding(false);
                          addForm.resetFields();
                        }}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <Button
                disabled={isOrder}
                style={{ width: "100%" }}
                onClick={(e) => {
                  setAdding(true);
                }}
              >
                {t("common.add")}
              </Button>
            )}
          </React.Fragment>
        )}
      </Row>
      <CustomRegularityModal
        isModalOpen={regularityModalOpen}
        setModalOpen={setRegularityModalOpen}
        addCleaningGroup={addCleaningGroup}
      ></CustomRegularityModal>
    </Form>
  );
};

export default OfferItemAdd;
