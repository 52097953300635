import { Tabs } from "antd";
import React from "react";
import useTabChange from "../../hooks/useTabChange";

/**
 * Ant Design tabs with active key as a hash in the URL.
 *
 * @component
 * @alias CustomTabs
 * @param {string} defaultActiveKey
 * @param {array} props
 * @returns <Tabs />
 */
const CustomTabs = ({ defaultActiveKey = "#overview", ...props }) => {
  const [activeTab, setActiveTab] = useTabChange(props.props, defaultActiveKey);

  return (
    <Tabs size={"small"} activeKey={activeTab} onChange={(e) => setActiveTab(e)} {...props}>
      {props.children}
    </Tabs>
  );
};

export default CustomTabs;
