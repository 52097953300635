import { notification, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { coreApi } from "../../api/calls";

/**
 * Account Transaction Pane - on mount fetches all transactions, that belongs to the account
 * @component
 * @alias AccountTransactionsPane
 * @property {object} account - object containing account details
 * @returns Table containing all transactions of the account
 */
const AccountTransactionsPane = ({ account }) => {
  const { t } = useTranslation();

  const defaultPageSize = 10;

  const [transactions, setTransactions] = useState([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    coreApi
      .get("/transactions", {
        params: {
          account_id: account?.id,
        },
      })
      .then((response) => {
        setPageSize(defaultPageSize);
        setTotal(response.data.total);
        setTransactions(response.data.data);
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [account]);

  const handleTableChange = (pagination, filters, sorter) => {
    setLoading(true);
    coreApi
      .get("/transactions", {
        params: {
          page: pagination.current,
          page_size: pageSize,
          order_by_column: sorter.columnKey,
          order_by_desc: sorter.order === "descend" ? "DESC" : null,
          account_id: account?.id,
        },
      })
      .then((response) => {
        setCurrentPage(pagination.current);
        setPageSize(pageSize);
        setTotal(response.data.total);
        setTransactions(response.data.data);
      })
      .catch((error) => {
        notification.error({
          message: error?.response?.data?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // column definition for <Table /> component
  const columns = [
    {
      title: t("finance.transactions.transactionNumber"),
      key: "id",
      dataIndex: "id",
      render: (number) => <Link to={"/transactions/" + number}>{number}</Link>,
    },
    {
      title: t("finance.transactions.accountNumberFrom"),
      key: "account_number_from",
      render: (transaction) => (
        <Typography.Text copyable>{transaction.account_number_from + "/" + transaction.bank_code_from}</Typography.Text>
      ),
    },
    {
      title: t("finance.transactions.accountNameFrom"),
      key: "account_name_from",
      dataIndex: "account_name_from",
    },
    {
      title: t("finance.transactions.amount"),
      key: "amount",
      sorter: true,
      render: (transaction) =>
        new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: transaction.currency_iso_4217 || "CZK",
        }).format(transaction.amount),
    },
    {
      title: t("finance.transactions.date"),
      key: "date_transaction",
      dataIndex: "date_transaction",
      sorter: true,
      render: (date) => moment(date).format("L"),
    },
    {
      title: t("finance.transactions.variableSymbol"),
      key: "variable_symbol",
      dataIndex: "variable_symbol",
      render: (variableSymbol) => <Typography.Text copyable>{variableSymbol}</Typography.Text>,
    },
    {
      title: t("finance.transactions.linked"),
      key: "linked",
      dataIndex: "invoices",
      filters: [
        { text: t("finance.transactions.linked"), value: true },
        { text: t("finance.transactions.unlinked"), value: false },
      ],
      onFilter: (value, record) => {
        let bool = record.invoices.length > 0;
        return bool === value;
      },
      render: (invoices) =>
        invoices.length > 0 ? (
          <Tag color={"cyan"}>{t("finance.transactions.linked")}</Tag>
        ) : (
          <Tag color={"red"}>{t("finance.transactions.unlinked")}</Tag>
        ),
    },
  ];

  return (
    <Table
      dataSource={transactions}
      loading={loading}
      size={"small"}
      columns={columns}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: total,
        showSizeChanger: true,
      }}
      onChange={handleTableChange}
      rowKey={"id"}
    ></Table>
  );
};

export default AccountTransactionsPane;
